export const SET_DEVICE = 'SET_DEVICE';

export interface DeviceState {
    device_id: number

}

interface SetSelectedDevice {
    type: typeof SET_DEVICE,
    payload: number
}

export type DeviceActionTypes = SetSelectedDevice;