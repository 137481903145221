import React, {useEffect, useState} from "react";
import {Dimmer, Loader, Segment, Table} from "semantic-ui-react";
import {Auth} from "aws-amplify";

const ActivityLog: React.FC = () => {
    const [devices, setDevices] = useState([]);
    const [retrievingDevices, setRetrievingDevices] = useState<boolean>(false);

    useEffect(() => {
        let isSubscribed = true;

        setRetrievingDevices(true);
        Auth.currentAuthenticatedUser({bypassCache: true}).then(res => {
            if (isSubscribed) {
                res.listDevices(30, '', {
                    onSuccess: (r) => {
                        setDevices(r.Devices);
                    },
                    onFailure: (e) => {
                        console.log(e);
                    }
                });
            }
        }).finally( () => {
            if(isSubscribed){
                setRetrievingDevices(false);
            }
        });

        return () => {
            isSubscribed = false;
        };
    }, []);

    return <>
        <h4>Activity Log</h4>
        <Table striped>
            <Dimmer inverted active={retrievingDevices}>
                <Loader />
            </Dimmer>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>IP</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {devices.map((d, index) => {
                    const date = new Date(d.DeviceLastAuthenticatedDate * 1000);
                    return <Table.Row key={'device-' + index}>
                        <Table.Cell>{d.DeviceAttributes.find(da => da.Name === 'device_name').Value}</Table.Cell>
                        <Table.Cell>{d.DeviceAttributes.find(da => da.Name === 'device_status').Value}</Table.Cell>
                        <Table.Cell>{d.DeviceAttributes.find(da => da.Name === 'last_ip_used').Value}</Table.Cell>
                        <Table.Cell>{date.getFullYear()}-{date.getUTCDay()}-{date.getMonth()}</Table.Cell>
                    </Table.Row>;
                })}
            </Table.Body>
        </Table>
    </>;
};
export default ActivityLog;
