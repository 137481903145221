import {Application} from "../../interfaces/Application";
import {AxiosInterceptor} from "../AxiosInterceptor";
import {AxiosInstance, AxiosResponse} from "axios";
import {UserApplication} from "../../interfaces/UserApplication";
import {Video} from "../../interfaces/Video";


export class ApplicationService {

    static list = async (lang: string): Promise<Application[]> => {
        const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/application/applications' : '/application/applications/test';
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, {lang: lang}).then((res: AxiosResponse) => {
                    console.log('ApplicationService, list', res.data);
                    return res.data;
                });
            });
    };

    static userApplications = async (): Promise<UserApplication[]> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/application/user/applications' : '/application/user/applications/test';
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                }).catch((error) => {
                    return [];
                });
            });
    };

    static getVideo = async (applicationId: number, video_name: string): Promise<string> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post('application/s3/application/' + applicationId + '/video', {video_name: video_name}).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

    static listVideos = async (): Promise<Video[]> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.get('videos/getVideos', {}).then((res: AxiosResponse) => {
                    return res.data.videos;
                });
            });
    }

    static downloadApplicationVideo = async (applicationId: number, key: string): Promise<string> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.get('application/s3/application-download/' + applicationId + '/'+key).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

}

