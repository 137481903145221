import React, {
    Dispatch,
    forwardRef,
    useEffect,
    useState
} from 'react';
import {LangProfile} from "../../../lang/Profile";
import {Form, InputOnChangeData, Item, Segment} from "semantic-ui-react";
import {SecondaryButton} from "../../../components/Buttons/SecondaryButton";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {UserProfession} from "../../../interfaces/UserProfession";
import UserService from "../../../services/user/UserService";
import {sendToast} from "../../../store/actions/ApplicationConfigurationActions";
import {CognitoUserInterface} from "@aws-amplify/ui-components";
import {Auth} from "aws-amplify";

const professions = [
    {key: 'p-1', text: 'Psicologo/a', value: 'psicologo'},
    {key: 'p-2', text: 'Psicologo/a psicoterapeuta', value: 'psicologo-psicoterapeuta'},
    {key: 'p-3', text: 'Medico/a psicoterapeuta', value: 'medico-psicoterapeuta'},
    {key: 'p-4', text: 'Psichiatra', value: 'psichiatra'},
    {key: 'p-5', text: 'Medico/a', value: 'medico'},
    {key: 'p-6', text: 'Infermiere/a', value: 'infermiere'},
    {key: 'p-9', text: 'Altro', value: 'altro'}
];

const ProfessionalDetails = forwardRef((props: { user: CognitoUserInterface | undefined }, ref) => {
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const dispatch: Dispatch<any> = useDispatch();
    const [fiscalCode, setFiscalCode] = useState<string>('');
    //
    const [savingDetails, setSavingDetails] = useState<boolean>(false);
    const [retrievingDetails, setRetrievingDetails] = useState<boolean>(true);
    const [userProfession, setUserProfession] = useState<UserProfession>({
        sdi: '',
        profession_id: '',
        profession: '',
        vat: '',
        pec: '',
        verified_vat: false,
        verified_profession_id: false
    });

    useEffect(() => {
        let isSubscribed = true;

        UserService.getProfessionDetails().then((res: UserProfession) => {
            if (isSubscribed) {
                setUserProfession(res);
            }
        })
            .catch(error => dispatch(sendToast({title: 'Error', messages: [error.message], color: 'negative'})))
            .finally(() => {
                setRetrievingDetails(false);
            });

        return () => {
            isSubscribed = false;
        };
    }, []);


    const saveDetails = () => {
        setSavingDetails(true);
        UserService.saveUserProfessionDetails(userProfession).then(res => {
            dispatch(sendToast({title: 'Success', messages: ['Saved'], color: 'positive'}));
        })
            .catch(error => dispatch(sendToast({title: 'Error', messages: [error.message], color: 'negative'})))
            .finally(() => {
                setSavingDetails(false);
            });
    };

    return <>
        <h2>{LangProfile.professionalDetails.miaomiao[lang].toUpperCase()}</h2>
        <Item>
            <Item.Content>
                <Form className={savingDetails || retrievingDetails ? 'loading' : ''}>
                    <Form.Group widths='equal'>
                        <Form.Input fluid label='SDI' placeholder={LangProfile.professionalDetails.enterSDI[lang]}
                                    value={userProfession?.sdi}
                                    onBlur={saveDetails}
                                    onChange={(e, data: InputOnChangeData) => setUserProfession({
                                        ...userProfession,
                                        sdi: data.value
                                    })}/>
                        <Form.Input fluid label={LangProfile.professionalDetails.VAT[lang]}
                                    placeholder={LangProfile.professionalDetails.enterVAT[lang]}
                                    value={userProfession?.vat}
                                    onBlur={saveDetails}
                                    onChange={(e, data: InputOnChangeData) => setUserProfession({
                                        ...userProfession,
                                        vat: data.value
                                    })}/>
                        <Form.Input fluid label='PEC' placeholder={LangProfile.professionalDetails.enterPEC[lang]}
                                    value={userProfession?.pec}
                                    onBlur={saveDetails}
                                    onChange={(e, data: InputOnChangeData) => setUserProfession({
                                        ...userProfession,
                                        pec: data.value
                                    })}/>
                    </Form.Group>

                    <Form.Group widths={"equal"}>
                        <Form.Select
                            fluid
                            value={userProfession?.profession}
                            label={LangProfile.professionalDetails.profession[lang]}
                            onChange={(e, data: InputOnChangeData) => {
                                setUserProfession({
                                    ...userProfession,
                                    profession: data.value
                                });
                                saveDetails();
                            }}
                            options={professions}
                            placeholder={LangProfile.professionalDetails.enterProfession[lang]}
                        />
                        <Form.Input fluid label={LangProfile.professionalDetails.professionID[lang]}
                                    placeholder={LangProfile.professionalDetails.enterProfessionID[lang]}
                                    value={userProfession?.profession_id}
                                    onBlur={saveDetails}
                                    onChange={(e, data: InputOnChangeData) => setUserProfession({
                                        ...userProfession,
                                        profession_id: data.value
                                    })}/>

                        <Form.Input fluid label={LangProfile.personalDetails.fiscalCode[lang]}
                                    placeholder={LangProfile.personalDetails.fiscalCode[lang]} value={fiscalCode}
                                    onBlur={() => {
                                        if (fiscalCode && fiscalCode.length > 0 && props.user?.attributes["custom:fiscal_code"] !== fiscalCode) {
                                            setSavingDetails(true);
                                            Auth.updateUserAttributes(props.user, {
                                                "custom:fiscal_code": fiscalCode
                                            }).then(r => {
                                                dispatch(sendToast({
                                                    title: LangProfile.success[lang],
                                                    messages: ['User updated'],
                                                    color: 'positive'
                                                }));
                                            })
                                                .catch(error => {
                                                    console.log('errormessage', error);
                                                    dispatch(sendToast({
                                                        title: LangProfile.error[lang],
                                                        messages: [error.message],
                                                        color: 'negative'
                                                    }));
                                                })
                                                .finally(() => {
                                                    setSavingDetails(false);
                                                });
                                        }
                                    }}
                                    onChange={(e, data: InputOnChangeData) => setFiscalCode(data.value)}/>
                    </Form.Group>
                </Form>
            </Item.Content>
        </Item>
    </>;
});
export default ProfessionalDetails;
