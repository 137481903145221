import {createReducer} from "@reduxjs/toolkit";
import {
    ApplicationConfigurationState,
    HIDE_SIDEBAR,
    SEND_TOAST,
    TOGGLE_SIDEBAR
} from "../types/ApplicationConfigurationTypes";

const initialState: ApplicationConfigurationState ={
    displayVerticalSidebar: true,
    toast: null
};

export const ApplicationConfigurationReducer = createReducer(initialState, (builder => {
    builder
        .addCase(TOGGLE_SIDEBAR, (state: ApplicationConfigurationState, action: any) => {
            state.displayVerticalSidebar = !state.displayVerticalSidebar;
        })
        .addCase(HIDE_SIDEBAR, (state: ApplicationConfigurationState, action: any) => {
            state.displayVerticalSidebar = false;
        })
        .addCase(SEND_TOAST, (state: ApplicationConfigurationState, action: any) => {
            state.toast = action.payload;
        });
}));
