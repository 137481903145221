export const LangSignIn = {
    header: {
        en: 'Login to your account',
        it: 'Login con il tuo account'
    },
    button: {
        en: 'Sign in',
        it: 'Accedi'
    },
    forgotPassword: {
        en: 'Forgot your password?',
        it: 'Hai dimenticato la password?'
    },
    createAccount: {
        en: 'Create an account',
        it: 'Crea un nuovo account'
    },
    register: {
        en: 'Is this your first time here?',
        it: 'É la prima volta che capiti qui?'
    },
    signUp: {
        en: '',
        it: ''
    },
    text: {
        en: 'Do you want to buy Augmented Psychology courses and apps?',
        it: 'Vuoi acquistare i corsi e le app di Psicologia Aumentata?'
    }
};
