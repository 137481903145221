export const LangResults = {
    results: {
        en: 'Results',
        it: 'Risultati'
    },
    correctAnswers: {
        en: 'Correct answers',
        it: 'Risposte corrette'
    },
    errors: {
        en: 'Errors',
        it: 'Errori'
    },
    success: {
        en: 'Success rate',
        it: 'Percentuale di successo'
    }
};
