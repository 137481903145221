import React, {Dispatch, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Application} from "../../interfaces/Application";
import {LangApplications} from "../../lang/Applications";
import {Button, Dimmer, Flag, Item, Label, Loader, Segment} from 'semantic-ui-react';
import {useHistory} from "react-router-dom";
import {RootState} from "../../store";
import {UserApplication} from "../../interfaces/UserApplication";
import {SubscriptionStatus} from "../../enums/SubscriptionStatus";
import {UserCourse} from "../../interfaces/UserCourse";
import {applicationsSelector, fetchApplications} from '../../store/slices/ApplicationSlice';
import {fetchUserApplications, userApplicationsSelector} from "../../store/slices/UserApplicationSlice";
import {fetchUserCourses, userCoursesSelector} from "../../store/slices/UserCourseSlice";

const Applications: React.FC = () => {

        const dispatch: Dispatch<any> = useDispatch();
        const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
        const {applications, retrievingApplications, applicationsFetched} = useSelector(applicationsSelector);
        const {userCourses, retrievingUserCourses, userCoursesFetched} = useSelector(userCoursesSelector);

        const {
            userApplications,
            retrievingUserApplications,
            userApplicationsFetched
        } = useSelector(userApplicationsSelector);


        const history = useHistory();

        useEffect(() => {
            let isSubscribed = true;
            window.document.title = LangApplications.windowTitle[lang];

            if (!userApplicationsFetched) {
                dispatch(fetchUserApplications());
            }
            return () => {
                isSubscribed = false;
            };
        }, []);

        useEffect(() => {
            if (!applicationsFetched.includes(lang)) {
                dispatch(fetchApplications(lang));
            }
            if (!userCoursesFetched.includes(lang)) {
                dispatch(fetchUserCourses(lang));
            }
        }, [lang]);


        const textSwitch = (app: Application, available: boolean, active: boolean) => {
            if (active) {
                return LangApplications.description1[lang];
            }
            if (app.prices.length === 0) {
                return <>{LangApplications.description2[lang]} <Label color='orange' size='mini'>beta</Label>!</>;
            }
            if (!available) {
                return LangApplications.description3[lang];
            }
            return LangApplications.description4[lang];
        };

        const buttonSwitch = (app: Application, available: boolean, active: boolean) => {
            if (active) {
                return <Button
                    onClick={() => history.push('/apps/' + app.slug)}
                    size='mini' icon='chevron circle right' color='green' label='Start'/>;
            }
            if (app.prices.length === 0) {
                return <Button size='mini' icon='lock' color='orange' label='Apply for early access'/>;
            }
            if (!available) {
                return <Button size='mini' icon='lock' color='red' label='Locked' disabled/>;
            }
            return <Button onClick={() => history.push('/apps/' + app.slug + '/checkout')}
                           size='mini' icon='unlock' color='blue' label='Unlock'/>;
        };


        return (
            <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
                <h1>{LangApplications.title[lang].toUpperCase()}</h1>
                <Dimmer inverted
                        className={retrievingApplications || retrievingUserApplications || retrievingUserCourses ? 'active' : ''}
                        inline='centered'>
                    <Loader content='Loading'/>
                </Dimmer>
                <Item.Group relaxed divided>
                    {userCourses[lang] && applications[lang] && applications[lang].map((app: Application) => {
                        const userHasApplications = userApplications.filter((ua: UserApplication) => ua.application_id === app.id);
                        let userHasApplication = userHasApplications.length > 0 ? userHasApplications[0] : null;
                        for (let k = 0; k < userHasApplications.length; k++) {
                            if (userHasApplications[k].subscription_status === SubscriptionStatus.active) {
                                userHasApplication = userHasApplications[k];
                            }
                        }

                        const userHasAppCourse = userCourses[lang].find((c: UserCourse) => c.course_id === app.course_id);
                        const active: boolean = userHasApplication?.subscription_status === SubscriptionStatus.active;
                        let available: boolean = false;
                        if (app.course_id === null) {
                            available = true;
                        } else {
                            available = active ? true : userHasAppCourse?.status === 'completed';
                        }
                        return (
                            <Item key={'application-' + app.id}>
                                <Item.Image size='tiny' alt={app.name + ' logo'} src={app.icon}
                                            style={{opacity: available || active ? '1' : '0.5'}}/>
                                <Item.Content verticalAlign='middle'>
                                    <Item.Header
                                        style={{opacity: available || active ? '1' : '0.5'}}><Flag
                                        name={lang === 'en' ? 'uk' : 'it'}/> {app.name}</Item.Header>
                                    <Item.Description>
                                        {textSwitch(app, available, active)}
                                    </Item.Description>
                                    <Item.Extra>
                                        {buttonSwitch(app, available, active)}
                                    </Item.Extra>
                                </Item.Content>

                            </Item>
                        );
                    })}
                </Item.Group>
            </Segment>
        );
    }
;

export default Applications;
