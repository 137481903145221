import React, {Dispatch, useCallback, useEffect, useState} from 'react';
import FoodList from "../interfaces/FoodList";
import {Food} from "../interfaces/Food";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import AugmentedFoodService from "../services/AugmentedFoodService";
import {
    Breadcrumb,
    Card,
    Checkbox,
    Dimmer, Divider,
    Grid,
    Header,
    List,
    Loader, Search, SearchResultData,
    Transition
} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DraggableListItem} from "../components/DraggableListItem";
import {sendToast} from "../../../store/actions/ApplicationConfigurationActions";
import _ from "lodash";
import {SecondaryButton} from "../../../components/Buttons/SecondaryButton";
import {fetchFoodLists, foodListsSelector} from "../../../store/slices/FoodListsSlice";

const EditFoodLists: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const {listId} = useParams<{ listId: string; }>();
    const {retrievingFoodLists, foodLists, foodListsFetched} = useSelector(foodListsSelector);

    const [foodList, setFoodList] = useState<FoodList>();
    const [food, setFood] = useState<Food[]>([]);
    const [selectedFood, setSelectedFood] = useState<Food[]>([]);

    const [retrievingFood, setRetrievingFood] = useState<boolean>(true);
    const [savingConfiguration, setSavingConfiguration] = useState<boolean>(false);

    // search
    const [loading, setLoading] = useState<boolean>(false);
    const [foodResults, setFoodResults] = useState<any[]>([]);
    const [searchFood, setSearchFood] = useState<string>('');

    const toggleElement = (food: Food) => {
        const clonedFoodList = [...selectedFood];
        let found = false;
        for (let k = 0; k < clonedFoodList.length; k++) {
            if (clonedFoodList[k].id === food.id) {
                clonedFoodList.splice(k, 1);
                found = true;
                break;
            }
        }
        if (!found) {
            clonedFoodList.push(food);
        }

        setSelectedFood(clonedFoodList);
    };

    let timeoutRef: any = null;

    const handleSearchChange = React.useCallback((e, data) => {
        clearTimeout(timeoutRef);
        setSearchFood(data.value);
        setLoading(true);

        timeoutRef = setTimeout(() => {
            const re = new RegExp(_.escapeRegExp(data.value), 'i');
            const isMatch = (result: Food) => re.test(result.title.toLowerCase());
            setLoading(false);
            setFoodResults(_.filter(food, isMatch));
        }, 300);
    }, []);

    const handleResultSelect = (e: any, value: SearchResultData) => {
        const result: Food = value.result;
        setSearchFood(result.title.toLowerCase());
        toggleElement(result);
    };


    useEffect(() => {
        let isSubscribed = true;
        if (!foodListsFetched) {
            dispatch(fetchFoodLists());
        }
        AugmentedFoodService.listFood().then((res: Food[]) => {
            if (isSubscribed) {
                for (let i = 0; i < res.length; i++) {
                    res[i].title = res[i].name;
                }
                //setFoodResults(food);
                setFood(res);
                setRetrievingFood(false);
            }
        });
        return () => {
            isSubscribed = false;
            clearTimeout(timeoutRef);
        };
    }, []);

    useEffect(() => {
        if (foodLists.length > 0) {
            const foodListR = foodLists.find((foodList: FoodList) => foodList.id === parseInt(listId));
            setSelectedFood([...foodListR.food]);
        }
    }, [foodLists]);


    const attachFood = () => {
        setSavingConfiguration(true);
        AugmentedFoodService.attachFoodToFoodList(parseInt(listId), selectedFood).then((res) => {
            dispatch(sendToast({title: 'Success', messages: ['Configuration updated!'], color: 'positive'}));
        }).catch(err => {
            dispatch(sendToast({title: 'Error', messages: [err.message], color: 'negative'}));
        }).finally(() => {
            setSavingConfiguration(false);
        });
    };

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const oldFood = {...selectedFood[dragIndex]};
            const newFood = {...selectedFood[hoverIndex]};
            const oldSelectedFood = [...selectedFood];
            oldSelectedFood[hoverIndex] = oldFood;
            oldSelectedFood[dragIndex] = newFood;
            setSelectedFood(oldSelectedFood);
        },
        [selectedFood],
    );

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Section as={Link} to='/applications'>Applications</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section as={Link} to='/apps/food'>Augmented Food Exposure</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section active>{foodList?.name}</Breadcrumb.Section>
            </Breadcrumb>
            <Header as='h1' size={'huge'}>Edit food list: {foodList?.name}</Header>
            <div style={{textAlign: 'right'}}>
                <SecondaryButton
                    disabled={savingConfiguration}
                    className={savingConfiguration ? 'loading' : ''}
                    onClick={() => attachFood()}>Update</SecondaryButton>
            </div>
            <Divider/>
            <Grid centered>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Card fluid>
                            <Card.Content>
                                <Card.Header>Select food</Card.Header>
                                <Card.Description style={{overflow: 'hidden'}}>
                                    <Dimmer inverted
                                            className={retrievingFoodLists || retrievingFood ? 'active' : ''}
                                            inline='centered'>
                                        <Loader content='Loading'/>
                                    </Dimmer>
                                    <Search
                                        loading={loading}
                                        placeholder={'Search by name'}
                                        input={{icon: 'search', iconPosition: 'left'}}
                                        onResultSelect={(e, value: SearchResultData) => handleResultSelect(e, value)}
                                        onSearchChange={_.debounce(handleSearchChange, 500, {
                                            leading: true,
                                        })}
                                        results={foodResults}
                                        value={searchFood}
                                    />
                                    <List animated relaxed divided
                                          style={{overflowY: 'scroll', height: '400px'}}>
                                        {food?.map((f: Food) => {
                                            return <List.Item key={'f-' + f.id} style={{cursor: 'pointer'}}
                                                              onClick={() => toggleElement(f)}>
                                                <List.Content floated={'left'}>
                                                    <Checkbox
                                                        checked={selectedFood.some((food: Food) => food.id === f.id)}/>
                                                </List.Content>
                                                <List.Content>{f.name}</List.Content>
                                            </List.Item>;
                                        })}
                                    </List>

                                </Card.Description>
                            </Card.Content>

                        </Card>

                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Card fluid style={{height: '100%'}}>
                            <Card.Content>
                                <Dimmer inverted className={retrievingFoodLists ? 'active' : ''} inline='centered'>
                                    <Loader content='Loading'/>
                                </Dimmer>
                                <Card.Header>Sort</Card.Header>
                                <Card.Description style={{overflow: 'hidden'}}>

                                    <DndProvider backend={HTML5Backend}>
                                        <Transition.Group animation={'slide right'} duration={500} as={List} relaxed
                                                          divided
                                                          style={{
                                                              overflowY: 'scroll',
                                                              height: '400px',
                                                          }}>
                                            {selectedFood?.map((f: Food, index: number) => {
                                                return <List.Item key={'f-' + f.id} style={{cursor: 'pointer'}}>
                                                    <DraggableListItem food={f} index={index}
                                                                       toggleElement={toggleElement}
                                                                       moveCard={moveCard}/>
                                                </List.Item>;
                                            })}
                                        </Transition.Group>
                                    </DndProvider>

                                </Card.Description>
                            </Card.Content>

                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>
            <div style={{textAlign: 'right'}}>
                <SecondaryButton
                    disabled={savingConfiguration}
                    className={savingConfiguration ? 'loading' : ''}
                    onClick={() => attachFood()}>Update</SecondaryButton>
            </div>
        </>
    );
};

export default EditFoodLists;

/*
<IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>Edit food list "{foodList?.name}"</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <div style={{textAlign: "center"}}>
                    <IonButton size={"small"} routerLink={"/augmentedFood/food_lists"} color={Color.light}>
                        Back to food lists
                    </IonButton>
                </div>

                <IonCard>
                    <IonCardHeader>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between"
                        }}>
                            <IonCardTitle>ADD FOOD</IonCardTitle>
                        </div>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonSelect onIonChange={e => toggleElement(e.detail.value)}
                                   placeholder={retrievingFood || retrievingListFoodList ? "Hold on, retrieving food" : "Add - remove food"}
                                   interface="action-sheet">
                            {food.map((f: Food) => {
                                return <IonSelectOption value={f}
                                                        key={'select-food-' + f.id}>{f.name}</IonSelectOption>
                            })}
                        </IonSelect>
                    </IonCardContent>
                </IonCard>

                {retrievingFood || retrievingListFoodList ? <IonProgressBar type="indeterminate"/> :
                    <>
                        <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                            {selectedFood.map((sf: Food) => {
                                return <IonItemSliding key={'sf-' + sf.id}>
                                    <IonItemOptions side="start">
                                        <IonItemOption color="danger" expandable onClick={() => toggleElement(sf)}>
                                            Remove
                                        </IonItemOption>
                                    </IonItemOptions>
                                    <IonItem>
                                        <IonReorder slot="start"/>
                                        <IonLabel>{sf.name}</IonLabel>
                                    </IonItem>
                                </IonItemSliding>
                            })}
                        </IonReorderGroup>
                    </>}

                <div style={{textAlign: "right", paddingRight: '15px'}}>
                    <IonButton color={Color.success} onClick={attachFood}>Update</IonButton>
                </div>
            </IonContent>
        </IonPage>
 */
