export const LangSecurity = {
    title: {
        it: 'Sicurezza',
        en: 'Security'
    },
    phoneVerification:{
        en: 'Phone verification',
        it: 'Verifica numero di telefono'
    },
    validatePhone:{
        en: 'In order to secure your account and validate your data you can verify your mobile phone.',
        it: 'Per rendere più sicuro il tuo account e validarne l\'autenticità è consigliato verificare il proprio numero di cellulare'
    },
    phone: {
      en: 'Phone',
      it: 'Cellulare'
    },
    twoFactorAuth:{
        en: '2Factor Authenticator',
        it: 'Autenticazione a due fattori'
    },
    twoFactorDescr: {
        en: '2FA is an extra layer of security used to make sure that people trying to gain access to an online account are who they say they are.',
        it: 'L\'autenticazione a due fattori aggiunge un livello di sicurezza al tuo account. Riceverai un codice di accesso sul tuo cellulare per confermare la tua identità'
    },
    scanQR: {
      it: 'Scansiona questo QR code con un\'applicazione come:',
      en: 'Scan this QR code with an application like:'
    },
    otherApp: {
      it: 'o l\'applicazione che preferisci.',
      en: 'or the application you prefer.'
    },
    changePw:{
        en: 'Change password',
        it: 'Cambia password'
    },
    document: {
      en: 'Security | BECOME',
      it: 'Sicurezza | BECOME'
    },
    accountItem: {
      it: 'Profilo',
      en: 'Account'
    },
    activityLogItem: {
      it: 'Controllo Attività',
      en: 'Activity Log'
    },
    success: {
      it: 'Successo',
      en: 'Success'
    },
    SMSsent: {
      it: 'Messaggio inviato, controlla il telefono',
      en: 'SMS sent! Check your phone'
    },
    userUpdate: {
      it: 'Utente aggiornato',
      en: 'User updated'
    },
    error: {
      it: 'Errore',
      en: 'Error'
    },
    authenticatorDisabled: {
      it: 'Autenticazione disabilitata',
      en: 'Authenticator disabled'
    },
    authenticatorEnabled: {
        it: 'Autenticazione abilitata',
        en: 'Authenticator enabled'
    },
    codeValid: {
      it: 'Il codice è valido',
      en: 'Code is valid'
    },
    invalidCode: {
      it: 'Codice non valido',
      en: 'Invalid code'
    },
    updatedPassword: {
      it: 'Password aggiornata',
      en: 'Password updated'
    },
    disable: {
      it: 'Disabilita',
      en: 'Disable'
    },
    setup: {
      it: 'Installa',
      en: 'Setup'
    },
    enable: {
      it: 'Abilita',
      en: 'Enable'
    },
    save: {
      it: 'Salva',
      en: 'Save'
    },
    SMSSent2: {
      it: 'Ti abbiamo appena mandato un SMS',
      en: 'We just sent an SMS'
    },
    checkPhone: {
      it: 'Controlla il cellulare e inserisci qui il codice',
      en: 'Check your phone and enter it here'
    },
    resendSMS: {
      it: 'Manda nuovamente il messaggio',
      en: 'Resend SMS'
    },
    close: {
      it: 'Chiudi',
      en: 'Close'
    },
    oldPw: {
      it: 'Vecchia password',
      en: 'Old password'
    },
    newPw: {
      it: 'Nuova Password',
      en: 'New Password'
    },
    pwRules: {
      it: 'La password deve rispettare queste regole:',
      en: 'Your password must follow these rules:'
    },
    pwRules2: {
      it: 'Dev\'essere lunga tra gli 8 e i 30 caratteri.',
      en: 'Must be between 8 and 30 characters.'
    },
    pwRules3: {
      it: 'Deve contenere almeno una lettera maiuscola (es. A, B, ecc).',
      en: 'Must contain at least one uppercase, or capital, letter (ex: A,B, etc.)'
    },
    pwRules4: {
      it: 'Deve contenere almeno una lettera minuscola.',
      en: 'Must contain at least one lowercase letter.'
    },
    pwRules5: {
      it: 'Deve contenere almeno un numero (es. 0, 1, 2, 3, ecc).',
      en: 'Must contain at least one number digit (ex: 0,1,2,3 etc)'
    },
    pwRules6: {
        it: 'Deve contenere almeno un carattere speciale (es. $,#,@,!,$,^,&,*,(,),-)',
        en: 'Must contain at least one special character (ex: $,#,@,!,$,^,&,*,(,),-)'
    },
    verificationCode: {
      it: 'Inserisci qui il codice di verifica.',
      en: 'Enter the verification code here.'
    },
    verificationCode2: {
      it: 'Codice di verifica',
      en: 'Verification code'
    },
    verify: {
      it: 'Verifica',
      en: 'Verify'
    },
    SMSError:{
        it: 'Limite SMS giornaliero raggiunto, ritentare domani',
        en: 'SMS limit raised! Please try again tomorrow',
    }
};
