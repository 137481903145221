import {UserApplication} from "../interfaces/UserApplication";
import {SubscriptionStatus} from "../enums/SubscriptionStatus";

export const ApplicationMiddleware = (history, userApplications, application) => {

    const userHasApplications = userApplications.filter((ua: UserApplication) => ua.application_id === application.id);

    let userHasApplication = userHasApplications.length > 0 ? userHasApplications[0] : null;

    for (let k = 0; k < userHasApplications.length; k++) {
        if (userHasApplications[k].subscription_status === SubscriptionStatus.active) {
            userHasApplication = userHasApplications[k];
        }
    }

    if (userHasApplication && userHasApplication.subscription_status) {
        switch (userHasApplication.subscription_status) {
            case SubscriptionStatus.inactive:
            case SubscriptionStatus.canceled:
                history.push('/apps/' + application.slug + '/checkout');
                break;
            case SubscriptionStatus.paused:
                // todo manage
                console.log('manage status paused');
                break;
        }
    } else {
        history.push('/applications');
    }

};
