import {AxiosInterceptor} from "../AxiosInterceptor";
import {AxiosInstance, AxiosResponse} from "axios";

export default class StudentService {
    static saveStudentDetails = async (schoolName: string): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/application/student/save_details';
                return ax.post(url, {name: schoolName}).then((res: AxiosResponse) => {
                    return res.data;
                });
            }).catch(err => Promise.reject(err));
    };
}



