import React, {Dispatch, forwardRef, useEffect, useState} from "react";
import {Button, Divider, Dropdown, Input, InputOnChangeData, Label, Message, Segment, Form} from "semantic-ui-react";
import {loadStripe, PaymentMethod, StripeError} from "@stripe/stripe-js";
import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import {sendToast} from "../store/actions/ApplicationConfigurationActions";
import StripeService from "../services/user/StripeService";
import {COUNTRY_OPTIONS} from "../data/countriesData";
import { addPaymentMethod } from "../store/slices/PaymentMethods";


const stripePromise = loadStripe((process.env.REACT_APP_LIVE_MODE === 'true' ? process.env.REACT_APP_STRIPE_LIVE : process.env.REACT_APP_STRIPE_TEST) ?? '');
export const CardComponent: React.FC<{ setCardModalOpen: any }> = (props: { setCardModalOpen }) => {

    const dispatch: Dispatch<any> = useDispatch();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);


    return (
        <Segment style={{textAlign: 'center', color: 'rgba(0,0,0,.87)'}}>
            <Elements stripe={stripePromise}>
                <FormStripe dispatch={dispatch}
                            setCardModalOpen={props.setCardModalOpen}/>
                <Divider/>
                <p style={{fontSize: '0.8em', marginTop: '1em'}}>We are <b>not storing</b> your card information on our
                    servers.
                    We use <a href='https://stripe.com/'
                              target='_blank'
                              rel={'noopener noreferrer'}>stripe</a> to
                    manage payments</p>
            </Elements>
        </Segment>
    );
};


const FormStripe = forwardRef((props: {dispatch: any, setCardModalOpen: any }, ref) => {
    const stripe = useStripe();
    const elements = useElements();

    const [formError, setFormError] = useState<string>('');
    // billing
    const [address, setAddress] = useState<string>('');
    const [addressError, setAddressError] = useState<string>('');

    const [cap, setCap] = useState<string>('');
    const [capError, setCapError] = useState<string>('');

    const [country, setCountry] = useState<string>('');
    const [countryError, setCountryError] = useState<string>('');

    const [province, setProvince] = useState<string>('');
    const [provinceError, setProvinceError] = useState<string>('');

    const [billingName, setBillingName] = useState<string>('');
    const [billingNameError, setBillingNameError] = useState<string>('');

    const [inProgress, setInProgress] = useState<boolean>(false);

    const handleSubmit = async () => {

        if (country === '') {
            setCountryError('Campo richiesto');
            return;
        }
        if (cap === '') {
            setCapError('Campo richiesto');
            return;
        }
        if (province === '') {
            setProvinceError('Campo richiesto');
            return;
        }
        if (address === '') {
            setAddressError('Campo richiesto');
            return;
        }
        if (billingName === '') {
            setBillingNameError('Campo richiesto');
            return;
        }


        if (!stripe || !elements) {
            return;
        }
        setInProgress(true);
        const cardElement = elements.getElement(CardElement);
        stripe
            .createPaymentMethod({
                type: 'card',
                card: cardElement!,
                billing_details: {
                    name: billingName,
                    address: {
                        country: country,
                        line1: address,
                        postal_code: cap,
                        state: province
                    }
                }
            })
            .then((result: { error?: StripeError, paymentMethod?: PaymentMethod }) => {
                if (result.error) {
                    console.log('error?', result.error);
                    props.dispatch(sendToast({title: 'Error', messages: [result.error.message!], color: 'negative'}));
                }
                if (result.paymentMethod) {
                    StripeService.addCard(result.paymentMethod.id).then((res: PaymentMethod) => {
                        props.dispatch(addPaymentMethod(res));
                        cardElement?.clear();
                        props.dispatch(sendToast({
                            title: 'Success',
                            messages: ['Payment method successfully added'],
                            color: 'positive'
                        }));
                    }).catch(err => {
                        if (err.message !== 'This Element has already been destroyed. Please create a new one.') {
                            props.dispatch(sendToast({
                                title: 'Error',
                                messages: [err.message],
                                color: 'negative'
                            }));
                        }
                    });
                }
                setInProgress(false);
                props.setCardModalOpen(false);
            });
    };

    return (<div>
        <Form error={formError !== ''}>
            <h4>Dati di fatturazione</h4>
            <Form.Field>
                <Input size={'mini'} value={billingName}
                       onChange={(e) => setBillingName(e.target.value)}
                       error={billingNameError !== ''}
                       onFocus={() => setBillingNameError('')}
                       placeholder={'Nome completo'}/>
                {billingNameError !== '' ?
                    <Label pointing prompt>
                        {billingNameError}
                    </Label> : <></>
                }
            </Form.Field>
            <Form.Group>
                <Form.Field width={12}>
                    <Input size={'mini'} value={address}
                           onChange={(e) => setAddress(e.target.value)}
                           error={addressError !== ''}
                           onFocus={() => setAddressError('')}
                           placeholder={'Via/Piazza e numero civico'}/>
                    {addressError !== '' ?
                        <Label pointing prompt>
                            {addressError}
                        </Label> : <></>
                    }
                </Form.Field>
                <Form.Field width={4}>
                    <Input size={'mini'} value={province}
                           onChange={(e) => setProvince(e.target.value)}
                           error={provinceError !== ''}
                           onFocus={() => setProvinceError('')}
                           placeholder={'Provincia'}/>
                    {provinceError !== '' ?
                        <Label pointing prompt>
                            {provinceError}
                        </Label> : <></>
                    }
                </Form.Field>
            </Form.Group>
            <Form.Group widths={'equal'}>
                <Form.Field>
                    <Input size={'mini'} value={cap}
                           onChange={(e) => setCap(e.target.value)}
                           error={capError !== ''}
                           onFocus={() => setCapError('')}
                           placeholder={'Cap'}/>
                    {capError !== '' ?
                        <Label pointing prompt>
                            {capError}
                        </Label> : <></>
                    }
                </Form.Field>
                <Form.Field>
                    <Dropdown
                        style={{
                            minHeight: '31px',
                            padding: '7px',
                            fontSize: '12px'
                        }}
                        size={'mini'}
                        placeholder='Select Country'
                        value={country}
                        error={countryError !== ''}
                        selection
                        search
                        options={COUNTRY_OPTIONS}
                        onChange=
                            {
                                (e, data: InputOnChangeData) => setCountry(data.value)
                            }
                    />
                    {countryError !== '' ?
                        <Label pointing prompt>
                            {countryError}
                        </Label> : <></>
                    }
                </Form.Field>
            </Form.Group>
            {formError !== '' ? <Message
                error
                header='Error'
                content={formError}
            /> : <></>}
        </Form>

        <h4>Dati della carta</h4>
        <Divider/>
        <CardElement/>
        <Button color="blue" onClick={handleSubmit}
                style={{marginTop: '25px', width: '100%'}}
                expand="block"
                className={inProgress ? 'loading' : ''}
                disabled={!stripe || inProgress}>Add card</Button>
    </div>);
});
