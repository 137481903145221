import React, {Dispatch, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {CognitoUserInterface} from "@aws-amplify/ui-components";
import {Auth} from "aws-amplify";
import {
    Item,
    Form,
    InputOnChangeData,
    Icon,
    Button,
    Modal,
    Header,
    Input,  List,
} from "semantic-ui-react";
import {sendToast} from "../../../store/actions/ApplicationConfigurationActions";
import QRCode from "qrcode.react";
import RegExpLibrary from "../../../utils/validators/RegExp";
import {LangSecurity} from "../../../lang/Security";
import {SecondaryButton} from "../../../components/Buttons/SecondaryButton";

const Account: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();

    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const [user, setUser] = React.useState<CognitoUserInterface>();

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [savingPhoneNumber, setSavingPhoneNumber] = useState<boolean>(false);
    const [forceNumberVerified, setForceNumberVerified] = useState<boolean>(false);

    const [verificationCode, setVerificationCode] = useState<string>('');
    const [sendingVerificationCode, setSendingVerificationCode] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);


    const [force2FA, setForce2FA] = useState<boolean>(false);
    const [settingUpAuthenticator, setSettingUpAuthenticator] = useState<boolean>(false);
    const [enablingAuthenticator, setEnablingAuthenticator] = useState<boolean>(false);
    const [code, setCode] = useState<string>('');
    const [challengeAnswer, setChallengeAnswer] = useState<string>('');

    const [retrievingUserData, setRetrievingUserData] = useState<boolean>(true);


    const [oldPassword, setOldPassword] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [savingNewPassword, setSavingNewPassword] = useState<boolean>(false);
    const [validLen, setValidLen] = useState<boolean>(false);
    const [validUpperCase, setValidUppercase] = useState<boolean>(false);
    const [validLowercase, setValidLowercase] = useState<boolean>(false);
    const [validDigit, setValidDigit] = useState<boolean>(false);
    const [validSpecial, setValidSpecial] = useState<boolean>(false);
    const [displayPwHelper, setDisplayPwHelper] = useState<boolean>(false);
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);
    const [passwordError, setPasswordError] = useState<string>('');

    const [sendingSMS, setSendingSMS] = useState<boolean>(false);

    useEffect(() => {
        let isSubscribed = true;
        Auth.currentAuthenticatedUser({bypassCache: true}).then(res => {
            if (isSubscribed) {
                setPhoneNumber(res?.attributes.phone_number ?? '');
                setUser(res);
                setForceNumberVerified(res.attributes.phone_number_verified);
                setForce2FA(res?.preferredMFA !== "NOMFA");
                setRetrievingUserData(false);
            }
        });
        return () => {
            isSubscribed = false;
        };
    }, []);

    const sendSmsVerification = () => {
        setShowModal(true);
        setSendingSMS(true);
        Auth.verifyCurrentUserAttribute('phone_number')
            .then((res) => dispatch(sendToast({title: LangSecurity.success[lang], messages: [LangSecurity.SMSsent[lang]], color: 'positive'})))
            .catch(err => {
                setShowModal(false);
                dispatch(sendToast({title: LangSecurity.error[lang], messages: [LangSecurity.SMSError[lang]], color: 'negative'}));
            })
            .finally(() => setSendingSMS(false));
    };

    const savingPhone = () => {
        setSavingPhoneNumber(true);
        Auth.updateUserAttributes(user, {
            phone_number: phoneNumber
        }).then(r => {
            dispatch(sendToast({title: LangSecurity.success[lang], messages: [LangSecurity.userUpdate[lang]], color: 'positive'}));
            Auth.userAttributes(user).then(res => {
                console.log('res', res);
            });
        })
            .catch(error => {
                console.log('errormessage', error);
                dispatch(sendToast({title: LangSecurity.error[lang], messages: [error.message], color: 'negative'}));
            })
            .finally(() => {
                setSavingPhoneNumber(false);
                setForceNumberVerified(false);
            });
    };


    const setupAuthenticator = () => {
        setSettingUpAuthenticator(true);
        Auth.setupTOTP(user).then((code: string) => {
            setCode(code);
        }).catch(err => {
            dispatch(sendToast({title: LangSecurity.error[lang], messages: [err.message], color: 'negative'}));
        }).finally(() => {
            setSettingUpAuthenticator(false);
        });
    };

    const disableAuthenticator = () => {
        setSettingUpAuthenticator(true);
        Auth.setPreferredMFA(user, 'NOMFA').then((res) => {
            console.log('res,', res);
            Auth.getPreferredMFA(user, {bypassCache: true}).then(res => {
                dispatch(sendToast({title: LangSecurity.success[lang], messages: [LangSecurity.authenticatorDisabled[lang]], color: 'positive'}));
                setForce2FA(false);
                setSettingUpAuthenticator(false);
                setCode('');
            });
        }).catch(err => {
            dispatch(sendToast({title: LangSecurity.error[lang], messages: [err.message], color: 'negative'}));
            setSettingUpAuthenticator(false);
        });
    };

    const enableAuthenticator = () => {
        setEnablingAuthenticator(true);
        Auth.verifyTotpToken(user, challengeAnswer).then(res => {
            Auth.setPreferredMFA(user, 'TOTP').then(res => {
                Auth.userAttributes(user).then(res => {
                    dispatch(sendToast({title: LangSecurity.success[lang], messages: [LangSecurity.authenticatorEnabled[lang]], color: 'positive'}));
                    setEnablingAuthenticator(false);
                    setForce2FA(true);
                    setCode('');
                });
            }).catch(err => {
                dispatch(sendToast({title: LangSecurity.error[lang], messages: [err.message], color: 'negative'}));
            });
        }).catch(err => {
            dispatch(sendToast({title: LangSecurity.error[lang], messages: [err.message], color: 'negative'}));
            setEnablingAuthenticator(false);
        });
    };

    useEffect(() => {

        if (phoneNumber !== '' && verificationCode.length === 6) {
            setSendingVerificationCode(true);

            Auth.verifyCurrentUserAttributeSubmit('phone_number', verificationCode).then((res: string) => {
                dispatch(sendToast({title: LangSecurity.success[lang], messages: [LangSecurity.codeValid[lang]], color: 'positive'}));

                Auth.userAttributes(user).then(res => {
                    setForceNumberVerified(true);
                });

                setShowModal(false);

            }).catch(error => {

                dispatch(sendToast({title: LangSecurity.error[lang], messages: [LangSecurity.invalidCode[lang]], color: 'negative'}));

            }).finally(() => {
                setSendingVerificationCode(true);
            });
        }
    }, [verificationCode]);


    const savingPassword = () => {
        setSavingNewPassword(true);
        Auth.changePassword(user, oldPassword, password).then(res => {
            dispatch(sendToast({title: LangSecurity.success[lang], messages: [LangSecurity.updatedPassword[lang]], color: 'positive'}));
            Auth.userAttributes(user).then(res => {
            });
        }).catch(err => {
            dispatch(sendToast({title: LangSecurity.error[lang], messages: [err.message], color: 'negative'}));
        }).finally(() => {
            setSavingNewPassword(false);
        });
    };

    useEffect(() => {
        if (password.length > 0) {
            setValidDigit(new RegExp(/\d/).test(password));
            setValidUppercase(new RegExp(/[A-Z]/).test(password));
            setValidLowercase(new RegExp(/[a-z]/).test(password));
            setValidSpecial(new RegExp(/\W/).test(password));
            setValidLen(password.length >= 8 && password.length <= 30);
        } else {
            setValidDigit(false);
            setValidUppercase(false);
            setValidLowercase(false);
            setValidSpecial(false);
            setValidLen(false);
        }
    }, [password]);

    return <>
        <Item.Group>
            <Item>
                <Icon
                    name={retrievingUserData ? 'spinner' : (forceNumberVerified ? 'check circle' : 'warning sign')}
                    color={retrievingUserData ? 'grey' : (forceNumberVerified ? 'green' : 'red')}
                    style={{marginRight: '10px'}}
                    size={'large'}/>

                <Item.Content>
                    <Item.Header as='a'>{LangSecurity.phoneVerification[lang].toUpperCase()}</Item.Header>
                    <Item.Meta>{LangSecurity.validatePhone[lang]}</Item.Meta>
                    <Item.Description>
                        <Form.Input fluid label={LangSecurity.phone[lang]} placeholder={LangSecurity.phone[lang]} value={phoneNumber}
                                    onChange={(e, data: InputOnChangeData) => setPhoneNumber(data.value)}/>
                    </Item.Description>
                    <Item.Extra>

                        {forceNumberVerified ? <></> :
                            <SecondaryButton
                                    onClick={() => sendSmsVerification()}>{LangSecurity.verify[lang]}</SecondaryButton>
                        }
                        <SecondaryButton className={(savingPhoneNumber ? 'loading' : '')}
                                disabled={savingPhoneNumber}
                                onClick={() => savingPhone()}>{LangSecurity.save[lang]}</SecondaryButton>

                    </Item.Extra>
                </Item.Content>
            </Item>

            <Item style={{marginTop: '35px'}}>
                <Icon
                    name={retrievingUserData ? 'spinner' : (force2FA ? 'check circle' : 'warning sign')}
                    color={retrievingUserData ? 'grey' : (force2FA ? 'green' : 'red')}
                    style={{marginRight: '10px'}}
                    size={'large'}/>


                <Item.Content>
                    <Item.Header as='a'>{LangSecurity.twoFactorAuth[lang].toUpperCase()}</Item.Header>
                    <Item.Meta>{LangSecurity.twoFactorDescr[lang]}</Item.Meta>
                    <Item.Description>
                        {code ? <>
                                <div style={{display: 'flex', marginBottom: '15px'}}>
                                    <QRCode
                                        value={`otpauth://totp/${user?.attributes.email}?secret=${code}&issuer=BECOME`}/>
                                    <p style={{marginLeft: '25px'}}>
                                        {LangSecurity.scanQR[lang]}
                                        <ul>
                                            <li>
                                                <a target={'_blank'} rel={'noopener'}
                                                   href={'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US'}>
                                                    Google Authenticator [Android]
                                                </a>
                                            </li>
                                            <li>
                                                <a target={'_blank'} rel={'noopener'}
                                                   href={' https://apps.apple.com/us/app/google-authenticator/id388497605'}>
                                                    Google Authenticator [App Store]
                                                </a>
                                            </li>
                                        </ul>
                                        {LangSecurity.otherApp[lang]}
                                    </p>
                                </div>
                                <Form.Input fluid label= {LangSecurity.verificationCode[lang]}
                                            style={{width: '200px'}}
                                            placeholder= {LangSecurity.verificationCode2[lang]}
                                            value={challengeAnswer}
                                            onChange={(e, data: InputOnChangeData) => setChallengeAnswer(data.value)}/>
                            </>
                            : <></>}
                    </Item.Description>
                    <Item.Extra>
                        {force2FA ? <SecondaryButton disabled={settingUpAuthenticator}
                                            className={(settingUpAuthenticator ? 'loading' : '')}
                                            onClick={() => disableAuthenticator()}>{LangSecurity.disable[lang]}</SecondaryButton> :
                            (code === '' ?
                                    <SecondaryButton disabled={settingUpAuthenticator}
                                            className={(settingUpAuthenticator ? 'loading' : '')}
                                            onClick={() => setupAuthenticator()}>{LangSecurity.setup[lang]}</SecondaryButton> :

                                    <SecondaryButton
                                        disabled={enablingAuthenticator || challengeAnswer === ''}
                                        className={(enablingAuthenticator ? 'loading' : '')}
                                        onClick={() => enableAuthenticator()}>{LangSecurity.enable[lang]}</SecondaryButton>
                            )}
                    </Item.Extra>
                </Item.Content>
            </Item>


            <Item style={{marginTop: '35px'}}>
                <Icon
                    name='key'
                    style={{marginRight: '10px'}}
                    size={'large'}/>

                <Item.Content>
                    <Item.Header as='a'>{LangSecurity.changePw[lang].toUpperCase()}</Item.Header>
                    <Item.Description>

                    </Item.Description>
                    <Item.Extra>
                        <Form>
                            <Form.Input fluid label={LangSecurity.oldPw[lang]} placeholder={LangSecurity.oldPw[lang]}
                                        value={oldPassword} type={'password'}
                                        onChange={(e, data: InputOnChangeData) => setOldPassword(data.value)}/>

                            <div style={{position: "relative"}}>
                                <Form.Input fluid label={LangSecurity.newPw[lang]} placeholder={LangSecurity.newPw[lang]}
                                            value={password} type={'password'}
                                            onFocus={() => {
                                                setIsPasswordValid(true);
                                                setDisplayPwHelper(true);
                                                setPasswordError('');
                                            }}
                                            onBlur={() => {
                                                if (password.length > 0) {
                                                    setIsPasswordValid(RegExpLibrary.password.test(password));
                                                }
                                                setDisplayPwHelper(false);
                                            }}
                                            error={passwordError !== '' ? passwordError : false}
                                            onChange={(e, data: InputOnChangeData) => setPassword(data.value)}/>
                                <div style={{
                                    position: "absolute",
                                    background: "white",
                                    boxSizing: "border-box",
                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                                    left: 0,
                                    right: 0,
                                    top: '102%',
                                    padding: '14px',
                                    zIndex: 99,
                                    display: displayPwHelper ? 'block' : 'none'
                                }}>
                                    <Header as={'h4'}>
                                        <Icon name={'shield alternate'}/>
                                        {'Password security rules'.toUpperCase()}
                                    </Header>
                                    <div style={{fontSize: '13px', lineHeight: '14px'}}>
                                        <p style={{padding: 0}}>{LangSecurity.pwRules[lang]}</p>
                                        <List bulleted style={{marginTop: '-6px'}}>
                                            <List.Item style={{color: validLen ? '#21A558' : '#454F5B'}}>{LangSecurity.pwRules2[lang]}</List.Item>
                                            <List.Item style={{color: validUpperCase ? '#21A558' : '#454F5B'}}>{LangSecurity.pwRules3[lang]}</List.Item>
                                            <List.Item style={{color: validLowercase ? '#21A558' : '#454F5B'}}>{LangSecurity.pwRules4[lang]}</List.Item>
                                            <List.Item style={{color: validDigit ? '#21A558' : '#454F5B'}}>{LangSecurity.pwRules5[lang]}</List.Item>
                                            <List.Item style={{color: validSpecial ? '#21A558' : '#454F5B'}}>{LangSecurity.pwRules6[lang]}</List.Item>
                                        </List>
                                    </div>
                                </div>
                            </div>

                            <SecondaryButton
                                    className={(savingNewPassword ? 'loading' : '')}
                                    disabled={savingNewPassword || oldPassword === ''}
                                    onClick={() => savingPassword()}>{LangSecurity.save[lang]}</SecondaryButton>

                        </Form>
                    </Item.Extra>
                </Item.Content>
            </Item>
        </Item.Group>


        <Modal
            basic
            onClose={() => setShowModal(false)}
            onOpen={() => setShowModal(true)}
            open={showModal}
            size='small'
        >
            <Header icon size={'huge'}>
                <Icon name='mobile alternate'/>
                {LangSecurity.SMSSent2[lang]}
                <Header.Subheader style={{color: 'white'}}>
                    {LangSecurity.checkPhone[lang]}
                </Header.Subheader>
            </Header>
            <Modal.Content>


                <Form>
                    <Form.Group widths='equal'>
                        <Input style={{width: '100%'}}
                               placeholder={'123456'}
                               inverted
                               loading={sendingVerificationCode}
                               value={verificationCode}
                               onChange={(e, data: InputOnChangeData) => setVerificationCode(data.value)}/>
                    </Form.Group>
                </Form>
                <Header as='p' onClick={sendSmsVerification}
                        style={{textDecoration: 'underline', textAlign: 'right', cursor: 'pointer'}} color='blue'>{LangSecurity.resendSMS[lang]}</Header>

            </Modal.Content>
            <Modal.Actions>
                <Button basic color='grey' inverted onClick={() => setShowModal(false)}>
                    <Icon name='remove'/> {LangSecurity.close[lang]}
                </Button>
            </Modal.Actions>
        </Modal>
    </>;
};
export default Account;
