import {ApplicationService} from "../../services/applications/ApplicationService";
import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    retrievingApplications: false,
    hasErrors: false,
    applications: {},
    applicationsFetched: []
};

// A slice for recipes with our 3 reducers
const applicationsSlice = createSlice({
    name: 'applications',
    initialState,
    reducers: {
        getApplications: state => {
            state.retrievingApplications = true;
        },
        getApplicationsSuccess: (state, {payload}) => {
            const lang = payload.lang;
            const oldApplications = {...state.applications};
            oldApplications[lang] = payload.applications;
            state.applications = oldApplications;
            state.retrievingApplications = false;
            state.hasErrors = false;
            state.applicationsFetched = [...state.applicationsFetched, lang];
        },
        getApplicationsFailure: state => {
            state.retrievingApplications = false;
            state.hasErrors = true;
        },
    },
});

// The reducer
export default applicationsSlice.reducer;

// Selectors
export const applicationsSelector = state => state.applications;

// Actions
export const {getApplications, getApplicationsSuccess, getApplicationsFailure} = applicationsSlice.actions;

// Asynchronous thunk action
export const fetchApplications = (lang: string): (dispatch) => Promise<void> => {
    console.log('fetch applications', lang);
    return async dispatch => {
        dispatch(getApplications());
        try {
            await ApplicationService.list(lang).then(data => dispatch(getApplicationsSuccess({
                applications: data,
                lang: lang
            })));
        } catch (error) {
            dispatch(getApplicationsFailure());
        }
    };
};
