import React, {Dispatch, forwardRef, useState} from 'react';
import {Patient} from "../interfaces/Patient";
import {useDispatch} from "react-redux";
import AugmentedFoodService from "../services/AugmentedFoodService";
import {
    Form,
    Header,
    Icon,
    InputOnChangeData,
    List,
    Modal,
    Image, Grid
} from "semantic-ui-react";
import {sendToast} from "../../../store/actions/ApplicationConfigurationActions";
import {SecondaryButton} from "../../../components/Buttons/SecondaryButton";
import {PrimaryButton} from "../../../components/Buttons/PrimaryButton";
import { addPatient } from '../../../store/slices/PatientsSlice';


const Patients = forwardRef((props: { open: boolean, setOpen: any, patients: Patient[] }, ref) => {

    const dispatch: Dispatch<any> = useDispatch();

    const [creatingPatient, setCreatingPatient] = useState<boolean>(false);

    const addToPatients = React.useCallback(
        (patient: Patient) => dispatch(addPatient(patient)),
        [dispatch]
    );

    const [patientSecretCode, setPatientSecretCode] = useState<string>('');


    const createNewPatient = () => {
        setCreatingPatient(true);
        AugmentedFoodService.createPatient(patientSecretCode).then(res => {
            if (res.insertId) {
                addToPatients({id: res.insertId, secret_user_id: patientSecretCode, user_id: ''});
                dispatch(sendToast({
                    title: 'Success',
                    messages: ['Patient created'],
                    color: 'positive'
                }));
            }
        }).catch(err => {
            dispatch(sendToast({
                title: 'Error',
                messages: [err.response.data],
                color: 'negative'
            }));
        }).finally(() => {
            setCreatingPatient(false);
        });
    };


    return (
        <Modal
            onClose={() => props.setOpen(false)}
            open={props.open}
        >
            <Modal.Header>
                <Header.Content style={{display: 'flex', alignItems: 'center'}}>
                    <Image src={'./assets/images/medical-records.svg'} style={{marginRight: '15px'}}
                           size={'tiny'}/> Patients
                </Header.Content>
            </Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <List style={{maxHeight: '250px', overflowY: 'auto'}}>
                                {props.patients.map((patient: Patient) => {
                                    return <List.Item key={patient.id} icon='user' content={patient.secret_user_id}/>;
                                })}
                            </List>
                        </Grid.Column>
                        <Grid.Column>
                            <Header as={'h4'}>Create new patient:</Header>
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Input
                                        fluid
                                        value={patientSecretCode}
                                        onChange={(e, data: InputOnChangeData) => {
                                            if (data.value) {
                                                setPatientSecretCode(data.value);
                                            }
                                        }}
                                        placeholder='Patient secret username'
                                    />
                                </Form.Group>
                            </Form>

                            <SecondaryButton className={creatingPatient ? 'loading' : ''}
                                    disabled={patientSecretCode === '' || creatingPatient}
                                    onClick={() => createNewPatient()}>
                                <Icon name='checkmark'/> Create
                            </SecondaryButton>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>

            <Modal.Actions>
                <PrimaryButton onClick={() => props.setOpen(false)}>
                    CLose
                </PrimaryButton>
            </Modal.Actions>
        </Modal>
    );
});

export default Patients;
