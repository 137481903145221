export const LangDevices = {
    headTitle: {
        it: 'I miei dispositivi | BECOME',
        en: 'My devices | BECOME'
    },
    title: {
        it: 'Dispositivi',
        en: 'Devices'
    },
    add: {
        it: 'Aggiungi dispositivo',
        en: 'Add new device'
    },
    type: {
        it: 'Tipologia',
        en: 'Device type'
    },
    button: {
        it: 'Aggiungi',
        en: 'Add'
    },
    success: {
        it: 'Successo',
        en: 'Success'
    },
    deviceAdded: {
      it: 'Dispositivo aggiunto correttamente',
      en: 'Device successfully added'
    },
    error: {
      it: 'Errore',
      en: 'Error'
    },
    deviceType: {
        it: 'Tipo di dispositivo',
        en: 'Device type'
    },
    deviceID: {
      it: 'ID del dispositivo',
      en: 'Device ID'
    },
    close: {
        it: 'Chiudi',
        en: 'Close'
    },
    Add: {
      it: 'Aggiungi',
      en: 'Add'
    },
};
