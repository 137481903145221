import {createSlice} from '@reduxjs/toolkit';
import StripeService from "../../services/user/StripeService";
import FoodList from "../../applications/food/interfaces/FoodList";
import {PaymentMethod} from "@stripe/stripe-js";


export const initialState = {
    retrievingPaymentMethods: false,
    hasErrors: false,
    paymentMethods: [],
    paymentMethodsFetched: false
};

const paymentMethodsSlice = createSlice({
    name: 'paymentMethods',
    initialState,
    reducers: {
        getPaymentMethods: state => {
            state.retrievingPaymentMethods = true;
        },
        getPaymentMethodsSuccess: (state, {payload}) => {
            state.paymentMethods = payload;
            state.retrievingPaymentMethods = false;
            state.hasErrors = false;
            state.paymentMethodsFetched = true;
        },
        getPaymentMethodsFailure: state => {
            state.retrievingPaymentMethods = false;
            state.hasErrors = true;
        },
        addPaymentMethod: (state, {payload}) => {
            state.paymentMethods = [...state.paymentMethods, payload];
        },
        removePaymentMethod: (state, {payload}) => {
            state.paymentMethods = state.paymentMethods.filter((paymentMethod: PaymentMethod) => paymentMethod.id !== payload.id);
        }
    },
});

export default paymentMethodsSlice.reducer;

export const paymentMethodsSelector = state => state.paymentMethods;

export const {
    getPaymentMethods,
    getPaymentMethodsSuccess,
    getPaymentMethodsFailure,
    addPaymentMethod
} = paymentMethodsSlice.actions;

export const fetchPaymentMethods = (): (dispatch) => Promise<void> => {
    console.log('fetch payment methods');
    return async dispatch => {
        dispatch(getPaymentMethods());
        try {
            await StripeService.getUserPaymentMethods().then(data => {
                dispatch(getPaymentMethodsSuccess(data));
            });
        } catch (error) {
            dispatch(getPaymentMethodsFailure());
        }
    };
};
