import {AxiosInterceptor} from "../../../services/AxiosInterceptor";
import {AxiosInstance, AxiosResponse} from "axios";
import {Patient} from "../interfaces/Patient";
import FoodList from "../interfaces/FoodList";
import {Food} from "../interfaces/Food";
import {Configuration} from "../interfaces/Configuration";
import {FoodResult} from "../interfaces/FoodResult";

export default class AugmentedFoodService{

    static listFood = async (): Promise<Food[]> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/augmented_food/food';
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                }).catch((error) => {
                    return [];
                });
            });
    }

    static listPatients = async (): Promise<Patient[]> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/augmented_food/patients';
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                }).catch((error) => {
                    return [];
                });
            });
    }

    static createPatient = async (secret_user_id: string): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/augmented_food/save_patient';
                return ax.post(url, {secret_user_id: secret_user_id}).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

    static listFoodList = async (): Promise<FoodList[]> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/augmented_food/food_lists';
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                }).catch((error) => {
                    return [];
                });
            });
    }

    static createFoodList = async (name: string): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/augmented_food/create_food_list';
                return ax.post(url, {name: name}).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

    static deleteFoodList = async (id: number): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/augmented_food/food_list/';
                return ax.delete(url + id).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

    static attachFoodToFoodList = async (id: number, food: Food[]): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/augmented_food/attach_food_to_food_list';
                return ax.post(url, {id: id, food: food}).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

    static listConfigurations = async (offset: number, column: string, direction: string): Promise<Configuration[]> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/augmented_food/configurations';
                return ax.post(url, {
                    offset: offset,
                    column: column,
                    direction: direction
                }).then((res: AxiosResponse) => {
                    return res.data;
                }).catch((error) => {
                    return [];
                });
            });
    }

    static createConfiguration = async (
        deviceId: number,
        room: string,
        foodListId: number,
        patientId: number
    ): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/augmented_food/create_configuration';
                return ax.post(url, {
                    device_id: deviceId,
                    room: room,
                    food_list_id: foodListId,
                    patient_id: patientId
                }).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

    static deleteConfiguration = async (id: number): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/augmented_food/configuration/';
                return ax.delete(url + id).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }


    static getConfigurationResults = async (food_list_id: number): Promise<FoodResult[]> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/augmented_food/configuration/results';
                return ax.post(url, {food_list_id: food_list_id}).then((res: AxiosResponse) => {
                    return res.data;
                }).catch((error) => {
                    return [];
                });
            });
    }


}


