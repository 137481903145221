import {createSlice} from '@reduxjs/toolkit';
import AugmentedFoodService from "../../applications/food/services/AugmentedFoodService";
import FoodList from "../../applications/food/interfaces/FoodList";


export const initialState = {
    retrievingFoodLists: false,
    hasErrors: false,
    foodLists: [],
    foodListsFetched: false
};

const foodListsSlice = createSlice({
    name: 'foodLists',
    initialState,
    reducers: {
        getFoodLists: state => {
            state.retrievingFoodLists = true;
        },
        getFoodListsSuccess: (state, {payload}) => {
            state.foodLists = payload;
            state.retrievingFoodLists = false;
            state.hasErrors = false;
            state.foodListsFetched = true;
        },
        getFoodListsFailure: state => {
            state.retrievingFoodLists = false;
            state.hasErrors = true;
        },
        addFoodList: (state, {payload}) => {
            state.foodLists = [...state.foodLists, payload];
        },
        removeFoodList: (state, {payload}) => {
            state.foodLists = state.foodLists.filter((foodList:FoodList) => foodList.id !== payload.id);
        }
    },
});

export default foodListsSlice.reducer;

export const foodListsSelector = state => state.foodLists;

export const {getFoodLists, getFoodListsSuccess, getFoodListsFailure, addFoodList, removeFoodList} = foodListsSlice.actions;

export const fetchFoodLists = (): (dispatch) => Promise<void> => {
    console.log('fetch food lists');
    return async dispatch => {
        dispatch(getFoodLists());
        try {
            await AugmentedFoodService.listFoodList().then(data => {
                dispatch(getFoodListsSuccess(data));
            });
        } catch (error) {
            dispatch(getFoodListsFailure());
        }
    };
};
