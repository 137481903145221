import React, {useEffect, useState} from 'react';
import {Header, Icon, Progress, Segment} from "semantic-ui-react";
import {LangReturnUrl} from "../lang/ReturnUrl";
import {useSelector} from "react-redux";
import {RootState} from "../store";


const ReturnUrl: React.FC = () => {
    const [percent, setPercent] = useState<number>(0);
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);

    useEffect(() => {
        let isSubscribed = true;
        setPercent(100);
        window.document.title = LangReturnUrl.title[lang] +' | BECOME';

        setTimeout(() => {
            const targetOrigin = process.env.REACT_APP_LIVE_MODE === 'true' ? 'https://app.become-hub.com' : process.env.REACT_APP_URL;
            parent.postMessage('3DS-authentication-complete', targetOrigin);
        }, 2000);
        return () => {
            isSubscribed = false;
        };
    }, []);

    return (
        <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}}>
            <Header as={'h1'} icon textAlign='center'>
                <Icon name='check' color={'green'} circular />
                <Header.Content>{LangReturnUrl.title[lang]}</Header.Content>
            </Header>
            <Header as={'h3'} textAlign={'center'}>
                {LangReturnUrl.description[lang]}
            </Header>
            <Progress color={'green'} percent={percent} active/>
        </Segment>
    );
};

export default ReturnUrl;
