import React, {Dispatch, useEffect, useState} from 'react';
import {Dropdown, DropdownProps, Menu, Image} from "semantic-ui-react";
import {toggleVerticalSidebar} from "../store/actions/ApplicationConfigurationActions";
import {useDispatch, useSelector} from "react-redux";
import {Hub} from "@aws-amplify/core";
import {AUTH_STATE_CHANGE_EVENT, AuthState, UI_AUTH_CHANNEL} from "@aws-amplify/ui-components";
import {RootState} from "../store";
import {setCurrentLang} from "../store/actions/applicationActions";
import {useHistory, useLocation} from "react-router-dom";
import {Auth} from "aws-amplify";
import {countryOptions} from "../data/countryOptions";
import styled from "styled-components";


const TopMenu: React.FC<any & { className?: string }> = ({className, children}) => {

    const location = useLocation();
    const history = useHistory();
    const dispatch: Dispatch<any> = useDispatch();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const [isScrolling, setIsScrolling] = useState<boolean>(false);
    const [display, setDisplay] = useState<'flex' | 'none'>('flex');
    let timeout = null;

    const onScroll = () => {
        setIsScrolling(true);

        clearTimeout(timeout);
        if (document.documentElement.scrollTop > 80) {
            setDisplay('none');
        } else {
            setDisplay('flex');
        }

        timeout = setTimeout(() => {
            setIsScrolling(false);
        }, 200);
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    const switchLang = (newLang: string) => {
        Auth.currentAuthenticatedUser().then(res => {
            if (res) {
                Auth.updateUserAttributes(res, {
                    locale: newLang
                });
            }
        });
        setLang(newLang);
    };

    const setLang = React.useCallback(
        (lang: string) => dispatch(setCurrentLang(lang)),
        [dispatch]
    );

    return <Menu pointing secondary fixed={'top'} className={className}>
        <div id="top-left-menu">
            <Menu.Item
                icon={'bars'}
                onClick={() => dispatch(toggleVerticalSidebar())}
            />

            <Menu.Item
                name='dashboard'
                active={location.pathname === '/'}
                onClick={() => history.push('/')}
            />
            <Menu.Item
                name='support'
                active={location.pathname === '/support'}
                onClick={() => history.push('/support')}
            />
        </div>

        <div id={'top-logo'} style={{display: display}}>
            BECOME <Image src={'./assets/icon/logo.png'}/> PLATFORM
        </div>

        <Menu.Menu position='right' id="top-right-menu">
            <Dropdown
                onChange={(e, props: DropdownProps) => switchLang(props.value as string)}
                placeholder='Select Country'
                fluid
                value={lang}
                selection
                options={countryOptions}
            />
            <Menu.Item
                onClick={() => {
                    localStorage.clear();
                    Hub.dispatch(UI_AUTH_CHANNEL, {
                        event: AUTH_STATE_CHANGE_EVENT,
                        message: AuthState.SignedOut,
                        data: null
                    });
                }}
                name='logout'
            />
        </Menu.Menu>
    </Menu>;
};

export const StyledTopMenu = styled(TopMenu)`
  z-index: 2;
  border-bottom: 0 !important;
  height: 80px !important;
  align-items: center;
  background: white!important;

  #top-left-menu {
    display: flex;
    margin-left: 50px;
  }

  #top-right-menu {
    margin-right: 50px;
  }


  #top-logo {
    display: flex;
    position: absolute;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    height: 80px;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-weight: bold;
    font-size: large;

    img {
      height: 60px !important;
      margin-left: 20px;
      margin-right: 20px;
    }
  }


  @media (min-width: 780px) and (max-width: 1750px) {
    min-width: 200px;
    padding-left: 0!important;
    height: 50px!important;
  }

  @media (max-width: 1750px) {
    #top-logo {
      display: none !important;
    }

    #top-left-menu {
      margin-left: 0;
    }

    #top-right-menu {
      margin-right: 0;
    }
  }

`;
