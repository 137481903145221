import React, {FC, useRef} from 'react';
import {Icon, List} from "semantic-ui-react";
import {Food} from "../interfaces/Food";
import {useDrag, useDrop, DropTargetMonitor} from "react-dnd";
import {XYCoord} from 'dnd-core';

export interface CardProps {
    food: Food
    index: number
    toggleElement: any
    moveCard: (dragIndex: number, hoverIndex: number) => void
}

const ItemTypes = {
    CARD: 'card',
};

interface DragItem {
    index: number
    id: string
    type: string
}

export const DraggableListItem: FC<CardProps> = ({food, index, toggleElement, moveCard}) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{handlerId}, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{isDragging}, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return {food, index};
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return <div ref={ref} style={{opacity: opacity, display: 'flex', width: '100%', padding: '5px'}}>
        <Icon name='grab'/>
        <div style={{flex: 1, marginLeft: '5px'}}>{food.name}</div>
        <Icon className={'scalable'} name='trash' onClick={() => toggleElement(food)}/>
    </div>;
};
