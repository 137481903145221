import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import {
    Segment
} from "semantic-ui-react";
import HubspotForm from 'react-hubspot-form';
import {Helmet} from "react-helmet";
import {Auth} from "aws-amplify";

const Support: React.FC = () => {

    return (
        <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">

            <Helmet>
                <title>BECOME | SUPPORT</title>
            </Helmet>

            <HubspotForm
                portalId='19855872'
                formId='8a28d5d4-4e21-49ca-a77e-f77ae7fff169'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => {
                    Auth.currentAuthenticatedUser().then(res => {
                        if (res) {
                            const loop = () => {
                                setTimeout(() => {
                                    console.log('let\'s try ', form.contentWindow.document.querySelectorAll('input[name="email"]'));
                                    if (form.contentWindow.document.querySelectorAll('input[name="email"]').length === 0) {
                                        loop();
                                    } else {
                                        form.contentWindow.document.querySelectorAll('input[name="email"]')[0].value = res.attributes.email;
                                    }
                                }, 200);
                            };
                            loop();
                        }
                    });
                }}
                loading={<div>Loading...</div>}
            />
        </Segment>
    );
};

export default Support;
