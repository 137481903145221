import styled from "styled-components";
import React from "react";
import {CardGroup} from "semantic-ui-react";


export const StyledResponsiveCardGroup = styled(CardGroup)`

  @media (max-width: 1080px) {
    .card {
      width: 100% !important;
    }
  }

`;
