import {Course} from "../../interfaces/Course";
import {UserCourse} from "../../interfaces/UserCourse";


export const SET_COURSES = 'SET_COURSES';
export const SET_LANG = 'SET_LANG';
export const UPDATE_APPLICATION_USER_APPLICATIONS = 'UPDATE_APPLICATION_USER_APPLICATIONS';

export interface ApplicationState {
    courses: Course[],
    userCourses: UserCourse[],
    lang: string
}

interface SetCourses {
    type: typeof SET_COURSES,
    payload: Course[]
}


interface SetLang {
    type: typeof SET_LANG,
    payload: string
}

export type ApplicationActionTypes =
      SetCourses
    | SetLang;
