import React, {Dispatch, useEffect, useState} from 'react';
import {Route, useLocation, Switch} from 'react-router-dom';
import awsmobile from "./aws-exports";
import Auth from 'aws-amplify';
import Amplify, {Hub} from "@aws-amplify/core";
import {AmplifyAuthenticator} from '@aws-amplify/ui-react';
import {AUTH_STATE_CHANGE_EVENT, AuthState, onAuthUIStateChange, UI_AUTH_CHANNEL} from '@aws-amplify/ui-components';
import {CognitoUserInterface} from '@aws-amplify/ui-components';
import {Container} from "semantic-ui-react";

import SignUp from './auth/slots/SignUp';
import SignIn from './auth/slots/SignIn';
import Applications from './pages/applications/Applications';
import Certificates from './pages/certificates/Certificates';
import Dashboard from './pages/user/Dashboard';
import Courses from './pages/courses/Courses';
import BillingPage from './pages/BillingPage';
import DevicesPage from "./pages/user/DevicesPage";
import BuyCourse from "./pages/courses/BuyCourse";
import ReturnUrl from './pages/ReturnUrl';
import CoursePage from './pages/courses/CoursePage';
import ApplicationPage from './pages/applications/ApplicationPage';
import SubscribeToApplication from './pages/applications/SubscribeToApplication';
import Support from './pages/Support';
import Security from "./pages/security/Security";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./store";
import {setCurrentLang} from "./store/actions/applicationActions";
import EditFoodLists from "./applications/food/pages/EditFoodLists";
import ConfirmSignIn from "./auth/slots/ConfirmSignIn";
import CertificatePage from "./pages/certificates/CertificatePage";
import ConfirmSignUp from "./auth/slots/ConfirmSignUp";
import {StyledSideBar} from "./partials/Sidebar";
import Settings from "./pages/Settings";
import {toggleVerticalSidebar} from "./store/actions/ApplicationConfigurationActions";
import MyVideos from "./pages/user/MyVideos";
import ForgotPassword from "./auth/slots/ForgotPassword";
import OnBoarding from "./pages/OnBoarding";
import {StyledTopMenu} from "./partials/TopMenu";
import Subscriptions from "./pages/user/Subscriptions";
import {
    TransitionGroup,
    CSSTransition
} from "react-transition-group";

Amplify.configure(awsmobile);
Auth.configure({
    identityPoolId: 'eu-west-1:534644df-09db-400e-8fb3-ec2fc7f26b94',
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_5UmfDqYEh',
    userPoolWebClientId: '3obd71km8r3tm2rgiveflp05uu'
});

const App: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const [authState, setAuthState] = React.useState<AuthState>();
    const [user, setUser] = React.useState<CognitoUserInterface | undefined>();
    const location = useLocation();
    const hideTopMenu: string[] = ['/return_url'];
    const visible: boolean = useSelector((state: RootState) => state.application.displayVerticalSidebar);
    const [oldPath, setOldPath] = useState<string>('');

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState: AuthState, authData: CognitoUserInterface | undefined) => {
            setAuthState(nextAuthState);
            if (authData) {
                setUser(authData);
                if (authData.attributes && authData.attributes.locale) {
                    console.log('hello, ', authData.attributes.locale);
                    dispatch(setCurrentLang(authData.attributes.locale ?? 'en'));
                }
            }
        });
    }, []);

    useEffect(() => {
        if (oldPath !== location.pathname) {
            console.log('setting path ', location.pathname);
            setOldPath(location.pathname);
        }
    }, [location]);

    const closeSideBarIfMobile = () => {
        if (window.innerWidth <= 780) {
            if (document.getElementsByClassName('sidebarVisible').length > 0) {
                dispatch(toggleVerticalSidebar());
            }
        }
    };
    const getPathDepth = (): number => {
        let pathArr = location.pathname.split("/");
        pathArr = pathArr.filter(n => n !== "");
        return pathArr.length;
    };

    const getPrevDepth = (): number => {
        let pathArr = oldPath.split("/");
        pathArr = pathArr.filter(n => n !== "");
        return pathArr.length;
    };

    return authState === AuthState.SignedIn && user ? (
        <div>
            {hideTopMenu.indexOf(location.pathname) >= 0 ? <></> : <StyledTopMenu/>}
            <StyledSideBar/>
            <OnBoarding/>
            <div style={{minHeight: '90vh', paddingTop: '40px'}}
                 className={visible ? 'sidebarVisible' : 'sidebarHidden'}>
                <Container className={'minWidthContainer'} onClick={() => closeSideBarIfMobile()}>

                    <TransitionGroup>
                        <CSSTransition
                            key={location.key}
                            classNames="pageSlider"
                            timeout={{enter: 800, exit: 400}}
                        >
                            <div className={getPathDepth() - getPrevDepth() >= 0 ? "left" : "right"}>
                                <Switch>
                                    <Route path="/applications" component={Applications} exact/>
                                    <Route path="/apps/food/food_lists/:listId" component={EditFoodLists} exact/>
                                    <Route path="/apps/:applicationSlug" component={ApplicationPage} exact/>
                                    <Route path="/apps/:applicationSlug/checkout" component={SubscribeToApplication}
                                           exact/>


                                    <Route path="/courses" component={Courses} exact/>
                                    <Route path="/course/:courseId/checkout" component={BuyCourse} exact/>
                                    <Route path="/course/:courseId" component={CoursePage} exact/>

                                    <Route path="/certificates" component={Certificates} exact/>
                                    <Route path="/certificates/:courseId" component={CertificatePage} exact/>

                                    <Route path="/devices" component={DevicesPage} exact/>

                                    <Route path="/billing" component={BillingPage} exact/>
                                    <Route path="/subscriptions" component={Subscriptions} exact/>

                                    <Route path="/settings" component={Settings} exact/>
                                    <Route path="/return_url" component={ReturnUrl} exact/>

                                    <Route path="/support" component={Support} exact/>

                                    <Route path="/security" component={Security} exact/>
                                    <Route path="/videos" component={MyVideos} exact/>

                                    <Route path="/" component={Dashboard} exact/>

                                </Switch>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>

                </Container>
            </div>
        </div>
    ) : (
        <Switch>
            <Route path="/">
                {location && location.pathname !== '/privacy' ?
                    <AmplifyAuthenticator>
                        <div slot='sign-up'><SignUp/></div>
                        <div slot='sign-in' style={{width: '100%'}}>
                            <SignIn/>
                        </div>
                        <div slot='confirm-sign-in'><ConfirmSignIn/></div>
                        <div slot='confirm-sign-up'><ConfirmSignUp/></div>
                        <div slot='forgot-password'><ForgotPassword/></div>
                    </AmplifyAuthenticator> : <></>
                }
            </Route>
        </Switch>
    );
};

export default App;
