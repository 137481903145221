export const LangProfile = {

    documentTitle: {
        it: 'Impostazioni | BECOME',
        en: 'Settings | BECOME'
    },
    title: {
        it: 'Impostazioni account',
        en: 'Account settings'
    },
    menu: {
        profile: {
            it: 'Profilo',
            en: 'Profile'
        },
        users: {
            it: 'Utenti',
            en: 'Users'
        },
        company: {
            it: 'Azienda',
            en: 'Company'
        },
        notifications: {
            it: 'Notifiche',
            en: 'Notifications'
        },
        integrations: {
            it: 'Integrazioni',
            en: 'Integrations'
        }
    },
    avatar: {
            it: 'Foto profilo',
        en: 'Avatar'
        },
    avatarSubtitle: {
        it: 'Puoi personalizzare la tua immagine del profilo caricando una foto dal tuo PC.',
        en: 'You can customize your profile picture by uploading an image from your PC.'
    },
    saveButton: {
        it: 'Salva',
        en: 'Save'
    },
    success: {
      it: 'Successo',
      en: 'Success'
    },
    error: {
      it: 'Errore',
      en: 'Error'
    },
    personalDetails: {
        miao: {
            it: 'Informazioni personali',
            en: 'Personal details'
        },
        nickname: {
          it: 'Nickname',
          en: 'Nickname'
        },
        fullName: {
            it: 'Nome completo',
            en: 'Full name'
        },
        gender: {
          it: 'Genere',
          en: 'Gender'
        },
        agender: {
          it: 'Senza genere',
          en: 'Agender'
        },
        bigender: {
          it: 'Bigenere',
          en: 'Bigender'
        },
        genderfluid: {
            it: 'Genere fluido',
            en: 'Gender fluid'
        },
        nonbinary: {
          it: 'Genere non binario',
          en: 'Non binary'
        },
        female: {
            it: 'Donna',
            en: 'Woman'
        },
        male: {
            it: 'Uomo',
            en: 'Man'
        },
        other: {
            it: 'Altro',
            en: 'Other'
        },
        birthdate: {
          it: 'Data di nascita',
          en: 'Birthdate'
        },
        locale: {
          it: 'Lingua',
          en: 'Locale'
        },
        italian: {
            it: 'Italiano',
            en: 'Italian'
        },
        english: {
          it: 'Inglese',
          en: 'English'
        },
        addressBook: {
            it: 'DETTAGLI INDIRIZZO',
            en: 'ADDRESS BOOK'
        },
        communication: {
            it: 'PREFERENZE DI COMUNICAZIONE',
            en: 'COMMUNICATION PREFERENCES'
        },
        name: {
            it: 'Nome',
            en: 'Name'
        },
        surname: {
            it: 'Cognome',
            en: 'Surname'
        },
        fiscalCode: {
            it: 'Codice Fiscale',
            en: 'Fiscal code'
        },
        city: {
            it: 'Città',
            en: 'City'
        },
        address: {
            it: 'Via / Numero',
            en: 'Address'
        },
        province: {
            it: 'Provincia',
            en: 'Province'
        },
        country: {
            it: 'Paese',
            en: 'Country'
        },
        newletter: {
            it: 'Vuoi ricevere la newsletter?',
            en: 'Inscribe to newsletter?'
        }
    },
    professionalDetails: {
        miaomiao: {
            it: 'Informazioni professionali',
            en: 'Professional details'
        },
        enterSDI: {
            it: 'Inserisci SDI',
            en: 'Enter SDI'
        },
        VAT: {
          it: 'Partita IVA',
          en: 'VAT'
        },
        enterVAT:{
            it: 'Inserisci Partita IVA',
            en: 'Enter VAT'
        },
        enterPEC:{
            it: 'Inserisci PEC',
            en: 'Enter PEC'
        },
        profession:{
          it: 'Professione',
          en: 'Profession'
        },
        enterProfession: {
          it: 'Inserisci Professione',
          en: 'Enter Profession'
        },
        professionID: {
          it: 'Numero d\'iscrizione all\'Ordine',
            en: 'Profession ID'
        },
        enterProfessionID: {
          it: `Inserisci numero d'iscrizione all'ordine`,
          en: 'Enter Profession ID'
        },
        name: {
            it: 'Nome azienda',
            en: 'Company Name'
        },
        addressBook: {
            it: 'DETTAGLI INDIRIZZO',
            en: 'ADDRESS BOOK'
        },
        city: {
            it: 'Città',
            en: 'City'
        },
        address: {
            it: 'Indirizzo',
            en: 'Address'
        },
        province: {
            it: 'Provincia',
            en: 'Province'
        },
        country: {
            it: 'Paese',
            en: 'Country'
        },
        save: {
            it: 'Salva',
            en: 'Save'
        }
    },
    studentDetails:{
        school: {
            en: 'School name',
            it: 'Nome della scuola'
        }
    },
    companyDetails:{
        name:{
            en: 'Name',
            it: 'Nome'
        },
        address:{
            en: 'Address',
            it: 'Indirizzo'
        },
        vatNumber:{
            en: 'Vat number',
            it: 'Partita IVA'
        }
    }
};
