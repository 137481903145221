import React, {useEffect, useState} from "react";
import {Storage} from 'aws-amplify';
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {Dimmer, Header, Icon, Item, Loader, Segment} from "semantic-ui-react";
import {LangDevices} from "../../lang/Devices";
import {LangMyVideos} from "../../lang/MyVideos";

const MyVideos: React.FC = () => {
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const [retrievingFiles, setRetrievingFiles] = useState<boolean>(true);
    const [files, setFiles] = useState<any[]>([]);
    const [downloading, setDownloading] = useState<{ key: string, value: boolean }[]>([]);

    useEffect(() => {
        console.log('retrieve files');
        Storage.list('', {level: 'private'}).then(res => {
            setRetrievingFiles(false);
            console.log('files', res);
            setFiles(res);
        });
    }, []);

    const getLink = (key: string) => {
        setDownloading([...downloading, {key: key, value: true}]);
        Storage.get(key, { download: false, level: 'private' }).then((res) => {
            setDownloading([...downloading, {key: key, value: false}]);
            window.open(res as string, '_blank');
        });
    };

    return <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
        <Dimmer inverted className={retrievingFiles ? 'active' : ''}>
            <Loader inverted>Loading</Loader>
        </Dimmer>
        <Header as='h1'>{LangMyVideos.title[lang].toUpperCase()}</Header>
        <Item.Group>
            {files.filter((file: any) => file.key.includes('.mp4') > 0).map((file: any, index: number) => {
                return <Item key={'video-' + index}>
                    <Icon name={downloading.find((k: {key:string, value: boolean}) =>  k.key === file.key && k.value) ? 'spinner' : 'video'}/>
                    <Item.Content verticalAlign='middle'>
                        <Item.Header as='a' onClick={() => getLink(file.key)}>{file.key}</Item.Header>
                    </Item.Content>
                </Item>;
            })}
        </Item.Group>
    </Segment>;
};
export default MyVideos;
