import React, {Dispatch, useEffect, useState} from "react";
import {Table, Segment, Image, Dimmer, Loader, Icon, Header} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {applicationsSelector, fetchApplications} from "../../store/slices/ApplicationSlice";
import {Subscription} from "../../interfaces/Subscription";
import StripeService from "../../services/user/StripeService";
import {RootState} from "../../store";
import {Application} from "../../interfaces/Application";
import {LangDashboard} from "../../lang/Dashboard";
import {monthNames} from "../../data/monthNames";
import {DangerButton} from "../../components/Buttons/DangerButton";
import {LangSubscriptions} from "../../lang/Subscriptions";


const Subscriptions: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const {applications, retrievingApplications, applicationsFetched} = useSelector(applicationsSelector);
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [retrievingSubscriptions, setRetrievingSubscriptions] = useState<boolean>(true);

    const [deletingSubscription, setDeletingSubscription] = useState<string>('');


    useEffect(() => {
        let isSubscribed = true;

        StripeService.listUserSubscriptions().then((res: Subscription[]) => {
            if (isSubscribed) {
                setSubscriptions(res);
                setRetrievingSubscriptions(false);
            }
        });

        window.document.title = 'Subscriptions | BECOME';
        return () => {
            isSubscribed = false;
        };
    }, []);

    useEffect(() => {
        if (!applicationsFetched.includes(lang)) {
            dispatch(fetchApplications(lang));
        }
    }, [lang]);

    useEffect(() => {
        console.log(applications[lang]);
    }, [applications]);



    return <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
        <Header as={'h1'}>{LangSubscriptions.header[lang].toUpperCase()}</Header>

        <Table striped textAlign={'center'}>
            <Dimmer inverted active={retrievingSubscriptions}>
                <Loader/>
            </Dimmer>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan={2}>
                        {LangSubscriptions.application[lang].toUpperCase()}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        {LangSubscriptions.status[lang].toUpperCase()}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        {LangSubscriptions.priceY[lang].toUpperCase()}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        {LangSubscriptions.expiration[lang].toUpperCase()}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        {LangSubscriptions.quantity[lang].toUpperCase()}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        {LangSubscriptions.actions[lang].toUpperCase()}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {applications && applications[lang] && subscriptions.map((subscription: Subscription) => {
                    let cost = 0;
                    const app = applications[lang].find((app: Application) => {
                        for (let k = 0; k < app.prices.length; k++) {
                            if (app.prices[k].price_id === subscription.plan.id) {
                                cost = app.prices[k].cost;
                                return true;
                            }
                        }
                        return false;
                    });
                    const createdAt = new Date(subscription.created * 1000);
                    console.log('prices', app.prices, 'plan id', subscription.plan.id);
                    const localCost = cost / 100 * subscription.quantity;

                    return <Table.Row key={'subscription-' + subscription.id}>
                        <Table.Cell>
                            <Image alt={app?.name + ' logo'} src={app?.icon} size='mini'
                                   style={{opacity: subscription.status === 'active' ? '1' : '0.5'}}/>
                        </Table.Cell>
                        <Table.Cell>
                            {app?.name}
                        </Table.Cell>
                        <Table.Cell
                            style={{
                                color: subscription.cancel_at_period_end ? '#CE0000' : '#0A9D00',
                                fontWeight: 'bold'
                            }}>
                            <Icon name={subscription.cancel_at_period_end ? 'remove circle' : 'check circle'}/>
                            {subscription.cancel_at_period_end ? 'Suspended'.toUpperCase() : subscription.status.toUpperCase()}
                        </Table.Cell>
                        <Table.Cell textAlign={'right'} style={{fontWeight: 'bold', color: '#787878'}}>
                            {localCost} €
                        </Table.Cell>
                        <Table.Cell>{LangDashboard.expiration[lang]} {monthNames[createdAt.getMonth()]} {createdAt.getDate()}, {createdAt.getFullYear()}</Table.Cell>
                        <Table.Cell style={{fontWeight: 'bold'}}>{subscription.quantity}</Table.Cell>
                        <Table.Cell>
                            {!subscription.cancel_at_period_end &&
                            <DangerButton size={'mini'}
                                          onClick={() => {
                                              setDeletingSubscription(subscription.id);
                                              StripeService.cancelSubscriptionService(subscription.id).then(() => {
                                                  const oldSubscriptions = [...subscriptions];
                                                  const updatedSubscription = oldSubscriptions.find(s => s.id === subscription.id);
                                                  updatedSubscription.cancel_at_period_end = true;
                                                  setSubscriptions(oldSubscriptions);
                                              }).finally(() => setDeletingSubscription(''));
                                          }}
                                          disabled={deletingSubscription.includes(subscription.id)}
                                          className={deletingSubscription.includes(subscription.id) ? 'loading' : ''}>
                                {LangSubscriptions.cancel[lang]}
                            </DangerButton>
                            }
                        </Table.Cell>
                    </Table.Row>;
                })}
            </Table.Body>
        </Table>
    </Segment>;

};

export default Subscriptions;
