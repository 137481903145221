import {createSlice} from '@reduxjs/toolkit';
import AugmentedFoodService from "../../applications/food/services/AugmentedFoodService";
import {Configuration} from "../../applications/food/interfaces/Configuration";


export const initialState = {
    retrievingConfigurations: false,
    hasErrors: false,
    configurations: [],
    configurationsFetched: false
};

const configurationsSlice = createSlice({
    name: 'configurations',
    initialState,
    reducers: {
        getConfigurations: state => {
            state.retrievingConfigurations = true;
        },
        getConfigurationsSuccess: (state, {payload}) => {
            state.configurations = payload;
            state.retrievingConfigurations = false;
            state.hasErrors = false;
            state.configurationsFetched = true;
        },
        getConfigurationsFailure: state => {
            state.retrievingConfigurations = false;
            state.hasErrors = true;
        },
        addConfiguration: (state, {payload}) => {
            state.configurations = [...state.configurations, payload];
        },
        removeConfiguration: (state, {payload}) => {
            state.configurations = state.configurations.filter((configuration: Configuration) => configuration.id !== payload.id);
        }
    },
});

export default configurationsSlice.reducer;

export const configurationsSelector = state => state.configurations;

export const {getConfigurations, getConfigurationsSuccess, getConfigurationsFailure, addConfiguration, removeConfiguration} = configurationsSlice.actions;

export const fetchConfigurations = (offset: number, column: string, direction: string): (dispatch) => Promise<void> => {
    console.log('fetch configurations');
    return async dispatch => {
        dispatch(getConfigurations());
        try {
            await AugmentedFoodService.listConfigurations(offset, column, direction).then(data => {
                dispatch(getConfigurationsSuccess(data));
            });
        } catch (error) {
            dispatch(getConfigurationsFailure());
        }
    };
};
