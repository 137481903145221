import React, {Dispatch, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {useParams} from "react-router";
import {Link, useHistory} from "react-router-dom";
import {Breadcrumb, Dimmer, Header, Loader, Segment} from "semantic-ui-react";
import {Application} from "../../interfaces/Application";
import {UserApplication} from "../../interfaces/UserApplication";
import {SubscriptionStatus} from "../../enums/SubscriptionStatus";
import AppFoodDashboard from "../../applications/food/pages/AppFoodDashboard";
import {applicationsSelector, fetchApplications} from "../../store/slices/ApplicationSlice";
import {fetchUserApplications, userApplicationsSelector} from "../../store/slices/UserApplicationSlice";
import ApplicationVideoWrapper from "../../applications/video/ApplicationVideoWrapper";
import {ApplicationMiddleware} from "../../middleware/Application";
import {fetchUserCourses, userCoursesSelector} from "../../store/slices/UserCourseSlice";


const ApplicationPage: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const {applicationSlug} = useParams<{ applicationSlug: string; }>();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const [application, setApplication] = useState<Application>();
    const history = useHistory();
    const {applications, retrievingApplications, applicationsFetched} = useSelector(applicationsSelector);
    const {userCourses, retrievingUserCourses, userCoursesFetched} = useSelector(userCoursesSelector);
    const {
        userApplications,
        retrievingUserApplications,
        userApplicationsFetched
    } = useSelector(userApplicationsSelector);

    useEffect(() => {
        if (userApplications.length > 0 && application && userCourses && userCourses[lang] && userCourses[lang].length > 0) {
            console.log('calling application middleware');
            ApplicationMiddleware(history, userApplications, application);
        }
        // TODO EULA


    }, [userApplications, application, userCourses]);


    useEffect(() => {
        if (!userApplicationsFetched) {
            dispatch(fetchUserApplications());
        }
    }, []);

    useEffect(() => {
        if (!applicationsFetched.includes(lang)) {
            dispatch(fetchApplications(lang));
        }
        if (!userCoursesFetched.includes(lang)) {
            console.log('calling fetchUserCourses');
            dispatch(fetchUserCourses(lang));
        }
    }, [lang]);

    useEffect(() => {
        if (applications[lang] && applications[lang].length > 0) {
            console.log('use effect applications');
            const findApplication = applications[lang].find((a: Application) => a.slug === applicationSlug);
            if (!findApplication) {
                history.push('/applications');
            }
            window.document.title = findApplication.name + ' | BECOME';
            setApplication(findApplication);
        }
    }, [applications]);


    useEffect(() => {
        if (userApplications.length > 0 && application) {

            const userHasApplications = userApplications.filter((ua: UserApplication) => ua.application_id === application.id);
            let userHasApplication = userHasApplications.length > 0 ? userHasApplications[0] : null;
            for (let k = 0; k < userHasApplications.length; k++) {
                if (userHasApplications[k].subscription_status === SubscriptionStatus.active) {
                    userHasApplication = userHasApplications[k];
                }
            }

            if (userHasApplication && userHasApplication.subscription_status) {
                switch (userHasApplication.subscription_status) {
                    case SubscriptionStatus.inactive:
                    case SubscriptionStatus.canceled:
                        history.push('/apps/' + application.slug + '/checkout');
                        break;
                    case SubscriptionStatus.paused:
                        // todo manage
                        console.log('manage status paused');
                        break;
                }
            } else {
                history.push('/applications');
            }
        }
    }, [userApplications, application]);

    const appPage = () => {
        switch (application?.slug) {
            case 'food':
                return <AppFoodDashboard application={application}/>;
            case 'relax':
                //return <ApplicationVideoPage application={application}/>;
                return <ApplicationVideoWrapper application={application}/>;
            case 'clinic':
                return <ApplicationVideoWrapper application={application}/>;
            default:
                return <div>Application page not found</div>;

        }
    };

    return (
        <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
            <Breadcrumb>
                <Breadcrumb.Section as={Link} to='/applications'>Applications</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section active>{application?.name}</Breadcrumb.Section>
            </Breadcrumb>
            <Dimmer inverted className={retrievingUserApplications || retrievingApplications ? 'active' : ''}
                    inline='centered'>
                <Loader content='Loading'/>
            </Dimmer>
            <Header as={'h1'}>
                {application?.name}
            </Header>
            <div>
                {appPage()}
            </div>

        </Segment>
    );
};

export default ApplicationPage;


