export const OnBoardingLang = {

    header: {
        en: 'Welcome aboard',
        it: 'Benvenuto'
    },
    subHeader: {
        en: 'Before start we need a few steps to configure your application.',
        it: 'Prima di cominciare sono necessari alcuni passaggi per finire di configurare l\'applicazione.'
    },
    label1: {
        en: 'Who are you?',
        it: 'Chi sei?'
    },
    label2: {
        en: 'Start using application',
        it: 'Fai partire l\'applicazione'
    },
    types: {
        student: {
            en: 'Student',
            it: 'Studente'
        },
        individual: {
            en: 'Individual',
            it: 'Professionista'
        },
        school: {
            en: 'School',
            it: 'Scuola'
        },
        company: {
            en: 'Company / Research etc.',
            it: 'Azienda / Ente / Ricerca'
        }
    },
    next: {
        en: 'Next',
        it: 'Avanti'
    },
    back: {
        en: 'Back',
        it: 'Indietro'
    },
    complete: {
        en: 'Click to start',
        it: 'Clicca per iniziare'
    },
    formError1: {
        en: 'Once choice is required',
        it: 'Scegli un opzione'
    },

    steps: {
        student: {
            header: {
                en: 'Student',
                it: 'Studente'
            }

        },
        individual: {
            header: {
                en: 'Individual',
                it: 'Professionista'
            }
        },
        school: {
            header: {
                en: 'School',
                it: 'Scuola'
            }
        },
        company: {
            header: {
                en: 'Company / Research etc.',
                it: 'Azienda / Ente / Ricerca'
            }
        }

    }

};
