import React, {Dispatch, forwardRef, useState} from 'react';
import FoodList from "../interfaces/FoodList";
import {useDispatch} from "react-redux";
import AugmentedFoodService from "../services/AugmentedFoodService";
import {sendToast} from "../../../store/actions/ApplicationConfigurationActions";
import {
    Button,
    Form, Grid,
    Header,
    Icon, Image,
    InputOnChangeData,
    List,
    Modal,
} from "semantic-ui-react";
import {useHistory} from "react-router-dom";
import {SecondaryButton} from "../../../components/Buttons/SecondaryButton";
import {DangerButton} from "../../../components/Buttons/DangerButton";
import {PrimaryButton} from "../../../components/Buttons/PrimaryButton";


const FoodLists = forwardRef((props: { open: boolean, setOpen: any, foodLists: FoodList[] }, ref) => {

    const dispatch: Dispatch<any> = useDispatch();
    const history = useHistory();

    const addToFoodLists = React.useCallback(
        (foodList: FoodList) => dispatch(addFoodListAction(foodList)),
        [dispatch]
    );

    const [foodListName, setFoodListName] = useState<string>('');

    const [creatingFoodList, setCreatingFoodList] = useState<boolean>(false);
    const [deletingFoodList, setDeletingFoodList] = useState<number>(0);


    const createNewFoodList = () => {
        setCreatingFoodList(true);
        AugmentedFoodService.createFoodList(foodListName).then(res => {
            if (res.insertId) {
                addToFoodLists({id: res.insertId, name: foodListName, user_id: '', food: []});
                history.push('/apps/food/food_lists/' + res.insertId);
            }
        }).catch(err => {
            dispatch(sendToast({
                title: 'Error',
                messages: [err.response.data],
                color: 'negative'
            }));
        }).finally(() => {
            setCreatingFoodList(false);
        });
    };

    const deleteFoodList = (id: number) => {
        const foodList = props.foodLists.find((foodList: FoodList) => foodList.id === id);
        if (foodList) {
            setDeletingFoodList(id);
            AugmentedFoodService.deleteFoodList(id).then(res => {
                dispatch(removeFoodListAction(foodList));
            }).catch(err => {
                dispatch(sendToast({
                    title: 'Error',
                    messages: [err.response.data],
                    color: 'negative'
                }));
            }).finally(() => {
                setDeletingFoodList(0);
            });
        }
    };

    return <Modal
        onClose={() => props.setOpen(false)}
        open={props.open}
    >
        <Modal.Header>
            <Header.Content style={{display: 'flex', alignItems: 'center'}}>
                <Image src={'./assets/images/shopping-cart.svg'} style={{marginRight: '15px'}}
                       size={'tiny'}/>Food lists
            </Header.Content>
        </Modal.Header>
        <Modal.Content>
            <Grid>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={10}>

                        <List style={{maxHeight: '250px', overflowY: 'auto'}} divided>
                            {props.foodLists.map((foodList: FoodList) => {
                                return <List.Item key={foodList.id}>
                                    <List.Content style={{display: 'flex', alignItems: 'center'}}>
                                        <List.Icon circular name={'food'}/>
                                        <div style={{flex: 1, marginLeft: '5px'}}>
                                            <List.Header>
                                                {foodList.name}
                                            </List.Header>
                                            <List.Description>
                                                Total food: {foodList.food.length}
                                            </List.Description>
                                        </div>
                                        <Button.Group size={'mini'}>
                                            <SecondaryButton
                                                size={'small'} style={{marginRight: '5px'}}
                                                onClick={() => history.push('/apps/food/food_lists/' + foodList.id)}>Edit</SecondaryButton>
                                            <DangerButton
                                                size={'small'}
                                                className={deletingFoodList === foodList.id ? 'loading' : ''}
                                                disabled={deletingFoodList === foodList.id}
                                                onClick={() => {
                                                    deleteFoodList(foodList.id);
                                                }}>Delete</DangerButton>
                                        </Button.Group>
                                    </List.Content>
                                </List.Item>;
                            })}
                        </List>
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={16} computer={6}>
                        <Header as={'h4'}>Create new food list:</Header>

                        <Form>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    fluid
                                    value={foodListName}
                                    onChange={(e, data: InputOnChangeData) => {
                                        setFoodListName(data.value);
                                    }}
                                    placeholder='Food list name'
                                />
                            </Form.Group>
                        </Form>


                        <SecondaryButton className={creatingFoodList ? 'loading' : ''}
                                         disabled={foodListName === '' || creatingFoodList}
                                         onClick={() => createNewFoodList()}>
                            <Icon name='checkmark'/> Create food list
                        </SecondaryButton>


                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Modal.Content>

        <Modal.Actions>
            <PrimaryButton onClick={() => props.setOpen(false)}>
                CLose
            </PrimaryButton>
        </Modal.Actions>
    </Modal>;
});

export default FoodLists;
function addFoodListAction(foodList: FoodList): any {
    throw new Error('Function not implemented.');
}

function removeFoodListAction(foodList: FoodList): any {
    throw new Error('Function not implemented.');
}

