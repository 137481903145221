export const LangConfirmSignUp = {
    header: {
        en: 'Confirm Sign Up',
        it: 'Conferma registrazione',
    },
    subHeader: {
        en: 'We just sent you an email with the verification code.',
        it: 'Ti abbiamo appena inviato via email il codice di conferma.',
    },
    button: {
        en: 'Confirm',
        it: 'Conferma'
    },
    backSignIn: {
        en: 'Go back to sign in',
        it: 'Torna alla login'
    },
    sendCodeAgain: {
        en: 'Send code again',
        it: 'Reinvia il codice'
    },
    emailLabel: {
        en: 'Enter your email',
        it: 'Inserisci il tuo indirizzo email'
    },
    confirmationCodeLabel: {
        en: 'Enter your confirmation code',
        it: 'Inserisci il codice che hai ricevuto'
    },
    confirmationCode: {
        en: 'Confirmation code',
        it: 'Codice di conferma'
    },
    emptyEmail:{
        en: 'Insert a valid email',
        it: 'Campo email obbligatorio'
    },
    emptyCode:{
        en: 'Insert a valid code',
        it: 'Inserisci un codice valido'
    },
    emailSent:{
        en: 'Email sent',
        it: 'Email inviata'
    }
};
