export const LangForgotPassword = {

    header: {
        en: 'Reset your password',
        it: 'Resetta la tua password'
    },
    signIn: {
        en: 'Back to sign in',
        it: 'Torna alla login'
    },
    email: {
        en: 'Enter your email address',
        it: 'Inserisci il tuo indirizzo email'
    },
    hint: {
        en: 'A code will be sent by email. Use it to proceed',
        it: 'Ti sarà inviata una mail con un codice per procedere al reset.'
    },
    done: {
        en: 'Email has been successfully sent',
        it: 'Email inviata con successo'
    },
    sendBtn: {
        en: 'Send email',
        it: 'Invia email'
    },
    codeReceived: {
        en: 'I received the code',
        it: 'Ho ricevuto il codice'
    },
    back: {
        en: 'Back',
        it: 'Indietro'
    },
    code: {
        en: 'Verification code',
        it: 'Inserisci il codice di verifica'
    },
    newPassword: {
        en: 'New password',
        it: 'Inserisci la tua nuova password'
    },
    codeIsRequired:{
        en: 'The code is required',
        it: 'Inserire il codice'
    },
    completed: {
        en: 'Password changed successfully',
        it: 'Password aggiornata con successo'
    }

};
