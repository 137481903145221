import {createSlice} from '@reduxjs/toolkit';
import {UserCourse} from "../../interfaces/UserCourse";
import CourseService from "../../services/courses/CourseService";

export const initialState = {
    retrievingUserCourses: false,
    hasErrors: false,
    userCourses: {},
    userCoursesFetched: []
};

// A slice for recipes with our 3 reducers
const userCoursesSlice = createSlice({
    name: 'userCourses',
    initialState,
    reducers: {
        getUserCourses: state => {
            state.retrievingUserCourses = true;
        },
        getUserCoursesSuccess: (state, {payload}) => {
            const lang = payload.lang;
            const oldUserCourses = {...state.userCourses};
            oldUserCourses[lang] = payload.userCourses;

            state.userCourses = oldUserCourses;
            state.retrievingUserCourses = false;
            state.hasErrors = false;
            state.userCoursesFetched = [...state.userCoursesFetched, payload.lang];
        },
        getUserCoursesFailure: state => {
            state.retrievingUserCourses = false;
            state.hasErrors = true;
        },
        updateUserCourse: (state, {payload}) => {
            state.userCourses = state.userCourses[payload.lang].map((uc: UserCourse, index: number) => {
                if (uc.id !== payload.id) {
                    return uc;
                }
                return {
                    ...uc,
                    ...payload
                };
            });
        }
    },
});

// The reducer
export default userCoursesSlice.reducer;

// Selectors
export const userCoursesSelector = state => state.userCourses;
// Actions
export const {getUserCourses, getUserCoursesSuccess, getUserCoursesFailure, updateUserCourse} = userCoursesSlice.actions;

// Asynchronous thunk action
export const fetchUserCourses = (lang: string): (dispatch) => Promise<void> => {
    console.log('fetch users courses', lang);
    return async dispatch => {
        dispatch(getUserCourses());
        try {
            await CourseService.userCourses(lang).then(data => {
                dispatch(getUserCoursesSuccess({userCourses: data, lang: lang}));
            });
        } catch (error) {
            dispatch(getUserCoursesFailure());
        }
    };
};
