export const LangCertificatePage = {
    documentTitle: {
        en: 'Certificates | BECOME',
        it: 'Certificati | BECOME'
    },
    header: {
        en: 'MATERIALS',
        it: 'MATERIALI'
    },

};
