import {ApplicationConfigurationTypes, SEND_TOAST} from "../types/ApplicationConfigurationTypes";
import {Toast} from "../../interfaces/Toast";

export const toggleVerticalSidebar = (): ApplicationConfigurationTypes => {
    return {
        type: "TOGGLE_SIDEBAR"
    };
};

export const hideVerticalSidebar = (): ApplicationConfigurationTypes => {
    return {
        type: "HIDE_SIDEBAR"
    };
};

export function sendToast(toast: Toast): ApplicationConfigurationTypes {
    return {
        type: SEND_TOAST,
        payload: toast
    };
};
