import {AxiosInterceptor} from "../AxiosInterceptor";
import {AxiosInstance, AxiosResponse} from "axios";
import {School} from "../../interfaces/School";

export default class SchoolService {
    static saveSchoolDetails = async (school: School): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/application/school/save_details';
                return ax.post(url, school).then((res: AxiosResponse) => {
                    return res.data;
                });
            }).catch(err => Promise.reject(err));
    };
}



