import React, {Dispatch, forwardRef, useEffect, useState} from "react";
import {Button, Form, Header, Icon, InputOnChangeData, Modal} from "semantic-ui-react";
import AugmentedFoodService from "../services/AugmentedFoodService";
import {sendToast} from "../../../store/actions/ApplicationConfigurationActions";
import {useDispatch} from "react-redux";
import FoodList from "../interfaces/FoodList";
import {Patient} from "../interfaces/Patient";
import {UserDevice} from "../../../interfaces/UserDevice";
import { addConfiguration } from "../../../store/slices/ConfigurationsSlice";

const CreateConfiguration = forwardRef((props: {
    open: boolean,
    setOpen: any,
    rooms: string[],
    foodLists: FoodList[],
    patients: Patient[],
    userDevices: UserDevice[]
}, ref) => {
    const dispatch: Dispatch<any> = useDispatch();

    const [userDevicesOptions, setUserDevicesOptions] = useState<{ key: string, text: string, value: number }[]>([]);
    const [userDeviceId, setUserDeviceId] = useState<number>(-1);

    const [patientOptions, setPatientOptions] = useState<{ key: string, text: string, value: number }[]>([]);
    const [patientId, setPatientId] = useState<number>(-1);

    const [roomOptions, setRoomOptions] = useState<{ key: string, text: string, value: number }[]>([]);
    const [room, setRoom] = useState<string>('');

    const [foodListOptions, setFoodListOptions] = useState<{ key: string, text: string, value: number }[]>([]);
    const [foodListId, setFoodListId] = useState<number>(-1);

    const [creatingConfiguration, setCreatingConfiguration] = useState<boolean>(false);


    useEffect(() => {
        const tempUserDevices = [];
        for (let k = 0; k < props.userDevices.length; k++) {
            tempUserDevices.push({
                key: 'ud-' + props.userDevices[k].id,
                text: props.userDevices[k].serial_number,
                value: props.userDevices[k].id
            });
        }
        setUserDevicesOptions(tempUserDevices);
    }, [props.userDevices]);

    useEffect(() => {
        const tempPatients = [];
        for (let k = 0; k < props.patients.length; k++) {
            tempPatients.push({
                key: 'p-' + props.patients[k].id,
                text: props.patients[k].secret_user_id,
                value: props.patients[k].id
            });
        }
        setPatientOptions(tempPatients);
    }, [props.patients]);

    useEffect(() => {
        const tempRooms = [];
        for (let k = 0; k < props.rooms.length; k++) {
            tempRooms.push({
                key: 'r-' + k,
                text: props.rooms[k],
                value: props.rooms[k]
            });
        }
        setRoomOptions(tempRooms);
    }, [props.rooms]);

    useEffect(() => {
        const tempFoodLists = [];
        for (let k = 0; k < props.foodLists.length; k++) {
            tempFoodLists.push({
                key: 'fl-' + props.foodLists[k].id,
                text: props.foodLists[k].name,
                value: props.foodLists[k].id
            });
        }
        setFoodListOptions(tempFoodLists);

    }, [props.foodLists]);

    const onSubmit = () => {
        setCreatingConfiguration(true);
        AugmentedFoodService.createConfiguration(userDeviceId, room, foodListId, patientId).then(r => {
            dispatch(addConfiguration({
                id: r.insertId,
                status: 'CREATED',
                created_at: 'now',
                food_list_name: props.foodLists.find((fl: FoodList) => fl.id === foodListId).name,
                food_list_id: foodListId,
                room: room,
                serial_number: props.userDevices.find((ud: UserDevice) => ud.id === userDeviceId).serial_number,
                patient_name: props.patients.find((p: Patient) => p.id === patientId).secret_user_id,
                updated_at: '',
                user_id: ''
            }));
            dispatch(sendToast({
                title: 'Success',
                messages: ['Configuration has been created'],
                color: 'positive'
            }));
        }).catch(err => {
            dispatch(sendToast({
                title: 'Error',
                messages: [err.message],
                color: 'negative'
            }));
        }).finally(() => {
            props.setOpen(false);
            setCreatingConfiguration(false);
        });
    };


    return <Modal
        basic
        onClose={() => props.setOpen(false)}
        onOpen={() => props.setOpen(true)}
        open={props.open}
        size='small'
    >
        <Header icon>
            <Icon name='cogs'/>
            Create new configuration
        </Header>
        <Modal.Content>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Select
                        fluid
                        inverted='true'
                        value={userDeviceId}
                        onChange={(e, data: InputOnChangeData) => {
                            if (data.value) {
                                setUserDeviceId(parseInt(data.value));
                            }
                        }}
                        options={userDevicesOptions}
                        placeholder='Select your device'
                    />
                    <Form.Select
                        fluid
                        inverted='true'
                        value={patientId}
                        onChange={(e, data: InputOnChangeData) => {
                            if (data.value) {
                                setPatientId(parseInt(data.value));
                            }
                        }}
                        options={patientOptions}
                        placeholder='Select patient'
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Select
                        fluid
                        inverted='true'
                        value={room}
                        onChange={(e, data: InputOnChangeData) => {
                            if (data.value) {
                                setRoom(data.value);
                            }
                        }}
                        options={roomOptions}
                        placeholder='Choose the room'
                    />
                    <Form.Select
                        fluid
                        inverted='true'
                        value={foodListId}
                        onChange={(e, data: InputOnChangeData) => {
                            if (data.value) {
                                setFoodListId(parseInt(data.value));
                            }
                        }}
                        options={foodListOptions}
                        placeholder='Select the food list'
                    />
                </Form.Group>
            </Form>


        </Modal.Content>
        <Modal.Actions>
            <Button basic color='grey' inverted onClick={() => props.setOpen(false)}>
                <Icon name='remove'/> Close
            </Button>
            <Button color='blue' inverted
                    disabled={creatingConfiguration || foodListId <= 0 || patientId <= 0 || room === '' || foodListId <= 0}
                    onClick={() => onSubmit()}>
                <Icon name='checkmark'/> Yes
            </Button>
        </Modal.Actions>
    </Modal>;
});
export default CreateConfiguration;
