export interface CountryData {
    text: string;
    key: string;
    value: string;
    flag: string;
}

export const COUNTRY_OPTIONS: CountryData[] = [
    {
        text: "Afghanistan",
        key: "af",
        value: "af",
        flag: "af"
    },
    {
        text: "Åland Islands",
        key: "ax",
        value: "ax",
        flag: "ax"
    },
    {
        text: "Albania",
        key: "al",
        value: "al",
        flag: "al"
    },
    {
        text: "Algeria",
        key: "dz",
        value: "dz",
        flag: "dz"
    },
    {
        text: "American Samoa",
        key: "as",
        value: "as",
        flag: "as"
    },
    {
        text: "Andorra",
        key: "ad",
        value: "ad",
        flag: "ad"
    },
    {
        text: "Angola",
        key: "ao",
        value: "ao",
        flag: "ao"
    },
    {
        text: "Anguilla",
        key: "ai",
        value: "ai",
        flag: "ai"
    },
    /*  {
          text: "Antarctica",
          key: "aq",
          value: "aq",
          flag: "aq"
      },*/
    {
        text: "Antigua and Barbuda",
        key: "ag",
        value: "ag",
        flag: "ag"
    },
    {
        text: "Argentina",
        key: "ar",
        value: "ar",
        flag: "ar"
    },
    {
        text: "Armenia",
        key: "am",
        value: "am",
        flag: "am"
    },
    {
        text: "Aruba",
        key: "aw",
        value: "aw",
        flag: "aw"
    },
    {
        text: "Australia",
        key: "au",
        value: "au",
        flag: "au"
    },
    {
        text: "Austria",
        key: "at",
        value: "at",
        flag: "at"
    },
    {
        text: "Azerbaijan",
        key: "az",
        value: "az",
        flag: "az"
    },
    {
        text: "Bahamas",
        key: "bs",
        value: "bs",
        flag: "bs"
    },
    {
        text: "Bahrain",
        key: "bh",
        value: "bh",
        flag: "bh"
    },
    {
        text: "Bangladesh",
        key: "bd",
        value: "bd",
        flag: "bd"
    },
    {
        text: "Barbados",
        key: "bb",
        value: "bb",
        flag: "bb"
    },
    {
        text: "Belarus",
        key: "by",
        value: "by",
        flag: "by"
    },
    {
        text: "Belgium",
        key: "be",
        value: "be",
        flag: "be"
    },
    {
        text: "Belize",
        key: "bz",
        value: "bz",
        flag: "bz"
    },
    {
        text: "Benin",
        key: "bj",
        value: "bj",
        flag: "bj"
    },
    {
        text: "Bermuda",
        key: "bm",
        value: "bm",
        flag: "bm"
    },
    {
        text: "Bhutan",
        key: "bt",
        value: "bt",
        flag: "bt"
    },
    {
        text: "Bolivia, Plurinational State of",
        key: "bo",
        value: "bo",
        flag: "bo"
    },
    /*  {
          text: "Bonaire, Sint Eustatius and Saba",
          key: "bq",
          value: "bq",
          flag: "bq"
      },*/
    {
        text: "Bosnia and Herzegovina",
        key: "ba",
        value: "ba",
        flag: "ba"
    },
    {
        text: "Botswana",
        key: "bw",
        value: "bw",
        flag: "bw"
    },
    {
        text: "Bouvet Island",
        key: "bv",
        value: "bv",
        flag: "bv"
    },
    {
        text: "Brazil",
        key: "br",
        value: "br",
        flag: "br"
    },
    {
        text: "British Indian Ocean Territory",
        key: "io",
        value: "io",
        flag: "io"
    },
    {
        text: "Brunei Darussalam",
        key: "bn",
        value: "bn",
        flag: "bn"
    },
    {
        text: "Bulgaria",
        key: "bg",
        value: "bg",
        flag: "bg"
    },
    {
        text: "Burkina Faso",
        key: "bf",
        value: "bf",
        flag: "bf"
    },
    {
        text: "Burundi",
        key: "bi",
        value: "bi",
        flag: "bi"
    },
    {
        text: "Cambodia",
        key: "kh",
        value: "kh",
        flag: "kh"
    },
    {
        text: "Cameroon",
        key: "cm",
        value: "cm",
        flag: "cm"
    },
    {
        text: "Canada",
        key: "ca",
        value: "ca",
        flag: "ca"
    },
    {
        text: "Cape Verde",
        key: "cv",
        value: "cv",
        flag: "cv"
    },
    {
        text: "Cayman Islands",
        key: "ky",
        value: "ky",
        flag: "ky"
    },
    {
        text: "Central African Republic",
        key: "cf",
        value: "cf",
        flag: "cf"
    },
    {
        text: "Chad",
        key: "td",
        value: "td",
        flag: "td"
    },
    {
        text: "Chile",
        key: "cl",
        value: "cl",
        flag: "cl"
    },
    {
        text: "China",
        key: "cn",
        value: "cn",
        flag: "cn"
    },
    {
        text: "Christmas Island",
        key: "cx",
        value: "cx",
        flag: "cx"
    },
    {
        text: "Cocos (Keeling) Islands",
        key: "cc",
        value: "cc",
        flag: "cc"
    },
    {
        text: "Colombia",
        key: "co",
        value: "co",
        flag: "co"
    },
    {
        text: "Comoros",
        key: "km",
        value: "km",
        flag: "km"
    },
    {
        text: "Congo",
        key: "cg",
        value: "cg",
        flag: "cg"
    },
    {
        text: "Congo, the Democratic Republic of the",
        key: "cd",
        value: "cd",
        flag: "cd"
    },
    {
        text: "Cook Islands",
        key: "ck",
        value: "ck",
        flag: "ck"
    },
    {
        text: "Costa Rica",
        key: "cr",
        value: "cr",
        flag: "cr"
    },
    {
        text: "Côte d'Ivoire",
        key: "ci",
        value: "ci",
        flag: "ci"
    },
    {
        text: "Croatia",
        key: "hr",
        value: "hr",
        flag: "hr"
    },
    {
        text: "Cuba",
        key: "cu",
        value: "cu",
        flag: "cu"
    },
    /* {
         text: "Curaçao",
         key: "cw",
         value: "cw",
         flag: "cw"
     },*/
    {
        text: "Cyprus",
        key: "cy",
        value: "cy",
        flag: "cy"
    },
    {
        text: "Czech Republic",
        key: "cz",
        value: "cz",
        flag: "cz"
    },
    {
        text: "Denmark",
        key: "dk",
        value: "dk",
        flag: "dk"
    },
    {
        text: "Djibouti",
        key: "dj",
        value: "dj",
        flag: "dj"
    },
    {
        text: "Dominica",
        key: "dm",
        value: "dm",
        flag: "dm"
    },
    {
        text: "Dominican Republic",
        key: "do",
        value: "do",
        flag: "do"
    },
    {
        text: "Ecuador",
        key: "ec",
        value: "ec",
        flag: "ec"
    },
    {
        text: "Egypt",
        key: "eg",
        value: "eg",
        flag: "eg"
    },
    {
        text: "El Salvador",
        key: "sv",
        value: "sv",
        flag: "sv"
    },
    {
        text: "Equatorial Guinea",
        key: "gq",
        value: "gq",
        flag: "gq"
    },
    {
        text: "Eritrea",
        key: "er",
        value: "er",
        flag: "er"
    },
    {
        text: "Estonia",
        key: "ee",
        value: "ee",
        flag: "ee"
    },
    {
        text: "Ethiopia",
        key: "et",
        value: "et",
        flag: "et"
    },
    {
        text: "Falkland Islands (Malvinas)",
        key: "fk",
        value: "fk",
        flag: "fk"
    },
    {
        text: "Faroe Islands",
        key: "fo",
        value: "fo",
        flag: "fo"
    },
    {
        text: "Fiji",
        key: "fj",
        value: "fj",
        flag: "fj"
    },
    {
        text: "Finland",
        key: "fi",
        value: "fi",
        flag: "fi"
    },
    {
        text: "France",
        key: "fr",
        value: "fr",
        flag: "fr"
    },
    {
        text: "French Guiana",
        key: "gf",
        value: "gf",
        flag: "gf"
    },
    {
        text: "French Polynesia",
        key: "pf",
        value: "pf",
        flag: "pf"
    },
    {
        text: "French Southern Territories",
        key: "tf",
        value: "tf",
        flag: "tf"
    },
    {
        text: "Gabon",
        key: "ga",
        value: "ga",
        flag: "ga"
    },
    {
        text: "Gambia",
        key: "gm",
        value: "gm",
        flag: "gm"
    },
    {
        text: "Georgia",
        key: "ge",
        value: "ge",
        flag: "ge"
    },
    {
        text: "Germany",
        key: "de",
        value: "de",
        flag: "de"
    },
    {
        text: "Ghana",
        key: "gh",
        value: "gh",
        flag: "gh"
    },
    {
        text: "Gibraltar",
        key: "gi",
        value: "gi",
        flag: "gi"
    },
    {
        text: "Greece",
        key: "gr",
        value: "gr",
        flag: "gr"
    },
    {
        text: "Greenland",
        key: "gl",
        value: "gl",
        flag: "gl"
    },
    {
        text: "Grenada",
        key: "gd",
        value: "gd",
        flag: "gd"
    },
    {
        text: "Guadeloupe",
        key: "gp",
        value: "gp",
        flag: "gp"
    },
    {
        text: "Guam",
        key: "gu",
        value: "gu",
        flag: "gu"
    },
    {
        text: "Guatemala",
        key: "gt",
        value: "gt",
        flag: "gt"
    },
    /*  {
          text: "Guernsey",
          key: "gg",
          value: "gg",
          flag: "gg"
      },*/
    {
        text: "Guinea",
        key: "gn",
        value: "gn",
        flag: "gn"
    },
    {
        text: "Guinea-Bissau",
        key: "gw",
        value: "gw",
        flag: "gw"
    },
    {
        text: "Guyana",
        key: "gy",
        value: "gy",
        flag: "gy"
    },
    {
        text: "Haiti",
        key: "ht",
        value: "ht",
        flag: "ht"
    },
    {
        text: "Heard Island and McDonald Islands",
        key: "hm",
        value: "hm",
        flag: "hm"
    },
    {
        text: "Holy See (Vatican City State)",
        key: "va",
        value: "va",
        flag: "va"
    },
    {
        text: "Honduras",
        key: "hn",
        value: "hn",
        flag: "hn"
    },
    {
        text: "Hong Kong",
        key: "hk",
        value: "hk",
        flag: "hk"
    },
    {
        text: "Hungary",
        key: "hu",
        value: "hu",
        flag: "hu"
    },
    {
        text: "Iceland",
        key: "is",
        value: "is",
        flag: "is"
    },
    {
        text: "India",
        key: "in",
        value: "in",
        flag: "in"
    },
    {
        text: "Indonesia",
        key: "id",
        value: "id",
        flag: "id"
    },
    {
        text: "Iran, Islamic Republic of",
        key: "ir",
        value: "ir",
        flag: "ir"
    },
    {
        text: "Iraq",
        key: "iq",
        value: "iq",
        flag: "iq"
    },
    {
        text: "Ireland",
        key: "ie",
        value: "ie",
        flag: "ie"
    },
    /*   {
           text: "Isle of Man",
           key: "im",
           value: "im",
           flag: "im"
       },*/
    {
        text: "Israel",
        key: "il",
        value: "il",
        flag: "il"
    },
    {
        text: "Italy",
        key: "it",
        value: "it",
        flag: "it"
    },
    {
        text: "Jamaica",
        key: "jm",
        value: "jm",
        flag: "jm"
    },
    {
        text: "Japan",
        key: "jp",
        value: "jp",
        flag: "jp"
    },
    /*  {
          text: "Jersey",
          key: "je",
          value: "je",
          flag: "je"
      },*/
    {
        text: "Jordan",
        key: "jo",
        value: "jo",
        flag: "jo"
    },
    {
        text: "Kazakhstan",
        key: "kz",
        value: "kz",
        flag: "kz"
    },
    {
        text: "Kenya",
        key: "ke",
        value: "ke",
        flag: "ke"
    },
    {
        text: "Kiribati",
        key: "ki",
        value: "ki",
        flag: "ki"
    },
    {
        text: "Korea, Democratic People's Republic of",
        key: "kp",
        value: "kp",
        flag: "kp"
    },
    {
        text: "Korea, Republic of",
        key: "kr",
        value: "kr",
        flag: "kr"
    },
    {
        text: "Kuwait",
        key: "kw",
        value: "kw",
        flag: "kw"
    },
    {
        text: "Kyrgyzstan",
        key: "kg",
        value: "kg",
        flag: "kg"
    },
    {
        text: "Lao People's Democratic Republic",
        key: "la",
        value: "la",
        flag: "la"
    },
    {
        text: "Latvia",
        key: "lv",
        value: "lv",
        flag: "lv"
    },
    {
        text: "Lebanon",
        key: "lb",
        value: "lb",
        flag: "lb"
    },
    {
        text: "Lesotho",
        key: "ls",
        value: "ls",
        flag: "ls"
    },
    {
        text: "Liberia",
        key: "lr",
        value: "lr",
        flag: "lr"
    },
    {
        text: "Libya",
        key: "ly",
        value: "ly",
        flag: "ly"
    },
    {
        text: "Liechtenstein",
        key: "li",
        value: "li",
        flag: "li"
    },
    {
        text: "Lithuania",
        key: "lt",
        value: "lt",
        flag: "lt"
    },
    {
        text: "Luxembourg",
        key: "lu",
        value: "lu",
        flag: "lu"
    },
    {
        text: "Macao",
        key: "mo",
        value: "mo",
        flag: "mo"
    },
    {
        text: "Macedonia, the Former Yugoslav Republic of",
        key: "mk",
        value: "mk",
        flag: "mk"
    },
    {
        text: "Madagascar",
        key: "mg",
        value: "mg",
        flag: "mg"
    },
    {
        text: "Malawi",
        key: "mw",
        value: "mw",
        flag: "mw"
    },
    {
        text: "Malaysia",
        key: "my",
        value: "my",
        flag: "my"
    },
    {
        text: "Maldives",
        key: "mv",
        value: "mv",
        flag: "mv"
    },
    {
        text: "Mali",
        key: "ml",
        value: "ml",
        flag: "ml"
    },
    {
        text: "Malta",
        key: "mt",
        value: "mt",
        flag: "mt"
    },
    {
        text: "Marshall Islands",
        key: "mh",
        value: "mh",
        flag: "mh"
    },
    {
        text: "Martinique",
        key: "mq",
        value: "mq",
        flag: "mq"
    },
    {
        text: "Mauritania",
        key: "mr",
        value: "mr",
        flag: "mr"
    },
    {
        text: "Mauritius",
        key: "mu",
        value: "mu",
        flag: "mu"
    },
    {
        text: "Mayotte",
        key: "yt",
        value: "yt",
        flag: "yt"
    },
    {
        text: "Mexico",
        key: "mx",
        value: "mx",
        flag: "mx"
    },
    {
        text: "Micronesia, Federated States of",
        key: "fm",
        value: "fm",
        flag: "fm"
    },
    {
        text: "Moldova, Republic of",
        key: "md",
        value: "md",
        flag: "md"
    },
    {
        text: "Monaco",
        key: "mc",
        value: "mc",
        flag: "mc"
    },
    {
        text: "Mongolia",
        key: "mn",
        value: "mn",
        flag: "mn"
    },
    {
        text: "Montenegro",
        key: "me",
        value: "me",
        flag: "me"
    },
    {
        text: "Montserrat",
        key: "ms",
        value: "ms",
        flag: "ms"
    },
    {
        text: "Morocco",
        key: "ma",
        value: "ma",
        flag: "ma"
    },
    {
        text: "Mozambique",
        key: "mz",
        value: "mz",
        flag: "mz"
    },
    {
        text: "Myanmar",
        key: "mm",
        value: "mm",
        flag: "mm"
    },
    {
        text: "Namibia",
        key: "na",
        value: "na",
        flag: "na"
    },
    {
        text: "Nauru",
        key: "nr",
        value: "nr",
        flag: "nr"
    },
    {
        text: "Nepal",
        key: "np",
        value: "np",
        flag: "np"
    },
    {
        text: "Netherlands",
        key: "nl",
        value: "nl",
        flag: "nl"
    },
    {
        text: "New Caledonia",
        key: "nc",
        value: "nc",
        flag: "nc"
    },
    {
        text: "New Zealand",
        key: "nz",
        value: "nz",
        flag: "nz"
    },
    {
        text: "Nicaragua",
        key: "ni",
        value: "ni",
        flag: "ni"
    },
    {
        text: "Niger",
        key: "ne",
        value: "ne",
        flag: "ne"
    },
    {
        text: "Nigeria",
        key: "ng",
        value: "ng",
        flag: "ng"
    },
    {
        text: "Niue",
        key: "nu",
        value: "nu",
        flag: "nu"
    },
    {
        text: "Norfolk Island",
        key: "nf",
        value: "nf",
        flag: "nf"
    },
    {
        text: "Northern Mariana Islands",
        key: "mp",
        value: "mp",
        flag: "mp"
    },
    {
        text: "Norway",
        key: "no",
        value: "no",
        flag: "no"
    },
    {
        text: "Oman",
        key: "om",
        value: "om",
        flag: "om"
    },
    {
        text: "Pakistan",
        key: "pk",
        value: "pk",
        flag: "pk"
    },
    {
        text: "Palau",
        key: "pw",
        value: "pw",
        flag: "pw"
    },
    {
        text: "Palestine, State of",
        key: "ps",
        value: "ps",
        flag: "ps"
    },
    {
        text: "Panama",
        key: "pa",
        value: "pa",
        flag: "pa"
    },
    {
        text: "Papua New Guinea",
        key: "pg",
        value: "pg",
        flag: "pg"
    },
    {
        text: "Paraguay",
        key: "py",
        value: "py",
        flag: "py"
    },
    {
        text: "Peru",
        key: "pe",
        value: "pe",
        flag: "pe"
    },
    {
        text: "Philippines",
        key: "ph",
        value: "ph",
        flag: "ph"
    },
    {
        text: "Pitcairn",
        key: "pn",
        value: "pn",
        flag: "pn"
    },
    {
        text: "Poland",
        key: "pl",
        value: "pl",
        flag: "pl"
    },
    {
        text: "Portugal",
        key: "pt",
        value: "pt",
        flag: "pt"
    },
    {
        text: "Puerto Rico",
        key: "pr",
        value: "pr",
        flag: "pr"
    },
    {
        text: "Qatar",
        key: "qa",
        value: "qa",
        flag: "qa"
    },
    {
        text: "Réunion",
        key: "re",
        value: "re",
        flag: "re"
    },
    {
        text: "Romania",
        key: "ro",
        value: "ro",
        flag: "ro"
    },
    {
        text: "Russian Federation",
        key: "ru",
        value: "ru",
        flag: "ru"
    },
    {
        text: "Rwanda",
        key: "rw",
        value: "rw",
        flag: "rw"
    },
    /*  {
          text: "Saint Barthélemy",
          key: "bl",
          value: "bl",
          flag: "bl"
      },*/
    {
        text: "Saint Helena, Ascension and Tristan da Cunha",
        key: "sh",
        value: "sh",
        flag: "sh"
    },
    {
        text: "Saint Kitts and Nevis",
        key: "kn",
        value: "kn",
        flag: "kn"
    },
    {
        text: "Saint Lucia",
        key: "lc",
        value: "lc",
        flag: "lc"
    },
    /*  {
          text: "Saint Martin (French part)",
          key: "mf",
          value: "mf",
          flag: "mf"
      },*/
    {
        text: "Saint Pierre and Miquelon",
        key: "pm",
        value: "pm",
        flag: "pm"
    },
    {
        text: "Saint Vincent and the Grenadines",
        key: "vc",
        value: "vc",
        flag: "vc"
    },
    {
        text: "Samoa",
        key: "ws",
        value: "ws",
        flag: "ws"
    },
    {
        text: "San Marino",
        key: "sm",
        value: "sm",
        flag: "sm"
    },
    {
        text: "Sao Tome and Principe",
        key: "st",
        value: "st",
        flag: "st"
    },
    {
        text: "Saudi Arabia",
        key: "sa",
        value: "sa",
        flag: "sa"
    },
    {
        text: "Senegal",
        key: "sn",
        value: "sn",
        flag: "sn"
    },
    {
        text: "Serbia",
        key: "rs",
        value: "rs",
        flag: "rs"
    },
    {
        text: "Seychelles",
        key: "sc",
        value: "sc",
        flag: "sc"
    },
    {
        text: "Sierra Leone",
        key: "sl",
        value: "sl",
        flag: "sl"
    },
    {
        text: "Singapore",
        key: "sg",
        value: "sg",
        flag: "sg"
    },
    /* {
         text: "Sint Maarten (Dutch part)",
         key: "sx",
         value: "sx",
         flag: "sx"
     },*/
    {
        text: "Slovakia",
        key: "sk",
        value: "sk",
        flag: "sk"
    },
    {
        text: "Slovenia",
        key: "si",
        value: "si",
        flag: "si"
    },
    {
        text: "Solomon Islands",
        key: "sb",
        value: "sb",
        flag: "sb"
    },
    {
        text: "Somalia",
        key: "so",
        value: "so",
        flag: "so"
    },
    {
        text: "South Africa",
        key: "za",
        value: "za",
        flag: "za"
    },
    {
        text: "South Georgia and the South Sandwich Islands",
        key: "gs",
        value: "gs",
        flag: "gs"
    },
    /*   {
           text: "South Sudan",
           key: "ss",
           value: "ss",
           flag: "ss"
       },*/
    {
        text: "Spain",
        key: "es",
        value: "es",
        flag: "es"
    },
    {
        text: "Sri Lanka",
        key: "lk",
        value: "lk",
        flag: "lk"
    },
    {
        text: "Sudan",
        key: "sd",
        value: "sd",
        flag: "sd"
    },
    {
        text: "Suriname",
        key: "sr",
        value: "sr",
        flag: "sr"
    },
    {
        text: "Svalbard and Jan Mayen",
        key: "sj",
        value: "sj",
        flag: "sj"
    },
    {
        text: "Swaziland",
        key: "sz",
        value: "sz",
        flag: "sz"
    },
    {
        text: "Sweden",
        key: "se",
        value: "se",
        flag: "se"
    },
    {
        text: "Switzerland",
        key: "ch",
        value: "ch",
        flag: "ch"
    },
    {
        text: "Syrian Arab Republic",
        key: "sy",
        value: "sy",
        flag: "sy"
    },
    {
        text: "Taiwan, Province of China",
        key: "tw",
        value: "tw",
        flag: "tw"
    },
    {
        text: "Tajikistan",
        key: "tj",
        value: "tj",
        flag: "tj"
    },
    {
        text: "Tanzania, United Republic of",
        key: "tz",
        value: "tz",
        flag: "tz"
    },
    {
        text: "Thailand",
        key: "th",
        value: "th",
        flag: "th"
    },
    {
        text: "Timor-Leste",
        key: "tl",
        value: "tl",
        flag: "tl"
    },
    {
        text: "Togo",
        key: "tg",
        value: "tg",
        flag: "tg"
    },
    {
        text: "Tokelau",
        key: "tk",
        value: "tk",
        flag: "tk"
    },
    {
        text: "Tonga",
        key: "to",
        value: "to",
        flag: "to"
    },
    {
        text: "Trinidad and Tobago",
        key: "tt",
        value: "tt",
        flag: "tt"
    },
    {
        text: "Tunisia",
        key: "tn",
        value: "tn",
        flag: "tn"
    },
    {
        text: "Turkey",
        key: "tr",
        value: "tr",
        flag: "tr"
    },
    {
        text: "Turkmenistan",
        key: "tm",
        value: "tm",
        flag: "tm"
    },
    {
        text: "Turks and Caicos Islands",
        key: "tc",
        value: "tc",
        flag: "tc"
    },
    {
        text: "Tuvalu",
        key: "tv",
        value: "tv",
        flag: "tv"
    },
    {
        text: "Uganda",
        key: "ug",
        value: "ug",
        flag: "ug"
    },
    {
        text: "Ukraine",
        key: "ua",
        value: "ua",
        flag: "ua"
    },
    {
        text: "United Arab Emirates",
        key: "ae",
        value: "ae",
        flag: "ae"
    },
    {
        text: "United Kingdom",
        key: "gb",
        value: "gb",
        flag: "gb"
    },
    {
        text: "United States",
        key: "us",
        value: "us",
        flag: "us"
    },
    {
        text: "United States Minor Outlying Islands",
        key: "um",
        value: "um",
        flag: "um"
    },
    {
        text: "Uruguay",
        key: "uy",
        value: "uy",
        flag: "uy"
    },
    {
        text: "Uzbekistan",
        key: "uz",
        value: "uz",
        flag: "uz"
    },
    {
        text: "Vanuatu",
        key: "vu",
        value: "vu",
        flag: "vu"
    },
    {
        text: "Venezuela, Bolivarian Republic of",
        key: "ve",
        value: "ve",
        flag: "ve"
    },
    {
        text: "Viet Nam",
        key: "vn",
        value: "vn",
        flag: "vn"
    },
    {
        text: "Virgin Islands, British",
        key: "vg",
        value: "vg",
        flag: "vg"
    },
    {
        text: "Virgin Islands, U.S.",
        key: "vi",
        value: "vi",
        flag: "vi"
    },
    {
        text: "Wallis and Futuna",
        key: "wf",
        value: "wf",
        flag: "wf"
    },
    {
        text: "Western Sahara",
        key: "eh",
        value: "eh",
        flag: "eh"
    },
    {
        text: "Yemen",
        key: "ye",
        value: "ye",
        flag: "ye"
    },
    {
        text: "Zambia",
        key: "zm",
        value: "zm",
        flag: "zm"
    },
    {
        text: "Zimbabwe",
        key: "zw",
        value: "zw",
        flag: "zw"
    }
];
