import React, {Dispatch, forwardRef, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {UserDevice} from "../../../interfaces/UserDevice";
import {Device} from "../../../interfaces/Device";
import DeviceService from "../../../services/user/DeviceService";
import {
    Form,
    Grid,
    Header,
    Icon,
    Image, InputOnChangeData,
    List,
    Modal,
} from "semantic-ui-react";
import {LangDevices} from "../../../lang/Devices";
import {sendToast} from "../../../store/actions/ApplicationConfigurationActions";
import {RootState} from "../../../store";
import {SecondaryButton} from "../../../components/Buttons/SecondaryButton";
import {addUserDevice} from "../../../store/slices/UserDevicesSlice";
import {PrimaryButton} from "../../../components/Buttons/PrimaryButton";


const DevicesModal = forwardRef((props: { open: boolean, setOpen: any, userDevices: UserDevice[] }, ref) => {
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const dispatch: Dispatch<any> = useDispatch();

    const addToUserDevices = React.useCallback(
        (userDevice: UserDevice) => {
            dispatch(addUserDevice(userDevice));
        },
        [dispatch]
    );

    useEffect(() => {
        window.document.title = LangDevices.headTitle[lang];
        DeviceService.listDevices().then((res: Device[]) => setDevices(res));
    }, []);

    const [selectedDeviceTypeId, setSelectedDeviceTypeId] = useState<number>();
    const [serialNumber, setSerialNumber] = useState<string>('');

    const [creatingUserDevice, setCreatingUserDevice] = useState<boolean>(false);

    const [devices, setDevices] = useState<Device[]>([]);

    const addDevice = () => {
        setCreatingUserDevice(true);
        const device = devices.find((d: Device) => d.id === selectedDeviceTypeId);
        DeviceService.addUserDevice(serialNumber, selectedDeviceTypeId)
            .then(res => {
                if (res.insertId) {
                    addToUserDevices({
                        id: res.insertId,
                        user_id: '',
                        serial_number: serialNumber,
                        device_id: selectedDeviceTypeId,
                        name: device.name,
                        icon: device.icon
                    });
                    dispatch(sendToast({
                        title: LangDevices.success[lang],
                        messages: [LangDevices.deviceAdded[lang]],
                        color: 'positive'
                    }));
                }
            })
            .catch(err => {
                dispatch(sendToast({
                    title: LangDevices.error[lang],
                    messages: [err.message],
                    color: 'negative'
                }));
            })
            .finally(() => {
                setCreatingUserDevice(false);
            });
    };

    return <Modal
        onClose={() => props.setOpen(false)}
        open={props.open}
    >
        <Modal.Header>
            <Header.Content style={{display: 'flex', alignItems: 'center'}}>
                <Image src={'./assets/images/virtual-reality.svg'} style={{marginRight: '15px'}}
                       size={'tiny'}/> {LangDevices.title[lang].toUpperCase()}
            </Header.Content>
        </Modal.Header>
        <Modal.Content>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <List style={{maxHeight: '250px', overflowY: 'auto'}}>
                            {props.userDevices.map((userDevice: UserDevice) => {
                                return <List.Item key={'device-' + userDevice.id}>
                                    <Image avatar src={userDevice.icon}/>
                                    <List.Content>
                                        <List.Header>{userDevice.name}</List.Header>
                                        <List.Description>
                                            ID: {userDevice.serial_number}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>;
                            })}
                        </List>
                    </Grid.Column>
                    <Grid.Column>
                        <Header as={'h4'}>{LangDevices.add[lang]}</Header>
                        <Form>
                            <Form.Group widths='equal'>
                                <Form.Select
                                    fluid
                                    value={selectedDeviceTypeId}
                                    onChange={(e, data: InputOnChangeData) => {
                                        if (data.value) {
                                            setSelectedDeviceTypeId(parseInt(data.value));
                                        }
                                    }}
                                    options={[
                                        {key: 'd-1', text: 'Oculus Quest', value: 1},
                                        {key: 'd-2', text: 'Oculus Quest 2', value: 2},
                                        {key: 'd-3', text: 'Oculus GO', value: 3}
                                    ]}
                                    placeholder={LangDevices.deviceType[lang]}
                                />

                                <Form.Input fluid placeholder={LangDevices.deviceID[lang]}
                                            value={serialNumber}
                                            onChange={(e, data: InputOnChangeData) => setSerialNumber(data.value)}/>
                            </Form.Group>
                        </Form>

                        <SecondaryButton className={creatingUserDevice ? 'loading' : ''}
                                         disabled={serialNumber === '' || creatingUserDevice}
                                         onClick={() => addDevice()}>
                            <Icon name='checkmark'/> Create
                        </SecondaryButton>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Modal.Content>

        <Modal.Actions>
            <PrimaryButton onClick={() => props.setOpen(false)}>
                CLose
            </PrimaryButton>
        </Modal.Actions>
    </Modal>;
});

export default DevicesModal;
