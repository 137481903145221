import React, {Dispatch, useEffect, useState} from 'react';
import {Auth} from "aws-amplify";
import {AUTH_STATE_CHANGE_EVENT, AuthState, UI_AUTH_CHANNEL} from '@aws-amplify/ui-components';
import {useDispatch, useSelector} from "react-redux";
import {Hub, HubCapsule} from "@aws-amplify/core";
import {sendToast} from "../../store/actions/ApplicationConfigurationActions";
import {Form, Header, Message} from "semantic-ui-react";
import {LangConfirmSignUp} from "../../lang/ConfirmSignUp";
import {RootState} from "../../store";
import {SecondaryButton} from "../../components/Buttons/SecondaryButton";
import Authenticator from "../Authenticator";


const ConfirmSignUp: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();

    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');

    const [confirmationCode, setConfirmationCode] = useState<string>('');
    const [confirmationCodeError, setConfirmationCodeError] = useState<string>('');

    const [checkingCode, setCheckingCode] = useState<boolean>(false);
    const [emailSent, setEmailSent] = useState<boolean>(false);

    useEffect(() => {
        let subscribed = true;
        Hub.listen(UI_AUTH_CHANNEL, (data: HubCapsule) => {
            if (data.payload.data && subscribed) {
                setEmail(data.payload.data);
            }
        });
        return () => {
            subscribed = false;
        };
    }, []);

    const checkCode = () => {
        if (email === '') {
            setEmailError(LangConfirmSignUp.emptyEmail[lang]);
            return;
        }
        if (confirmationCode === '') {
            setConfirmationCodeError(LangConfirmSignUp.emptyCode[lang]);
            return;
        }

        setCheckingCode(true);
        Auth.confirmSignUp(email, confirmationCode).then(res => {
            if (res === 'SUCCESS') {

                Hub.dispatch(UI_AUTH_CHANNEL, {
                    event: AUTH_STATE_CHANGE_EVENT,
                    message: AuthState.SignIn,
                    data: email
                });
            }

        }).catch(err => {
            console.log(err.code);
            switch (err.code) {
                case 'UserNotFoundException':
                    setEmailError('Email not found');
                    break;

                case 'CodeMismatchException':
                    setConfirmationCodeError(err.message);
                    break;

                default:
                    dispatch(sendToast({title: 'Error', messages: [err.message], color: 'negative'}));
            }
        }).finally(() => {
            setCheckingCode(false);
        });
    };

    const sendMailAgain = () => {
        if (email === '') {
            setEmailError(LangConfirmSignUp.emptyEmail[lang]);
            return;
        }
        Auth.resendSignUp(email).then().finally(() => {
            setEmailSent(true);
            setTimeout(() => {
                setEmailSent(false);
            }, 5000);
        });
    };

    return <Authenticator
        header={
            <>
                {LangConfirmSignUp.header[lang]}
                <Header.Subheader>{LangConfirmSignUp.subHeader[lang]}</Header.Subheader></>
        }
        form={
            <>
                <Form.Input
                    fluid label={'Email'}
                    value={email}
                    onFocus={() => setEmailError('')}
                    error={emailError !== '' ? emailError : false}
                    className={emailError !== '' ? 'error' : ''}
                    onChange={e => setEmail(e.target.value)}
                    placeholder={LangConfirmSignUp.emailLabel[lang]}
                />

                <Form.Input
                    fluid label={LangConfirmSignUp.confirmationCode[lang]}
                    value={confirmationCode}
                    onFocus={() => setConfirmationCodeError('')}
                    error={confirmationCodeError !== '' ? confirmationCodeError : false}
                    className={confirmationCodeError !== '' ? 'error' : ''}
                    onChange={e => setConfirmationCode(e.target.value)}
                    placeholder={LangConfirmSignUp.confirmationCodeLabel[lang]}
                />

                <Message success>{LangConfirmSignUp.emailSent[lang]}</Message>

                <SecondaryButton fluid size='large' style={{padding: '14px 0'}}
                                 className={'whiteButton ' + (checkingCode ? 'loading' : '')}
                                 disabled={checkingCode} onClick={() => checkCode()}>
                    {LangConfirmSignUp.button[lang]}
                </SecondaryButton>
            </>
        }
        formStatus={{success: emailSent}}
        links={[
            {message: AuthState.SignIn, label: LangConfirmSignUp.backSignIn[lang]},
            {callback: sendMailAgain, label: LangConfirmSignUp.sendCodeAgain[lang]}
        ]}/>;
};

export default ConfirmSignUp;
