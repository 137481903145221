import React, {Dispatch, useEffect, useState} from 'react';
import {Auth} from "aws-amplify";
import {useDispatch, useSelector} from "react-redux";

import {LangPayments} from "../lang/Payments";
import {
    Segment,
    Button,
    Icon,
    Confirm,
    Loader,
    Dimmer,
    Transition,
    Divider, Header, Modal, Item
} from "semantic-ui-react";
import {CardComponent} from "../components/CardComponent";
import {PaymentMethod} from "@stripe/stripe-js";
import StripeService from "../services/user/StripeService";
import {paymentIcons} from "../utils/paymentIcons";
import {sendToast} from "../store/actions/ApplicationConfigurationActions";
import {RootState} from "../store";
import {SecondaryButton} from "../components/Buttons/SecondaryButton";
import {DangerButton} from "../components/Buttons/DangerButton";
import {fetchPaymentMethods, paymentMethodsSelector} from "../store/slices/PaymentMethods";


const BillingPage: React.FC = () => {

    const dispatch: Dispatch<any> = useDispatch();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const [user, setUser] = useState<{ username: string, attributes: { email: string } }>();

    const [detachPaymentId, setDetachPaymentId] = useState<string>('');
    const [detachingPaymentMethod, setDetachingPaymentMethod] = useState<boolean>(false);
    const [cardModalOpen, setCardModalOpen] = useState<boolean>(false);

    const {retrievingPaymentMethods, paymentMethods, paymentMethodsFetched} = useSelector(paymentMethodsSelector);

    useEffect(() => {
        let isSubscribed = true;
        window.document.title = LangPayments.billingPage[lang];
        Auth.currentUserInfo().then((user) => {
            if (isSubscribed) {
                setUser(user);
            }
        });
        if (!paymentMethodsFetched) {
            dispatch(fetchPaymentMethods());
        }
        return () => {
            isSubscribed = false;
        };
    }, []);

    const detachPaymentMethod = () => {
        setDetachingPaymentMethod(true);
        StripeService.detachPaymentMethod(detachPaymentId)
            .then((result: PaymentMethod) => {
                dispatch(removePaymentMethod(result));
                dispatch(sendToast({
                    title: LangPayments.success[lang],
                    messages: [LangPayments.successMessage[lang]],
                    color: 'positive'
                }));
            }).catch(err => {
            dispatch(sendToast({
                title: 'Error',
                messages: [err.message],
                color: 'negative'
            }));
        })
            .finally(() => {
                setDetachPaymentId('');
                setDetachingPaymentMethod(false);
            });
    };


    return (
        <>
            <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
                <Header as='h1'>{LangPayments.title[lang].toUpperCase()}</Header>
                <Dimmer inverted className={retrievingPaymentMethods ? 'active' : ''}>
                    <Loader inverted>Loading</Loader>
                </Dimmer>

                <Header as='h3'> <Icon name='credit card' size={'mini'}/>{LangPayments.creditCards[lang].toUpperCase()}
                </Header>
                <Confirm
                    open={detachPaymentId !== ''}
                    header={LangPayments.creditHeader[lang]}
                    content={<Dimmer inverted className={detachingPaymentMethod ? 'active' : ''}>
                        <Loader inverted>Removing...</Loader>
                    </Dimmer>}
                    onCancel={() => setDetachPaymentId('')}
                    onConfirm={() => detachPaymentMethod()}
                />
                <div style={{textAlign: "right"}}>
                    <SecondaryButton compact color={'blue'}
                            onClick={() => setCardModalOpen(true)}>{LangPayments.addCardButton[lang]}</SecondaryButton>
                </div>
                <Transition.Group as={Item.Group} duration={200} divided>
                    {paymentMethods.map((pm: PaymentMethod) => {
                        return (
                            <Item key={pm.id}>
                                <Item.Content verticalAlign='middle'>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Item.Image src={paymentIcons(pm.card.brand)} style={{borderRadius: '3px', marginRight: '15px'}}
                                                    size='tiny'/>
                                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                            <Header as={'h4'}
                                                    style={{width: '100%', marginBottom: '5px'}}>{pm.card.brand.toUpperCase()}</Header>
                                            <Item.Description>
                                                <span
                                                    style={{marginRight: '25px'}}>Ending with ...{pm.card.last4}</span>
                                                <span>{LangPayments.cardExpiration[lang]} {pm.card.exp_month}/{pm.card.exp_year}</span>
                                            </Item.Description>
                                        </div>
                                        <Item.Extra style={{textAlign: 'right', flex: 1}}>
                                            <DangerButton animated={'vertical'}
                                                    style={{padding: '10px 20px', textAlign: 'center'}}
                                                    onClick={() => setDetachPaymentId(pm.id)}>
                                                <Button.Content hidden
                                                                style={{
                                                                    fontSize: '0.62em'
                                                                }}>{LangPayments.cardRemove[lang]}</Button.Content>
                                                <Button.Content visible style={{margin: '0 15px 0 0'}}>
                                                    <Icon name='trash'/>
                                                </Button.Content>
                                            </DangerButton>
                                        </Item.Extra>
                                    </div>
                                </Item.Content>
                            </Item>
                        );
                    })}
                    {paymentMethods.length === 0 ? <Item key='no-methods'>
                        <Item.Content>
                            {LangPayments.noCardsFound[lang]}
                        </Item.Content>
                    </Item> : <></>}
                </Transition.Group>

                <Divider/>


                <Header as='h3'> <Icon name='paypal' size={'mini'}/>{LangPayments.paypal[lang].toUpperCase()}</Header>
                <div style={{textAlign: "right"}}>
                    <SecondaryButton compact color={'blue'} disabled>{LangPayments.connectPaypalAccount[lang]}</SecondaryButton>
                </div>

                Coming soon

                <Divider/>

                <Header as='h3'> <Icon name='university' size={'mini'}/> {LangPayments.bankTransfer[lang].toUpperCase()}
                </Header>
                <p><b>Unicredit S.p.A</b></p>
                <p>IBAN: IT19E0200801612000105161025</p>
                <p>BIC/SWIFT: UNCRITM1212</p>
                <div dangerouslySetInnerHTML={{__html: LangPayments.bankTransferDetails[lang]}}/>


                <Modal
                    basic
                    onClose={() => setCardModalOpen(false)}
                    onOpen={() => setCardModalOpen(true)}
                    open={cardModalOpen}
                    size='small'
                >
                    <Header icon>
                        <Icon name='stripe card'/>
                        {LangPayments.addCreditCard[lang]}
                    </Header>
                    <Modal.Content>
                        <CardComponent setCardModalOpen={setCardModalOpen}/>
                    </Modal.Content>
                    <Modal.Actions>
                        <SecondaryButton basic color='grey' inverted onClick={() => setCardModalOpen(false)}>
                            <Icon name='remove'/> {LangPayments.addCreditCardClose[lang]}
                        </SecondaryButton>
                    </Modal.Actions>
                </Modal>

            </Segment>
        </>
    );
};

export default BillingPage;
function removePaymentMethod(removePaymentMethod: any) {
    throw new Error('Function not implemented.');
}

