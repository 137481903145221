import {Auth} from "aws-amplify";
import {sendToast} from "../../store/actions/ApplicationConfigurationActions";
import {Hub} from "@aws-amplify/core";
import {AUTH_STATE_CHANGE_EVENT, AuthState, UI_AUTH_CHANNEL} from "@aws-amplify/ui-components";

class SignUpFunctions {
    setEmailError: any;
    setPasswordError: any;
    setPhoneError: any;
    setDoingSignUp: any;
    dispatch: any;
    lang: string;

    constructor(
        setEmailError: any,
        setPasswordError: any,
        setPhoneError: any,
        setDoingSignUp: any,
        dispatch: any,
        lang: string
    ) {
        console.log('Initializing Sign up [' + lang + ']');
        this.setEmailError = setEmailError;
        this.setPasswordError = setPasswordError;
        this.setPhoneError = setPhoneError;
        this.setDoingSignUp = setDoingSignUp;
        this.dispatch = dispatch;
        this.lang = lang;
    }

    public handleSignUp = (email: string, password: string, dialCode: string, phoneNumber: number) => {
        const username = email;
        const preferred_username = email;
        const phone_number = dialCode !== '' && phoneNumber ? `${dialCode}${phoneNumber}` : '';

        if (username === '') {
            this.setEmailError('Email cannot be empty');
            return;
        }

        if (password === '') {
            this.setPasswordError('Password cannot be empty');
            return;
        }

        this.setDoingSignUp(true);

        let attributes: object = {
            email,
            preferred_username,
        };
        if (phone_number !== '') {
            attributes = {
                email,
                preferred_username,
                phone_number
            };
        }

        Auth.signUp({
            username,
            password,
            attributes: attributes,
            validationData: []
        })
            .then(data =>
                this.dispatch(sendToast({
                    title: 'Success',
                    messages: ['User successfully created!'],
                    color: 'positive'
                })))
            .then(() => {
                Hub.dispatch(UI_AUTH_CHANNEL, {
                    event: AUTH_STATE_CHANGE_EVENT,
                    message: AuthState.ConfirmSignUp,
                    data: username
                });
            })
            .catch(err => {
                if (err.message === 'Invalid phone number format.') {
                    this.setPhoneError(err.message);
                } else {
                    this.dispatch(sendToast({title: 'Error', messages: [err.message], color: 'negative'}));
                }
            })
            .finally(() => {
                this.setDoingSignUp(false);
            });

    };
}

export default SignUpFunctions;
