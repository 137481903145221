import React, {useEffect, useState} from 'react';
import {Message, Transition} from "semantic-ui-react";
import {Toast} from "../interfaces/Toast";
import {useSelector} from "react-redux";
import {RootState} from "../store";

const ToastComponent: React.FC = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const toast: Toast | null = useSelector((state: RootState) => state.application.toast);

    useEffect(() => {
        if (toast !== null) {
            setVisible(true);
            setTimeout(() => {
                setVisible(false);
            }, 5500);
        }
    }, [toast]);

    return <div style={{position: 'fixed', bottom: '15px', right: '10px', zIndex: 102}}>
        <Transition visible={visible} animation='scale' duration={500}>
            <Message compact
                     className={toast?.color}
                     header={toast?.title}
                     list={toast?.messages}
                     onDismiss={() => setVisible(false)}>
            </Message>
        </Transition>
    </div>;
};

export default ToastComponent;
