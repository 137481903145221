import visa from 'payment-icons/min/flat/visa.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import amex from 'payment-icons/min/flat/amex.svg';
import jbc from 'payment-icons/min/flat/jcb.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import d from 'payment-icons/min/flat/default.svg';
import pp from 'payment-icons/min/flat/paypal.svg';


export const paymentIcons = (brand: string): string => {
    switch (brand) {
        case 'visa':
            return visa;
        case 'mastercard':
            return mastercard;
        case 'american_express':
            return amex;
        case 'jbc':
            return jbc;
        case 'discover':
            return discover;
        case 'paypal':
            return pp;
        default:
            return d;
    }
};
