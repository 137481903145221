import React, {Dispatch, useEffect} from 'react';
import {Menu, Header, Icon, Sidebar} from 'semantic-ui-react';
import SideBarItem from "./SidebarItem";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import './Sidebar.css';
import {LangMenu} from "../lang/Menu";
import {toggleVerticalSidebar} from "../store/actions/ApplicationConfigurationActions";
import styled from "styled-components";

const SideBar: React.FC<any & { className?: string }> = ({className, children}) => {
    const dispatch: Dispatch<any> = useDispatch();
    const visible: boolean = useSelector((state: RootState) => state.application.displayVerticalSidebar);
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);

    useEffect(() => {
        if (window.innerWidth <= 1080) {
            dispatch(toggleVerticalSidebar());
        }

        /*window.matchMedia("(max-width: 768px)").addEventListener('change', (e: any) => {
            if(e.matches){
                console.log('listener');
                dispatch(toggleVerticalSidebar());
            } else {

            }
        });*/
    }, []);

    return (
        <Menu borderless vertical stackable fixed='left' className={className + (visible ? ' visible' : ' hidden')}>


            <Header as='h2' style={{marginLeft: '10px'}} id={'side-logo'}
                    image={'./assets/icon/logo.png'} content={'BECOME'} subheader={'Platform'}/>


            <SideBarItem highlight={location.pathname === '/'} label='Dashboard' icon='home' url={'/'}/>
            <SideBarItem highlight={location.pathname === '/user/profile'} label={LangMenu.profile[lang]} icon='cogs'
                         url={'/settings'}/>
            <SideBarItem highlight={location.pathname === '/security'} label={LangMenu.security[lang]} icon='shield'
                         url={'/security'}/>
            <SideBarItem highlight={location.pathname === '/billing'} label={LangMenu.payments[lang]} icon='credit card'
                         url={'/billing'}/>
            <SideBarItem highlight={location.pathname === '/subscriptions'} label={LangMenu.subscriptions[lang]} icon='credit card outline'
                         url={'/subscriptions'}/>
            <SideBarItem highlight={location.pathname === '/courses'} label={LangMenu.courses[lang]}
                         icon='graduation cap' url={'/courses'}/>
            <SideBarItem highlight={location.pathname === '/certificates'} label={LangMenu.certificates[lang]}
                         icon='certificate' url={'/certificates'}/>
            <SideBarItem highlight={location.pathname === '/applications'} label={LangMenu.applications[lang]}
                         icon='grid layout' url={'/applications'}/>
            <SideBarItem highlight={location.pathname === '/devices'} label={LangMenu.devices[lang]} icon='tablet'
                         url={'/devices'}/>

        </Menu>
    );
};


export const StyledSideBar = styled(SideBar)`
  top: 84px !important;
  overflow-y: auto !important;
  padding-bottom: 64px !important;
  padding-top: 10px !important;
  transition: 1s ease all;
  box-shadow: none !important;
  border: 0 !important;
  min-width: 300px;
  padding-left: 50px !important;

  &.hidden {
    left: -300px !important;
  }
  &.visible {
    left: 0 !important;
  }

  @media (min-width: 780px) and (max-width: 1750px) {
    min-width: 200px;
    padding-left: 0!important;
   
  }

  @media (min-width: 1750px) {
    #side-logo {
      display: none !important;
    }
  }

`;
