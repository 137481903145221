import React, {Dispatch, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {useParams} from "react-router";
import {Link, useHistory} from "react-router-dom";
import {
    Breadcrumb,
    Card,
    Checkbox,
    Dimmer,
    Grid,
    Header,
    Image, List,
    Loader,
    Segment
} from "semantic-ui-react";

import {Application} from "../../interfaces/Application";
import {LangApplications} from "../../lang/Applications";
import ModalCheckout from "../../components/ModalCheckout";
import {applicationsSelector, fetchApplications} from "../../store/slices/ApplicationSlice";
import {fetchUserApplications, userApplicationsSelector} from "../../store/slices/UserApplicationSlice";
import {SecondaryButton} from "../../components/Buttons/SecondaryButton";
import {ApplicationMiddleware} from "../../middleware/Application";
import {fetchUserCourses, userCoursesSelector} from "../../store/slices/UserCourseSlice";


const SubscribeToApplication: React.FC = () => {

    const history = useHistory();
    const dispatch: Dispatch<any> = useDispatch();
    const {applicationSlug} = useParams<{ applicationSlug: string; }>();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);

    const {applications, retrievingApplications, applicationsFetched} = useSelector(applicationsSelector);
    const {
        userApplications,
        retrievingUserApplications,
        userApplicationsFetched
    } = useSelector(userApplicationsSelector);
    const {userCourses, retrievingUserCourses, userCoursesFetched} = useSelector(userCoursesSelector);


    // MODALS
    const [open, setOpen] = useState<boolean>(false);
    const [selectedPlan, setSelectedPlan] = useState<string>('professional');
    const [realCost, setRealCost] = useState<number>();
    const [application, setApplication] = useState<Application>();





    useEffect(() => {
        if (!userApplicationsFetched) {
            dispatch(fetchUserApplications());
        }
    }, []);

    useEffect(() => {
        if (!applicationsFetched.includes(lang)) {
            console.log('calling fetchApplications');
            dispatch(fetchApplications(lang));
        }
        if (!userCoursesFetched.includes(lang)) {
            console.log('calling fetchUserCourses');
            dispatch(fetchUserCourses(lang));
        }
    }, [lang]);

    useEffect(() => {
        if (applications[lang] && applications[lang].length > 0) {
            const findApplication = applications[lang].find((a: Application) => a.slug === applicationSlug);
            if (!findApplication) {
                history.push('/applications');
            }
            window.document.title = findApplication.name + ' subscribe | BECOME';
            setApplication(findApplication);
        }
    }, [applications]);


    useEffect(() => {
        if (selectedPlan !== '' && application) {
            const plan = application.prices.find((ap: { price_id: string, type: string, cost: number }) => ap.type === selectedPlan);
            setRealCost(plan.cost / 100);
        }
    }, [selectedPlan, application]);


    return (
        <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
            <Breadcrumb>
                <Breadcrumb.Section as={Link}
                                    to='/applications'>{LangApplications.title[lang].toUpperCase()}</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section active>{application?.name}</Breadcrumb.Section>
            </Breadcrumb>
            <Dimmer inverted
                    className={retrievingUserApplications || retrievingApplications || retrievingUserCourses ? 'active' : ''}
                    inline='centered'>
                <Loader content='Loading'/>
            </Dimmer>
            <Grid style={{marginTop: '0'}}>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={12} style={{marginBottom: '25px'}}>
                        <h1>{application?.name}</h1>
                        <Card.Group>
                            {application?.prices.map((p: { type: string, price_id: string, cost: number }, index: number) => {
                                let features = [];
                                if (application?.features) {
                                    features = JSON.parse(application?.features);
                                }
                                return <Card key={'card-price-' + index} onClick={() => setSelectedPlan(p.type)}
                                             style={{padding: '0.5em', color: 'black'}}>

                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Checkbox radio style={{width: '15px', marginLeft: '1px', marginRight: '15px'}}
                                                  checked={selectedPlan === p.type}/>
                                        <Card.Content style={{flex: 1, width: 'auto'}}>
                                            <Card.Header>{p.type.toUpperCase()}</Card.Header>
                                            <Card.Meta>€ {p.cost / 100}/Year</Card.Meta>
                                        </Card.Content>
                                    </div>
                                    {features[p.type] && features[p.type].length > 0 ?
                                        <Card.Description style={{marginLeft: '5px', marginTop: '15px'}}>
                                            <List as='ul'>
                                                {
                                                    features[p.type].map((f, index) => {
                                                        return <List.Item key={'list-' + index} as='li'>{f}</List.Item>;
                                                    })
                                                }
                                            </List>
                                        </Card.Description> : <></>}
                                </Card>;
                            })}
                        </Card.Group>
                        <br/>
                        <div dangerouslySetInnerHTML={{__html: application?.description}}/>
                        {/* <Testimonial type={'application'} id={application?.id}/> */}
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={16} computer={4}>
                        <Card>
                            <Image src={application?.icon}/>
                            <Card.Content>
                                <Header as='h1' size='huge'>
                                    € {Math.round(realCost / 12)}/{LangApplications.month[lang]} + <b>IVA</b>
                                    <Header.Subheader>€ {realCost}/{LangApplications.year[lang]} + <b>IVA</b></Header.Subheader>
                                </Header>
                                <SecondaryButton fluid
                                                 onClick={() => setOpen(true)}>{LangApplications.subscribe[lang].toUpperCase()}</SecondaryButton>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>


            <ModalCheckout
                is_subscription={true}
                header={application?.name}
                image={application?.icon}
                open={open}
                setOpen={setOpen}
                realCost={realCost}
                setRealCost={setRealCost}
                cost={application?.prices.find((ap: { price_id: string, type: string, cost: number }) => ap.type === selectedPlan)?.cost}
                price_id={application?.prices.find((ap: { price_id: string, type: string, cost: number }) => ap.type === selectedPlan)?.price_id}/>
        </Segment>
    );
};

export default SubscribeToApplication;
