import {
    ApplicationState,
    SET_COURSES,
    SET_LANG,
} from "../types/applicationTypes";
import {createReducer} from "@reduxjs/toolkit";


const initialState: ApplicationState = {
    courses: [],
    userCourses: [],
    lang: 'en'
};


export const applicationReducer = createReducer(initialState, (builder => {
    builder
        .addCase(SET_COURSES, (state: ApplicationState, action: any) => {
            state.courses = action.payload;
        })
        .addCase(SET_LANG, (state: ApplicationState, action: any) => {
            state.lang = action.payload;
        });
}));

