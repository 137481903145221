import {Button, Grid, Header, Item, Label, Image} from "semantic-ui-react";
import React, {Dispatch, forwardRef, useEffect, useState} from "react";
import {Application} from "../../interfaces/Application";
import {Storage} from "aws-amplify";
import {Video} from "../../interfaces/Video";
import {ApplicationService} from "../../services/applications/ApplicationService";
import {sendToast} from "../../store/actions/ApplicationConfigurationActions";
import {useDispatch} from "react-redux";

const ApplicationVideoPage = forwardRef((props: { application: Application }, ref) => {

    const dispatch: Dispatch<any> = useDispatch();
    const [videos, setVideos] = useState<any[]>([]);
    const [applicationVideos, setApplicationVideos] = useState<Video[]>([]);
    const [downloadingVideo, setDownloadingVideo] = useState<any[]>([]);

    useEffect(() => {
        console.log('retrieve application', +props.application.id);
        Storage.list('/' + props.application.id, {
            customPrefix: {
                public: 'application-download',
            },
            level: 'public'
        }) // for listing ALL files without prefix, pass '' instead
            .then(result => {
                const downloadingVideoArray = [];
                for (let j = 0; j < result.length; j++) {
                    const ck = result[j].key.replace('/' + props.application.id + '/', '');
                    if (ck !== '') {
                        downloadingVideoArray.push({key: ck, value: false});
                    }
                }
                setDownloadingVideo(downloadingVideoArray);
                setVideos(result);
            })
            .catch(err => console.log(err));

        ApplicationService.listVideos().then(r => {
            console.log('application videos', r);
            setApplicationVideos(r);
        });
    }, []);


    const downloadVideo = (key: string) => {
        const oldVideos = [...downloadingVideo];
        for (let k = 0; k < oldVideos.length; k++) {
            if (oldVideos[k].key === key) {
                oldVideos[k].value = true;
            }
        }
        setDownloadingVideo(oldVideos);

        ApplicationService.downloadApplicationVideo(props.application.id, key)
            .then(res => {
                window.open(res, '_blank');
            }).catch(error => {
            dispatch(sendToast({
                title: 'Error',
                messages: ['Non sei autorizzato a scaricare queste risorse'],
                color: 'negative'
            }));
        });


        setTimeout(() => {
            const ov = [...downloadingVideo];
            for (let k = 0; k < ov.length; k++) {
                if (ov[k].key === key) {
                    ov[k].value = false;
                }
            }
            setDownloadingVideo(ov);
        }, 2000);
    };


    return <>
        <Grid>

            <Grid.Row style={{padding: '7em 2em'}}>
                <Grid.Column mobile={16} tablet={16} computer={12}>
                    <Header as={'h1'} style={{fontSize: '3em', fontWeight: 400}}>Versione per <b
                        style={{color: 'black'}}>Oculus GO</b>.</Header>
                    <p style={{fontSize: '1.5em'}}>Gli utenti GO possono scaricare le esperienze immersive in questa
                        sezione cliccando sul pulsante "download".</p>
                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={4}>
                    <Image rounded size={"small"} style={{margin: 'auto'}}
                           src={'./assets/images/oculus-quest.png'}/>
                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={16} style={{marginTop: '2em'}}>
                    <Item.Group relaxed>
                        {videos.filter((v) => v.key.replace('/' + props.application.id + '/', '') !== '').map((video: any, index: number) => {
                            const clearedKey = video.key.replace('/' + props.application.id + '/', '');
                            const v = applicationVideos.find((av: Video) => av.key === clearedKey);

                            const isLoading = downloadingVideo.find(dv => dv.key === clearedKey).value;

                            return <Item key={'video-' + index}>
                                <Item.Image size='small' src={v?.image}/>
                                <Item.Content verticalAlign='middle'>
                                    <Item.Header
                                        as='a'>{v?.title}</Item.Header>
                                    <Item.Extra>
                                        <Button
                                            disabled={isLoading}
                                            className={isLoading ? 'loading' : ''}
                                            onClick={() => downloadVideo(clearedKey)} floated='right'>Download</Button>
                                    </Item.Extra>
                                </Item.Content>
                            </Item>;
                        })}
                    </Item.Group>
                </Grid.Column>
            </Grid.Row>


            <Grid.Row style={{backgroundColor: 'rgba(0,0,0,.87)', color: '#ececec', padding: '7em 2em'}}>
                <Grid.Column mobile={16} tablet={16} computer={4}>
                    <Image rounded size={'small'} style={{margin: 'auto'}}
                           src={'./assets/images/oculusquest2-render.png'}/>
                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={12}>
                    <Header as={'h1'} style={{fontSize: '3em', fontWeight: 400, color: '#ececec'}}>Versione per <b
                        style={{color: 'white'}}>Oculus Quest / Quest 2</b>.</Header>
                    <p style={{fontSize: '1.5em'}}>Istruzioni per l'installazione:</p>
                    <div>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/vo8QkMQjCYc"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div>
                    <p style={{fontSize: '1.5em', marginTop: '1em'}}>Link per scaricare l'applicazione:</p>
                    <a href='' target='_blank'  style={{fontSize: '1.5em'}}>Download file .apk</a>
                </Grid.Column>
            </Grid.Row>

            {/*
            <Grid.Row style={{padding: '7em 2em'}}>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <Header as={'h1'} style={{fontSize: '3em', fontWeight: 400}}><b
                        style={{color: 'black'}}>Streaming</b> realtime su tutti i dispositivi</Header>
                    <div style={{fontSize: '1.5em'}}>Questa modalità è attualmente in fase di <Label color='orange'
                                                                                                   tag>sperimentazione</Label>
                    </div>
                    <p style={{fontSize: '1.5em'}}>Requisiti minimi connessione: <b>20 Mbps</b></p>
                    <p>Per testare la tua velocità premi qui <a target={'_blank'} href={'https://fast.com/'}>https://fast.com/</a></p>
                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={8}>

                </Grid.Column>
            </Grid.Row>
*/}

        </Grid>
    </>;
});
export default ApplicationVideoPage;
