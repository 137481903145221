import React, {Dispatch, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setCurrentCourses} from "../../store/actions/applicationActions";
import {Course} from "../../interfaces/Course";
import {
    Breadcrumb,
    Card,
    Dimmer,
    Grid, Header,
    Image,
    Loader,
    Segment,
} from "semantic-ui-react";

import CourseService from "../../services/courses/CourseService";
import {useParams} from "react-router";
import {Link, useHistory} from 'react-router-dom';

import {RootState} from "../../store";
import {UserCourse} from "../../interfaces/UserCourse";
import {LangCourses} from "../../lang/Courses";
import ModalCheckout from "../../components/ModalCheckout";
import {SecondaryButton} from "../../components/Buttons/SecondaryButton";
import {fetchUserCourses, userCoursesSelector} from "../../store/slices/UserCourseSlice";


const Courses: React.FC = () => {

    const history = useHistory();
    const {courseId} = useParams<{ courseId: string; }>();
    const dispatch: Dispatch<any> = useDispatch();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);

    // courses
    const [retrievingCourses, setRetrievingCourses] = useState<boolean>(false);
    const setCourses = React.useCallback(
        (courses: Course[]) => dispatch(setCurrentCourses(courses)),
        [dispatch]
    );
    const [realCost, setRealCost] = useState<number>();
    const {userCourses, retrievingUserCourses, userCoursesFetched} = useSelector(userCoursesSelector);


    // MODALS
    const [open, setOpen] = useState<boolean>(false);
    const [course, setCourse] = useState<Course | undefined>();


    useEffect(() => {
        if (userCourses && userCourses[lang] && userCourses[lang].length > 0 && course) {
            window.document.title = 'Checkout ' + course.name + ' | BECOME';
            setRealCost(course.cost / 100);
            const userHasCourse = userCourses[lang].find((c: UserCourse) => c.course_id === course.id);
            if (userHasCourse && userHasCourse.status) {
                switch (userHasCourse.status) {
                    case 'completed':
                        history.push('/certificates/' + course.id);
                        break;
                    case 'started':
                        history.push('/course/' + course.id);
                        break;
                    default:
                        history.push('/courses');
                }

            }
        }
    }, [userCourses, course]);

    useEffect(() => {
        setRetrievingCourses(true);
        CourseService.list(lang).then((courses: Course[]) => {
            setCourses(courses);
            setRetrievingCourses(false);
            const c = courses.find((c: Course) => c.id === parseInt(courseId));
            setCourse(c);
            setRealCost(c.cost / 100);
        });

        if (!userCoursesFetched.includes(lang)) {
            dispatch(fetchUserCourses(lang));
        }
    }, [lang]);


    return (
        <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
            <Breadcrumb>
                <Breadcrumb.Section as={Link} to='/courses'>{LangCourses.title[lang].toUpperCase()}</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section active>{course?.name}</Breadcrumb.Section>
            </Breadcrumb>
            <Dimmer inverted className={retrievingCourses || retrievingUserCourses ? 'active' : ''} inline='centered'>
                <Loader content='Loading'/>
            </Dimmer>

            <Grid style={{marginTop: '0'}}>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={12} style={{marginBottom: '25px'}}>
                        <h1>{course?.name}</h1>
                        <div dangerouslySetInnerHTML={{__html: course?.description}}/>


                        {/*<Testimonial type={'course'} id={course?.id}/>*/}

                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={4}>
                        <Card>
                            <Image src={course?.image}/>
                            <Card.Content>
                                <Header as='h1' size='huge'>€ {realCost} + <b>IVA</b></Header>

                                <SecondaryButton style={{borderRadius: 0}} className={'whiteButton'} fluid
                                                 onClick={() => setOpen(true)}>
                                    {LangCourses.buyNow[lang].toUpperCase()}
                                </SecondaryButton>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <ModalCheckout
                header={course?.name}
                image={course?.image}
                open={open}
                setOpen={setOpen}
                realCost={realCost}
                setRealCost={setRealCost}
                cost={course?.cost}
                price_id={course?.price_id}/>

        </Segment>
    );
};

export default Courses;

