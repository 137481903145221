import {Company} from "../../interfaces/Company";
import {AxiosInterceptor} from "../AxiosInterceptor";
import {AxiosInstance, AxiosResponse} from "axios";

export default class CompanyService {
    static saveCompanyDetails = async (company: Company): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/application/company/save_details';
                return ax.post(url, company).then((res: AxiosResponse) => {
                    return res.data;
                });
            }).catch(err => Promise.reject(err));
    };
}


export const getCompanyDetails = async (): Promise<any> => {
    return await AxiosInterceptor().then(
        (ax: AxiosInstance) => {
            const url = '/application/company/details';
            return ax.get(url).then((res: AxiosResponse) => {
                return res.data;
            });
        }).catch(err => Promise.reject(err));
};



