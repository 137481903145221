import React, {useEffect, useState} from 'react';
import {Form, Header, Icon, Item, Message, Modal, Radio} from "semantic-ui-react";
import {OnBoardingLang} from "../lang/OnBoarding";
import { useSelector} from "react-redux";
import {RootState} from "../store";
import {Slider} from "../components/Slider";
import {SecondaryButton} from "../components/Buttons/SecondaryButton";
import {Auth} from "aws-amplify";
import ProfessionalDetails from "./user/components/ProfessionalDetails";
import {PrimaryButton} from "../components/Buttons/PrimaryButton";
import StudentDetails from "./student/components/StudentDetails";
import SchoolDetails from "./school/components/SchoolDetails";
import CompanyDetails from "./company/components/CompanyDetails";
import {CognitoUserInterface} from "@aws-amplify/ui-components";

const OnBoarding: React.FC = () => {

    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);

    const [open, setOpen] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [userValue, setUserValue] = useState<string>('');
    const [formError, setFormError] = useState<string>('');
    const [user, setUser] = useState<CognitoUserInterface | undefined>();

    const [updatingUserAttributes, setUpdatingUserAttributes] = useState<boolean>(false);

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((user) => {
            if (!user.attributes['custom:account_type']) {
                setOpen(true);
            }
            setUser(user);
        });
    }, []);

    const submit = () => {
        if (userValue === '') {
            setFormError(OnBoardingLang.formError1[lang]);
            return;
        }
        setUpdatingUserAttributes(true);
        Auth.currentAuthenticatedUser().then((user) => {
            Auth.updateUserAttributes(user, {
                "custom:account_type": userValue
            })
                .then(r => {
                    setActiveIndex(1);
                })
                .catch(error => {
                    console.log('errormessage', error);
                    setFormError(error.message);
                })
                .finally(() => {
                    setUpdatingUserAttributes(false);
                });
        });
    };

    const users = [
        {
            name: OnBoardingLang.types.student[lang],
            url: './assets/images/school.svg',
            value: 'student'
        },
        {
            name: OnBoardingLang.types.individual[lang],
            url: './assets/images/briefcase.svg',
            value: 'individual'
        },
        {
            name: OnBoardingLang.types.school[lang],
            url: './assets/images/government-buildings.svg',
            value: 'school'
        },
        {
            name: OnBoardingLang.types.company[lang],
            url: './assets/images/building.svg',
            value: 'company'
        }
    ];


    return <Modal
        Modal
        open={open}
    >
        <Header as={'h2'} textAlign={'center'} icon>
            <Icon name='compass outline'/>
            {OnBoardingLang.header[lang]}
            <Header.Subheader size={'small'}>{OnBoardingLang.subHeader[lang]}</Header.Subheader>
        </Header>
        <Modal.Content image>
            <Modal.Description style={{textAlign: 'center'}}>
                <Slider activeIndex={activeIndex}
                        steps={[{
                            name: OnBoardingLang.label1[lang],
                            icon: 'user outline'
                        }, {name: OnBoardingLang.label2[lang], icon: 'smile outline'}]}>
                    <Form error={formError !== ''}>
                        <Item.Group>
                            {users.map((user: { name: string, url: string, value: string }, index: number) => {
                                return <Item style={{alignItems: 'center', cursor: 'pointer'}} key={'user-' + index}
                                             onClick={() => {
                                                 setUserValue(user.value);
                                                 setFormError('');
                                             }}>
                                    <Radio style={{marginRight: '15px'}} value={user.name}
                                           checked={userValue === user.value}/>
                                    <Header as={'h3'}
                                            style={{flex: 1, textAlign: 'left', margin: 0}}>{user.name}</Header>
                                    <Item.Image size='tiny' src={user.url}
                                                style={{borderRadius: '5px', padding: '3px'}}/>
                                </Item>;
                            })}
                        </Item.Group>
                        {formError !== '' &&
                        <Message negative>
                            <p>{formError}</p>
                        </Message>
                        }
                    </Form>
                    <div>
                        {userValue !== '' && <>
                            <Header as={'h4'}>{OnBoardingLang.steps[userValue].header[lang].toUpperCase()}</Header>
                            <div>
                                {userValue === 'individual' && <ProfessionalDetails user={user}/>}
                                {userValue === 'student' && <StudentDetails user={user}/>}
                                {userValue === 'school' && <SchoolDetails user={user}/>}
                                {userValue === 'company' && <CompanyDetails user={user}/>}
                            </div>
                        </>}
                    </div>
                </Slider>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            {activeIndex !== 1 ?
                <SecondaryButton
                    content={OnBoardingLang.next[lang]}
                    labelPosition='right'
                    className={updatingUserAttributes ? 'loading' : ''}
                    icon='arrow right'
                    onClick={() => submit()}
                /> :
                <>
                    <SecondaryButton
                        content={OnBoardingLang.back[lang]}
                        labelPosition='left'
                        icon='arrow left'
                        onClick={() => setActiveIndex(0)}
                    />
                    <PrimaryButton
                        content={OnBoardingLang.complete[lang]}
                        labelPosition='right'
                        icon='check'
                        onClick={() => setOpen(false)}
                    />
                </>
            }
        </Modal.Actions>
    </Modal>;
};

export default OnBoarding;
