import React, {Dispatch, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {UserDevice} from "../../interfaces/UserDevice";
import {Device} from "../../interfaces/Device";
import DeviceService from "../../services/user/DeviceService";
import {
    Button,
    Dimmer, Form,
    Header,
    Icon,
    Image, InputOnChangeData,
    List,
    Loader,
    Modal,
    Segment,
    Transition
} from "semantic-ui-react";
import {LangDevices} from "../../lang/Devices";
import {sendToast} from "../../store/actions/ApplicationConfigurationActions";
import {RootState} from "../../store";
import {SecondaryButton} from "../../components/Buttons/SecondaryButton";
import {fetchUserDevices, userDevicesSelector, addUserDevice} from "../../store/slices/UserDevicesSlice";



const DevicesPage: React.FC = () => {
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const dispatch: Dispatch<any> = useDispatch();
    const {retrievingUserDevices, userDevices, userDevicesFetched} = useSelector(userDevicesSelector);

    const addToUserDevices = React.useCallback(
        (userDevice: UserDevice) =>
        {
            dispatch(addUserDevice(userDevice));
        },
        [dispatch]
    );

    useEffect(() => {
        window.document.title = LangDevices.headTitle[lang];
    }, []);

    const [selectedDeviceTypeId, setSelectedDeviceTypeId] = useState<number>();
    const [serialNumber, setSerialNumber] = useState<string>('');
    const [devices, setDevices] = useState<Device[]>([]);
    const [cardModalOpen, setCardModalOpen] = useState<boolean>(false);

    const addDevice = () => {
        const device = devices.find((d: Device) => d.id === selectedDeviceTypeId);
        DeviceService.addUserDevice(serialNumber, selectedDeviceTypeId).then(res => {
            if (res.insertId) {
                addToUserDevices({
                    id: res.insertId,
                    user_id: '',
                    serial_number: serialNumber,
                    device_id: selectedDeviceTypeId,
                    name: device.name,
                    icon: device.icon
                });
                dispatch(sendToast({
                    title: LangDevices.success[lang],
                    messages: [LangDevices.deviceAdded[lang]],
                    color: 'positive'
                }));
            }
            setCardModalOpen(false);
        }).catch(err => {
            dispatch(sendToast({
                title: LangDevices.error[lang],
                messages: [err.message],
                color: 'negative'
            }));
        });
    };

    useEffect(() => {
        let isSubscribed = true;

        if(!userDevicesFetched){
            dispatch(fetchUserDevices());
        }
        DeviceService.listDevices().then((res: Device[]) => setDevices(res));
        return () => {
            isSubscribed = false;
        };
    }, []);

    return (
        <>
            <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
                <Header as='h1'>{LangDevices.title[lang].toUpperCase()}</Header>
                <Dimmer inverted className={retrievingUserDevices ? 'active' : ''}>
                    <Loader inverted>Loading</Loader>
                </Dimmer>

                <div style={{textAlign: "right"}}>
                    <SecondaryButton compact color={'blue'} onClick={() => setCardModalOpen(true)}>{LangDevices.add[lang]}</SecondaryButton>
                </div>

                <Transition.Group as={List} duration={200} divided verticalAlign={'middle'}>
                    {userDevices.map((d: UserDevice) => {
                        return <List.Item key={'device-' + d.id}>
                            <Image avatar src={d.icon}/>
                            <List.Content>
                                <List.Header>{d.name}</List.Header>
                                <List.Description>
                                    ID: {d.serial_number}
                                </List.Description>
                            </List.Content>
                        </List.Item>;
                    })}
                </Transition.Group>


                <Modal
                    basic
                    onClose={() => setCardModalOpen(false)}
                    onOpen={() => setCardModalOpen(true)}
                    open={cardModalOpen}
                    size='small'
                >
                    <Header icon>
                        <Icon name='laptop'/>
                        {LangDevices.add[lang]}
                    </Header>
                    <Modal.Content>

                        <Form>
                            <Form.Group widths='equal'>
                                <Form.Select
                                    fluid
                                    inverted
                                    value={selectedDeviceTypeId}
                                    onChange={(e, data: InputOnChangeData) => {
                                        if (data.value) {
                                            setSelectedDeviceTypeId(parseInt(data.value));
                                        }
                                    }}
                                    options={[
                                        {key: 'd-1', text: 'Oculus Quest', value: 1},
                                        {key: 'd-2', text: 'Oculus Quest 2', value: 2},
                                        {key: 'd-3', text: 'Oculus GO', value: 3}
                                    ]}
                                    placeholder= {LangDevices.deviceType[lang]}
                                />

                                <Form.Input fluid placeholder= {LangDevices.deviceID[lang]}
                                            inverted
                                            value={serialNumber}
                                            onChange={(e, data: InputOnChangeData) => setSerialNumber(data.value)}/>
                            </Form.Group>
                        </Form>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic color='grey' inverted onClick={() => setCardModalOpen(false)}>
                            <Icon name='remove'/> {LangDevices.close[lang]}
                        </Button>
                        <Button color='green' inverted onClick={() => addDevice()}>
                            <Icon name='plus'/> {LangDevices.Add[lang]}
                        </Button>
                    </Modal.Actions>
                </Modal>


            </Segment>
        </>
    );
};

export default DevicesPage;
