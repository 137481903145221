import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import {LangProfile} from "../lang/Profile";

import {Segment, Tab,} from "semantic-ui-react";
import UserDetailsComponent from "./user/components/UserDetailsComponent";
import {RootState} from "../store";


const Settings: React.FC = () => {

    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);

    useEffect(() => {
        window.document.title = LangProfile.documentTitle[lang];
    }, []);


    const panes = [
     {
         menuItem: LangProfile.menu.profile[lang].toUpperCase(),
         render: () => <Tab.Pane attached={false} style={{border: 0, boxShadow: 'none'}}>
             <UserDetailsComponent/>
         </Tab.Pane>,
     },
/*
     {
         menuItem: LangProfile.menu.company[lang],
         render: () => <Tab.Pane attached={false}>
             My Companies...
             Join company
             Create company
         </Tab.Pane>,
     },
     {
         menuItem: LangProfile.menu.users[lang],
         render: () => <Tab.Pane attached={false}>Tab 3 Content</Tab.Pane>,
     },
     {
         menuItem: LangProfile.menu.notifications[lang],
         render: () => <Tab.Pane attached={false}>Tab 3 Content</Tab.Pane>,
     },
     {
         menuItem: LangProfile.menu.integrations[lang],
         render: () => <Tab.Pane attached={false} style={{boxShadow: 'none', border: 'none'}}>Tab 3
             Content</Tab.Pane>,
     }
      */
    ];

    return (
        <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
            <h1>{LangProfile.title[lang].toUpperCase()}</h1>
            <Tab menu={{text: true, style: {borderBottom: '1px solid #e0e0e0', height: '60px'}}} panes={panes}/>


        </Segment>
    );
};

export default Settings;
