import {Toast} from "../../interfaces/Toast";

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
export const SEND_TOAST = 'SEND_TOAST';

export interface ApplicationConfigurationState {
    displayVerticalSidebar: boolean,
    toast: Toast | null
}

interface ToggleVerticalSidebar {
    type: typeof TOGGLE_SIDEBAR
}

interface HideVerticalSidebar {
    type: typeof HIDE_SIDEBAR
}

interface SendToast {
    type: typeof SEND_TOAST,
    payload: Toast
}


export type ApplicationConfigurationTypes = ToggleVerticalSidebar | HideVerticalSidebar | SendToast;
