import {createSlice} from '@reduxjs/toolkit';
import AugmentedFoodService from "../../applications/food/services/AugmentedFoodService";


export const initialState = {
    retrievingPatients: false,
    hasErrors: false,
    patients: [],
    patientsFetched: false
};

const patientsSlice = createSlice({
    name: 'patients',
    initialState,
    reducers: {
        getPatients: state => {
            state.retrievingPatients = true;
        },
        getPatientsSuccess: (state, {payload}) => {
            state.patients = payload;
            state.retrievingPatients = false;
            state.hasErrors = false;
            state.patientsFetched = true;
        },
        getPatientsFailure: state => {
            state.retrievingPatients = false;
            state.hasErrors = true;
        },
        addPatient: (state, {payload}) => {
            state.patients = [...state.patients, payload];
        }
    },
});

export default patientsSlice.reducer;

export const patientsSelector = state => state.patients;

export const {getPatients, getPatientsSuccess, getPatientsFailure, addPatient} = patientsSlice.actions;

export const fetchPatients = (): (dispatch) => Promise<void> => {
    console.log('fetch patients');
    return async dispatch => {
        dispatch(getPatients());
        try {
            await AugmentedFoodService.listPatients().then(data => {
                dispatch(getPatientsSuccess(data));
            });
        } catch (error) {
            dispatch(getPatientsFailure());
        }
    };
};
