import React, {Dispatch, useEffect, useState} from 'react';
import {Auth} from "aws-amplify";
import {AUTH_STATE_CHANGE_EVENT, AuthState, CognitoUserInterface, UI_AUTH_CHANNEL} from '@aws-amplify/ui-components';
import {useDispatch, useSelector} from "react-redux";
import {Hub, HubCapsule} from "@aws-amplify/core";
import {sendToast} from "../../store/actions/ApplicationConfigurationActions";
import {Form} from "semantic-ui-react";
import {SecondaryButton} from "../../components/Buttons/SecondaryButton";
import Authenticator from "../Authenticator";
import {LangConfirmSignIn} from "../../lang/ConfirmSignIn";
import {RootState} from "../../store";
import {LangConfirmSignUp} from "../../lang/ConfirmSignUp";


const ConfirmSignIn: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();

    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const [user, setUser] = useState<CognitoUserInterface>();
    const [confirmationCode, setConfirmationCode] = useState<string>('');
    const [checkingCode, setCheckingCode] = useState<boolean>(false);

    useEffect(() => {
        let isSubscribed = true;
        Hub.listen(UI_AUTH_CHANNEL, (data: HubCapsule) => {
            if (isSubscribed) {
                setUser(data.payload.data);
            }
        });
        if (!user) {
            Hub.dispatch(UI_AUTH_CHANNEL, {
                event: AUTH_STATE_CHANGE_EVENT,
                message: AuthState.SignIn,
                data: null
            });
        }
        return () => {
            isSubscribed = false;
        };
    }, []);

    const checkCode = () => {
        setCheckingCode(true);
        Auth.confirmSignIn(user, confirmationCode, 'SOFTWARE_TOKEN_MFA').then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
            dispatch(sendToast({title: 'Error', messages: [err.message], color: 'negative'}));
        }).finally(() => {
            setCheckingCode(false);
        });
    };

    return <Authenticator
        header={
            LangConfirmSignIn.header[lang]
        }
        form={
            <>
                <Form.Input
                    fluid label={'MFA confirmation code'}
                    icon='lock'
                    value={confirmationCode}
                    onChange={e => setConfirmationCode(e.target.value)}
                    iconPosition='left'
                    placeholder='Enter confirmation code'
                />

                <SecondaryButton fluid size='large' style={{padding: '14px 0'}}
                                 className={checkingCode ? 'loading' : ''}
                                 disabled={checkingCode || confirmationCode === ''} onClick={() => checkCode()}>
                    {LangConfirmSignIn.button[lang]}
                </SecondaryButton>
            </>
        }
        links={[
            {message: AuthState.SignIn, label: LangConfirmSignUp.backSignIn[lang]}
        ]}/>;
};

export default ConfirmSignIn;
