import React, {Dispatch, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {setCurrentCourses} from "../../store/actions/applicationActions";
import {Course} from "../../interfaces/Course";
import {useParams} from "react-router";
import CourseService from "../../services/courses/CourseService";
import {UserCourse} from "../../interfaces/UserCourse";
import {Link, useHistory} from "react-router-dom";
import {
    Breadcrumb,
    Button,
    Dimmer, Divider,
    Header,
    Icon,
    Item,
    Loader,
    Segment,
} from "semantic-ui-react";

import {sendToast} from "../../store/actions/ApplicationConfigurationActions";
import {UserCourseStep} from "../../interfaces/UserCourseStep";
import {LangCertificatePage} from "../../lang/CertificatePage";
import {SecondaryButton} from "../../components/Buttons/SecondaryButton";
import {fetchUserCourses, userCoursesSelector} from "../../store/slices/UserCourseSlice";


const CertificatePage: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();

    const {courseId} = useParams<{ courseId: string; }>();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);

    const [retrievingCourses, setRetrievingCourses] = useState<boolean>(false);
    const setCourses = React.useCallback(
        (courses: Course[]) => dispatch(setCurrentCourses(courses)),
        [dispatch]
    );


    const {userCourses, retrievingUserCourses, userCoursesFetched} = useSelector(userCoursesSelector);
    const [currentUserCourse, setCurrentUserCourse] = useState<UserCourse>();


    const [retrievingUserStep, setRetrievingUserStep] = useState<boolean>(true);
    const [userCourseStep, setUserCourseStep] = useState<UserCourseStep>();
    const [downloading, setDownloading] = useState<number>(-1);

    const [course, setCourse] = useState<Course>();
    const history = useHistory();

    const [retrievingMaterials, setRetrievingMaterials] = useState<boolean>(true);
    const [pdfList, setPdfList] = useState([]);

    useEffect(() => {
        let isSubscribed = true;

        CourseService.userCourseStep(parseInt(courseId)).then((res: UserCourseStep) => {
            if (isSubscribed) {
                setUserCourseStep(res);
            }
        }).catch(err => {
            dispatch(sendToast({title: 'Error', messages: [err.message], color: 'negative'}));
        }).finally(() => {
            setRetrievingUserStep(false);
        });

        return () => {
            isSubscribed = false;
        };
    }, []);


    useEffect(() => {
        let isSubscribed = true;
        setRetrievingCourses(true);

        CourseService.list(lang).then((courses: Course[]) => {
            if (isSubscribed) {
                setCourses(courses);
                setRetrievingCourses(false);
                setCourse(courses.find((c: Course) => c.id === parseInt(courseId)));
            }
        });

        if (!userCoursesFetched.includes(lang)) {
            dispatch(fetchUserCourses(lang));
        }

        return () => {
            isSubscribed = false;
        };
    }, [lang]);


    /****** MIDDLEWARE *******/
    useEffect(() => {
        if (userCourses && userCourses[lang] && userCourses[lang].length > 0) {
            const userHasCourse = userCourses[lang].find((c: UserCourse) => c.course_id === parseInt(courseId));
            setCurrentUserCourse(userHasCourse);
            if (!userHasCourse || userHasCourse.status !== 'completed') {
                history.push('/course/' + courseId);
            }
        }
    }, [userCourses]);

    useEffect(() => {
        if (course) {
            window.document.title = course.name + ' materials | BECOME';
            CourseService.listCourseMaterials(course.id).then(res => {
                setPdfList(res);
            }).finally(() => {
                setRetrievingMaterials(false);
            });
        }
    }, [course]);

    return (
        <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
            <Breadcrumb>
                <Breadcrumb.Section as={Link} to='/certificates' style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    color: '#6d6d6d'
                }}>{LangCertificatePage.header[lang]}</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section active style={{
                    color: '#6d6d6d'
                }}>{course?.name}</Breadcrumb.Section>
            </Breadcrumb>
            <Dimmer inverted
                    className={retrievingCourses || retrievingUserCourses || retrievingUserStep ? 'active' : ''}
                    inline='centered'>
                <Loader content='Loading'/>
            </Dimmer>
            <Header as={'h1'}>
                {course?.name}
                <Header.Subheader>{LangCertificatePage.header[lang]}</Header.Subheader>
            </Header>

            <div>
                <Header as={'h2'}>Materiali</Header>
                <Dimmer inverted
                        className={retrievingMaterials ? 'active' : ''}
                        inline='centered'>
                    <Loader content='Loading'/>
                </Dimmer>
                <Item.Group relaxed>
                    {pdfList.filter((e) => e.Key.indexOf('.pdf') > 0).map((e, index: number) => {
                        return <Item key={'pdf-' + index} style={{alignItems: 'center'}}>
                            <Item.Content style={{display: 'flex', alignItems: 'center'}}>
                                <Icon name={'file pdf'} size={'big'} color={"red"}/>
                                <Item.Header as='a'
                                             style={{flex: 1}}>{e.Key.replace('public/' + courseId + '/', '')}</Item.Header>
                                <SecondaryButton onClick={() => {
                                    setDownloading(index);
                                    CourseService.getObject(course!.id, e.Key).then((res: any) => {
                                        window.open(res);
                                    }).finally(() => setDownloading(-1));
                                }}  compact
                                        size={'mini'}
                                        className={'whiteButton '+(downloading === index ? 'loading' : '')}
                                        floated='right'>Download</SecondaryButton>
                            </Item.Content>
                        </Item>;
                    })}
                </Item.Group>
            </div>

        </Segment>
    );
};

export default CertificatePage;
