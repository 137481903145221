export const LangMenu = {
    profile: {
        it: 'Impostazioni',
        en: 'Settings'
    },
    security: {
        it: 'Sicurezza',
        en: 'Security'
    },
    payments: {
        it: 'Pagamenti',
        en: 'Billing'
    },
    courses: {
        it: 'Corsi',
        en: 'Courses'
    },
    certificates: {
        it: 'Materiale',
        en: 'Course material'
    },
    applications: {
        it: 'Applicazioni',
        en: 'Applications'
    },
    devices: {
        it: 'Dispositivi',
        en: 'My devices'
    },
    support: {
        it: 'Supporto',
        en: 'Support'
    },
    signOut: {
        it: 'Esci',
        en: 'Sign out'
    },
    videos: {
        it: 'I miei video',
        en: 'My videos'
    },
    subscriptions: {
        it: 'Abbonamenti',
        en: 'Subscriptions'
    }
};
