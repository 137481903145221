import React, {forwardRef, useEffect, useState} from "react";
import {CourseStepQuestion} from "../../../interfaces/CourseStepQuestion";
import {Button, Checkbox,  Header, List, Progress, Transition} from "semantic-ui-react";
import {CourseStepAnswer} from "../../../interfaces/CourseStepAnswer";

const QuizPage = forwardRef((props: { questions: CourseStepQuestion[], answers: { question_id: number, answer_id: number }[], setAnswers: any }, ref) => {

    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [currentQuestion, setCurrentQuestion] = useState<CourseStepQuestion[]>([]);

    useEffect(() => {
        if (props.questions.length > 0) {
            setCurrentQuestion([props.questions[0]]);
        }
    }, []);

    const addAnswer = (q: CourseStepQuestion, a: CourseStepAnswer) => {
        const oldAnswers = [...props.answers];
        let found = false;
        for (let k = 0; k < oldAnswers.length; k++) {
            if (oldAnswers[k].question_id === q.id) {
                oldAnswers[k].answer_id = a.id;
                found = true;
                break;
            }
        }
        if (!found) {
            oldAnswers.push({question_id: q.id, answer_id: a.id});
        }
        props.setAnswers(oldAnswers);
    };

    const changeCurrentQuestion = (nextIndex: number) => {
        let validNextIndex: number = currentIndex + nextIndex;
        if (nextIndex < 0) {
            validNextIndex = Math.max(0, validNextIndex);
        } else {
            validNextIndex = Math.min(props.questions.length - 1, validNextIndex);
        }
        setCurrentIndex(validNextIndex);
        const nextQuestion = props.questions[validNextIndex];
        setCurrentQuestion([nextQuestion]);
    };

    return <div>
        <Transition.Group as={List} duration={200} animation={"slide right"} style={{minHeight: '350px'}}>
            {currentQuestion.map((q: CourseStepQuestion, index: number) => {
                return <List.Item key={'csq-' + q.id}
                                  style={{marginTop: '35px', display: currentIndex === index ? 'block' : 'none'}}>
                    <List.Icon name="question"/>
                    <List.Content>
                        {currentIndex+1}) {q.description}
                        <List style={{
                            marginTop: '1' +
                                '0px'
                        }}>
                            {q.answers.map((an: CourseStepAnswer) => {
                                return <List.Item key={'csas-' + an.id}>
                                    <Checkbox
                                        checked={props.answers.some((o: { question_id: number, answer_id: number }) => o.question_id === q.id && o.answer_id === an.id)}
                                        label={an.sentence} onClick={() => addAnswer(q, an)}/>
                                </List.Item>;
                            })}
                        </List>
                    </List.Content>
                </List.Item>;
            })}
        </Transition.Group>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Button icon='left arrow' disabled={currentIndex === 0}
                    onClick={() => changeCurrentQuestion(-1)} label='Prev Question'/>
            <Header as={'h4'}>{currentIndex + 1}/{props.questions.length}</Header>
            <Button icon='right arrow' disabled={currentIndex === props.questions.length - 1}
                    onClick={() => changeCurrentQuestion(+1)} label='Next Question'
                    labelPosition='left'/>
        </div>
        {props.questions.length > 0 ?
            <Progress percent={Math.round(100 * (currentIndex + 1) / props.questions.length)} progress/> : <></>}

    </div>;
});
export default QuizPage;
