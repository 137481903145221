import React, {Dispatch, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setCurrentCourses} from "../../store/actions/applicationActions";
import {Course} from "../../interfaces/Course";
import {LangCourses} from "../../lang/Courses";
import {Button, Dimmer, Flag, FlagNameValues, Header, Item, Loader, Segment} from "semantic-ui-react";
import {useHistory} from "react-router-dom";
import CourseService from "../../services/courses/CourseService";
import {UserCourse} from "../../interfaces/UserCourse";
import {RootState} from "../../store";
import {fetchUserCourses, userCoursesSelector} from "../../store/slices/UserCourseSlice";


const Courses: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);

    const [retrievingCourses, setRetrievingCourses] = useState<boolean>(false);
    const courses: Course[] = useSelector((state: RootState) => state.applicationReducer.courses);
    const setCourses = React.useCallback(
        (courses: Course[]) => dispatch(setCurrentCourses(courses)),
        [dispatch]
    );
    const {userCourses, retrievingUserCourses, userCoursesFetched} = useSelector(userCoursesSelector);

    const history = useHistory();

    useEffect(() => {
        window.document.title = 'Courses | BECOME';
    }, []);

    useEffect(() => {
        setRetrievingCourses(true);
        CourseService.list(lang).then((courses: Course[]) => {
            setCourses(courses);
            setRetrievingCourses(false);
        });

        if (!userCoursesFetched.includes(lang)) {
            dispatch(fetchUserCourses(lang));
        }
    }, [lang]);

    return (
        <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
            <Header as={'h1'}>{LangCourses.title[lang].toUpperCase()}</Header>
            <Dimmer inverted className={retrievingCourses || retrievingUserCourses ? 'active' : ''} inline='centered'>
                <Loader content='Loading'/>
            </Dimmer>
            <Item.Group relaxed divided>
                {userCourses && userCourses[lang] && courses.length > 0 && courses.map((course: Course) => {
                    const userHasCourse = userCourses[lang].find((c: UserCourse) => c.course_id === course.id);
                    console.log(userCourses, 'id', course.id);
                    return (
                        <Item key={'application-' + course.id} style={{opacity: course.enabled ? '1' : '0.4'}}>
                            <Item.Image size='tiny' alt={course.name + ' logo'} src={course.image}/>
                            <Item.Content verticalAlign='middle'>
                                {/*  as FlagNameValues */}
                                <Item.Header> <Flag name={lang === 'en' ? 'uk' : 'it'}/> {course.name}</Item.Header>
                                <Item.Description>
                                    {course.short_description}
                                </Item.Description>
                                <Item.Extra>
                                    {!course.enabled ?
                                        <Button size='mini' icon='unlock' color='red' label='Disabled' disabled/> :
                                        userHasCourse ?
                                            (userHasCourse.status === 'completed' ?
                                                <Button onClick={() => history.push('/certificates/' + course.id)}
                                                        size='mini' icon='check circle' color='green'
                                                        label='Completed'/>
                                                :
                                                <Button onClick={() => history.push('/course/' + course.id)}
                                                        size='mini' icon='chevron circle right' color='violet'
                                                        label='Start'/>)
                                            :
                                            <Button onClick={() => history.push('/course/' + course.id + '/checkout')}
                                                    size='mini' icon='unlock' color='blue' label='Unlock'/>}
                                </Item.Extra>
                            </Item.Content>

                        </Item>
                    );
                })}
            </Item.Group>
        </Segment>
    );
};

export default Courses;
