import React, {forwardRef, useEffect, useState} from "react";
import {Dimmer, Header, Icon, List, Loader, Modal} from "semantic-ui-react";
import {FoodResult} from "../interfaces/FoodResult";
import AugmentedFoodService from "../services/AugmentedFoodService";


const ShowFoodResult = forwardRef((props: { open: boolean, setOpen: any, foodListId: number }, ref) => {

    const [foodList, setFoodList] = useState<FoodResult[]>([]);
    const [retrievingFoodResults, setRetrievingFoodResults] = useState<boolean>(true);

    useEffect(() => {
        let isSubscribed = true;
        if (props.foodListId > 0) {
            AugmentedFoodService.getConfigurationResults(props.foodListId).then((res: FoodResult[]) => {
                if (isSubscribed) {
                    setFoodList(res);
                    setRetrievingFoodResults(false);
                }
            });
        }
        return () => {
            isSubscribed = false;
        };
    }, [props.foodListId]);

    return <Modal
        onClose={() => props.setOpen(false)}
        onOpen={() => props.setOpen(true)}
        open={props.open}
        size='small'
    >
        <Header icon>
            <Icon name='clipboard list'/>
            Results
        </Header>
        <Modal.Content>
            <Dimmer className={retrievingFoodResults ? 'active' : ''}>
                <Loader/>
            </Dimmer>
            <List>
                {foodList.map((obj: FoodResult, index: number) => {
                    return (<List.Item key={index + '-res-list'}>
                        <List.Content>
                            {obj.name}: {obj.level}
                        </List.Content>
                    </List.Item>);
                })}
            </List>

        </Modal.Content>
    </Modal>;

});
export default ShowFoodResult;
