import React, {Dispatch, useEffect, useState} from 'react';
import {Button, Dimmer, Flag, Header, Item, Loader, Segment} from "semantic-ui-react";
import CourseService from "../../services/courses/CourseService";
import {Course} from "../../interfaces/Course";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {setCurrentCourses} from "../../store/actions/applicationActions";
import {UserCourse} from "../../interfaces/UserCourse";
import {useHistory} from "react-router-dom";
import {LangCourses} from "../../lang/Courses";
import {LangCertificatePage} from "../../lang/CertificatePage";
import {fetchUserCourses, userCoursesSelector} from "../../store/slices/UserCourseSlice";

const Certificates: React.FC = () => {

    const dispatch: Dispatch<any> = useDispatch();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);

    const [retrievingCourses, setRetrievingCourses] = useState<boolean>(false);
    const courses: Course[] = useSelector((state: RootState) => state.applicationReducer.courses);
    const setCourses = React.useCallback(
        (courses: Course[]) => dispatch(setCurrentCourses(courses)),
        [dispatch]
    );
    const {userCourses, retrievingUserCourses, userCoursesFetched} = useSelector(userCoursesSelector);

    const history = useHistory();

    useEffect(() => {
        window.document.title = LangCertificatePage.documentTitle[lang];
    }, []);


    useEffect(() => {
        setRetrievingCourses(true);
        CourseService.list(lang).then((courses: Course[]) => {
            setCourses(courses);
            setRetrievingCourses(false);
        });

        if (!userCoursesFetched.includes(lang)) {
            dispatch(fetchUserCourses(lang));
        }
    }, [lang]);

    return (
        <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
            <Header as={'h1'}>{LangCertificatePage.header[lang].toUpperCase()}</Header>
            <Dimmer inverted className={retrievingCourses || retrievingUserCourses ? 'active' : ''} inline='centered'>
                <Loader content='Loading'/>
            </Dimmer>
            <Item.Group relaxed divided>
                {userCourses && userCourses[lang] && courses.length > 0 && courses.map((course: Course) => {
                    const userHasCourse = userCourses[lang].find((c: UserCourse) => c.course_id === course.id);
                    return (
                        <Item key={'application-' + course.id}
                              style={{opacity: userHasCourse && userHasCourse.status === 'completed' ? 1 : 0.35}}>
                            <Item.Image size='tiny' alt={course.name + ' logo'} src={course.image}/>
                            <Item.Content verticalAlign='middle'>
                                <Item.Header><Flag name={lang === 'en' ? 'uk' : 'it'}/> {course.name}</Item.Header>
                                <Item.Description>
                                    {course.short_description}
                                </Item.Description>
                                <Item.Extra>
                                    {userHasCourse && userHasCourse.status === 'completed' ?
                                        <Button onClick={() => history.push('/certificates/' + course.id)}
                                                size='mini' icon='unlock' color='green'
                                                label={LangCourses.buttonAccessMaterials[lang]}/>
                                        :
                                        <></>}
                                </Item.Extra>
                            </Item.Content>

                        </Item>
                    );
                })}
            </Item.Group>
        </Segment>
    );
};

export default Certificates;

/**
 {
    "Version": "2012-10-17",
    "Id": "Policy1616481797630",
    "Statement": [
        {
            "Sid": "Stmt1616481789329",
            "Effect": "Allow",
            "Principal": {
                "AWS": "arn:aws:iam::940086105727:role/amplify-becomeapp-staging-173259-authRole"
            },
            "Action": [
                "s3:GetObject",
                "s3:GetObjectAcl",
                "s3:ListBucket"
            ],
            "Resource": [
                "arn:aws:s3:::become-courses/*",
                "arn:aws:s3:::become-courses"
            ]
        }
    ]
}
 **/
