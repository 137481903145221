import React, {Dispatch, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { AuthState} from '@aws-amplify/ui-components';
import {
    Checkbox,
    Form,
    Icon, Message,
    Search, SearchProps, SearchResultData,
} from "semantic-ui-react";
import RegExpLibrary from "../../utils/validators/RegExp";
import {dialCodes} from "../../data/dialCodes";
import _ from "lodash";
import {DialCode} from "../../interfaces/DialCode";
import {RootState} from "../../store";
import {LangSignUp} from "../../lang/SignUp";
import PasswordHelper from "../components/PasswordHelper";
import {SecondaryButton} from "../../components/Buttons/SecondaryButton";
import Authenticator from "../Authenticator";
import SignUpFunctions from "../functions/SignUp";


const SignUp: React.FC = () => {

    const dispatch: Dispatch<any> = useDispatch();


    const [doingSignUp, setDoingSignUp] = useState<boolean>(false);
    const [dialCode, setDialCode] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<number | undefined>();
    const [phoneError, setPhoneError] = useState<string>('');

    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const [privacy, setPrivacy] = useState<boolean>(false);
    const [privacyError, setPrivacyError] = useState<string>('');

    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [results, setResults] = useState<any[]>([]);


    const [validLen, setValidLen] = useState<boolean>(false);
    const [validUpperCase, setValidUppercase] = useState<boolean>(false);
    const [validLowercase, setValidLowercase] = useState<boolean>(false);
    const [validDigit, setValidDigit] = useState<boolean>(false);
    const [validSpecial, setValidSpecial] = useState<boolean>(false);
    const [displayPwHelper, setDisplayPwHelper] = useState<boolean>(false);

    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const [signUpFunction, setSignUpFunction] = useState<SignUpFunctions | undefined>();

    useEffect(() => {
        const sif = new SignUpFunctions(setEmailError, setPasswordError, setPhoneError, setDoingSignUp, dispatch, lang);
        setSignUpFunction(sif);
    }, [lang]);


    const handleResultSelect = (e: any, value: SearchResultData) => {
        const result: DialCode = value.result;
        setDialCode('+' + result.value);
    };

    // @ts-ignore
    const handleSearchChange = (e: MouseEvent<HTMLElement, MouseEvent>, data: SearchProps) => {
        setDialCode(data.value);
        setIsLoading(true);

        setTimeout(() => {
            const re = new RegExp(_.escapeRegExp(data.value), 'i');
            const isMatch = (result: DialCode) => re.test(result.title);
            setIsLoading(false);
            setResults(_.filter(dialCodes, isMatch));
        }, 300);
    };


    useEffect(() => {
        if (password.length > 0) {
            setValidDigit(new RegExp(/\d/).test(password));
            setValidUppercase(new RegExp(/[A-Z]/).test(password));
            setValidLowercase(new RegExp(/[a-z]/).test(password));
            setValidSpecial(new RegExp(/\W/).test(password));
            setValidLen(password.length >= 9 && password.length <= 30);
        } else {
            setValidDigit(false);
            setValidUppercase(false);
            setValidLowercase(false);
            setValidSpecial(false);
            setValidLen(false);
        }
    }, [password]);


    return (
        <Authenticator
            header={LangSignUp.header[lang]}
            form={
                <>

                    <Form.Input fluid className={!isEmailValid || emailError !== '' ? 'error' : ''}
                                id={'email-input'}
                                onBlur={(e: any) => {
                                    if (email.length > 0) {
                                        setIsEmailValid(RegExpLibrary.email.test(e.target.value));
                                    }
                                }}
                                onFocus={() => {
                                    setIsEmailValid(true);
                                    setEmailError('');
                                }}
                                error={emailError !== '' ? emailError : false}
                                label='Email'
                                placeholder='Enter your email address'
                                value={email} onChange={e => setEmail(e.target.value)}/>


                    <div style={{position: "relative"}}>
                        <Form.Input
                            fluid className={!isPasswordValid || passwordError !== '' ? 'error' : ''}
                            icon={<Icon name='eye' link onClick={(ev) => {
                                const input = ev.target.parentNode.firstChild as HTMLInputElement;
                                input.type = input.type === 'password' ? 'text' : 'password';
                            }}/>}
                            value={password}
                            onFocus={() => {
                                setIsPasswordValid(true);
                                setDisplayPwHelper(true);
                                setPasswordError('');
                            }}
                            onBlur={() => {
                                if (password.length > 0) {
                                    setIsPasswordValid(RegExpLibrary.password.test(password));
                                }
                                setDisplayPwHelper(false);
                            }}
                            error={passwordError !== '' ? passwordError : false}
                            onChange={e => setPassword(e.target.value)}
                            autoComplete="new-password"
                            placeholder='Enter your password'
                            label='Password'
                            type='password'
                        />
                        <PasswordHelper displayPwHelper={displayPwHelper} validLen={validLen}
                                        validUpperCase={validUpperCase}
                                        validLowercase={validLowercase} validDigit={validDigit}
                                        validSpecial={validSpecial}/>
                    </div>

                    <div className='field'>
                        <label htmlFor="password-input">{LangSignUp.phone[lang]}</label>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Search
                                style={{padding: '0', width: '140px'}}
                                placeholder={'Dial code'}
                                loading={isLoading}
                                input={{icon: 'search', iconPosition: 'left'}}
                                onResultSelect={(e, value: SearchResultData) => handleResultSelect(e, value)}
                                onSearchChange={_.debounce(handleSearchChange, 500, {
                                    leading: true,
                                })}
                                results={results}
                                value={dialCode}
                            />
                            <Form.Input
                                className={phoneError !== '' ? 'error' : ''}
                                error={phoneError !== '' ? phoneError : false}

                                style={{flex: 1}}
                                id={'phoneNumberInput'}
                                value={phoneNumber}
                                onChange={e => {
                                    if (e.target.value) {
                                        setPhoneNumber(parseInt(e.target.value));
                                    } else {
                                        setPhoneNumber(undefined);
                                    }
                                }}
                                onKeyPress={(evt: any) => {
                                    if (evt.which < 48 || evt.which > 57) {
                                        evt.preventDefault();
                                    }
                                }}
                                placeholder='Enter your phone'
                            />
                        </div>
                    </div>

                    <div className='field'>
                        <Checkbox checked={privacy} onChange={(e) => {
                            setPrivacy(!privacy);
                            setPrivacyError('');
                        }}
                                  label={LangSignUp.policy[lang]}/>
                    </div>

                    <Message error>
                        {LangSignUp.requiredPolicy[lang]};
                    </Message>

                    <SecondaryButton fluid size='large' style={{padding: '14px 0'}}
                                     className={'whiteButton ' + (doingSignUp ? 'loading' : '')}
                                     disabled={doingSignUp}
                                     onClick={() => {
                                         if (!isPasswordValid) {
                                             setPasswordError('Invalid password');
                                             return;
                                         }
                                         if (!privacy) {
                                             setPrivacyError(LangSignUp.requiredPolicy[lang]);
                                             return;
                                         }
                                         // check privacy
                                         signUpFunction.handleSignUp(email, password, dialCode, phoneNumber);
                                     }}>
                        {LangSignUp.button[lang]}
                    </SecondaryButton>
                </>
            }
            formStatus={{error: privacyError !== ''}}

            links={[
                {
                    message: AuthState.SignIn,
                    label: LangSignUp.haveAnAccount[lang]
                }
            ]
            }
        />
    );
};

export default SignUp;
