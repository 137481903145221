import styled from "styled-components";
import {Icon, Segment, SemanticICONS, Step} from "semantic-ui-react";
import React from "react";

const Child = ({className, children}) => (
    <div className={className}>
        {children}
    </div>
);
const StyledChild = styled(Child)`
  width: 100%;
  flex: 0 0 100%;
`;

interface SliderComponentInterface {
    className: any,
    children: any,
    activeIndex: number,
    steps?: { icon: SemanticICONS, name: string }[]
}

const SliderComponent = ({className, children, activeIndex, steps = []}: SliderComponentInterface) => (
    <>
        <Step.Group style={{width: '100%'}} size={'mini'}>
            {steps.map((step: { icon: SemanticICONS, name: string }, index: number) => {
                return <Step completed={activeIndex > index} active={activeIndex === index} key={'slider-' + index}>
                    <Icon name={step.icon}/>
                    <Step.Content>
                        <Step.Title>{step.name}</Step.Title>
                    </Step.Content>
                </Step>;
            })}
        </Step.Group>
        <Segment style={{marginTop: '-5px'}}>
            <div style={{overflow: 'hidden'}}>
                <div className={className} style={{transform: `translate3d(${(activeIndex * -100)}%, 0, 0`}}>
                    {children.map((child, index) => {
                        return <StyledChild key={'child-' + index} index={index} activeIndex={activeIndex}>
                            {child}
                        </StyledChild>;
                    })}
                </div>
            </div>
        </Segment>
    </>
);

export const Slider = styled(SliderComponent)`
  display: flex;
  transition: 0.66s ease all;
  width: 100%;
`;

