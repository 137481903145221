import {createSlice} from '@reduxjs/toolkit';
import DeviceService from "../../services/user/DeviceService";

export const initialState = {
    retrievingUserDevices: false,
    hasErrors: false,
    userDevices: [],
    userDevicesFetched: false
};

// A slice for recipes with our 3 reducers
const userDevicesSlice = createSlice({
    name: 'userDevices',
    initialState,
    reducers: {
        getUserDevices: state => {
            state.retrievingUserDevices = true;
        },
        getUserDevicesSuccess: (state, {payload}) => {
            state.userDevices = payload;
            state.retrievingUserDevices = false;
            state.hasErrors = false;
            state.userDevicesFetched = true;
        },
        getUserDevicesFailure: state => {
            state.retrievingUserDevices = false;
            state.hasErrors = true;
        },
        addUserDevice: (state, {payload}) => {
            console.log('payload', payload);
            state.userDevices = [...state.userDevices, payload];
        }
    },
});

// The reducer
export default userDevicesSlice.reducer;

// Selectors
export const userDevicesSelector = state => state.userDevices;
// Actions
export const {getUserDevices, getUserDevicesSuccess, getUserDevicesFailure, addUserDevice} = userDevicesSlice.actions;

// Asynchronous thunk action
export const fetchUserDevices = (): (dispatch) => Promise<void> => {
    console.log('fetch users devices');
    return async dispatch => {
        dispatch(getUserDevices());
        try {
            await DeviceService.listUserDevices().then(data => {
                dispatch(getUserDevicesSuccess(data));
            });
        } catch (error) {
            dispatch(getUserDevicesFailure());
        }
    };
};
