import styled from "styled-components";
import React from "react";
import {ItemGroup} from "semantic-ui-react";


export const StyledResponsiveItemGroup = styled(ItemGroup)`

  display: flex;
  flex-direction: row;

  @media (max-width: 1080px) {
    flex-direction: column;
  }

`;
