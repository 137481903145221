import {Table} from "semantic-ui-react";
import React from "react";

export const LangSubscriptions = {
    header: {
        it: 'Abbonamenti',
        en: 'Subscriptions'
    },
    application: {
        it: 'applicazione',
        en: 'application'
    },
    status: {
        it: 'status',
        en: 'status'
    },
    priceY: {
        it: 'Costo annuale',
        en: 'Annual price'
    },
    expiration: {
        it: 'Scadenza',
        en: 'expiration'
    },
    quantity: {
        it: 'Quantità',
        en: 'quantity'
    },
    actions: {
        it: 'Azioni',
        en: 'actions'
    },
    cancel: {
        it: 'Annulla rinnovo automatico',
        en: 'Disable auto-renew'
    }
};
