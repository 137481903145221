import {
    Link
} from "react-router-dom";
import React, {Dispatch, useEffect, useState} from 'react';
import {Auth} from "aws-amplify";
import 'react-circular-progressbar/dist/styles.css';
import {Subscription} from "../../interfaces/Subscription";
import {useDispatch, useSelector} from "react-redux";

import {
    Segment,
    Image,
    Card,
    List,
    Icon,
    Dimmer, Loader, Item, Header
} from "semantic-ui-react";
import {LangDashboard} from "../../lang/Dashboard";
import {RootState} from "../../store";
import {UserDevice} from "../../interfaces/UserDevice";
import StripeService from "../../services/user/StripeService";
import {applicationsSelector, fetchApplications} from "../../store/slices/ApplicationSlice";
import {Application} from "../../interfaces/Application";
import {StyledResponsiveCardGroup} from "../../components/ResponsiveCardGroup";
import {StyledRightBar} from "./components/RightBar";
import {CognitoUserInterface} from "@aws-amplify/ui-components";
import {fetchUserDevices, userDevicesSelector} from "../../store/slices/UserDevicesSlice";
import {monthNames} from "../../data/monthNames";



const Dashboard: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);

    const [user, setUser] = useState<CognitoUserInterface | undefined>();
    const [groups, setGroups] = useState<string[]>([]);
    const [percentage, setPercentage] = useState<any>(null);

    const [retrievingSubscriptions, setRetrievingSubscriptions] = useState<boolean>(true);
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

    const [retrievingBalanceTransactions, setRetrievingBalanceTransactions] = useState<boolean>(true);
    const [balanceTransactions, setBalanceTransactions] = useState([]);

    const {retrievingUserDevices, userDevices, userDevicesFetched} = useSelector(userDevicesSelector);
    const {applications, retrievingApplications, applicationsFetched} = useSelector(applicationsSelector);

    useEffect(() => {
        let isSubscribed = true;
        Auth.currentAuthenticatedUser().then(res => {
            if (res.signInUserSession && res.signInUserSession.accessToken && res.signInUserSession.accessToken.payload["cognito:groups"]) {
                setGroups(res.signInUserSession.accessToken.payload["cognito:groups"]);
            }
            setUser(res);
            const total = 0 + (res.attributes.phone_number_verified ? 33 : 0) + (res.attributes.email_verified ? 33 : 0);
            setPercentage(total);
        });

        StripeService.listUserSubscriptions().then((res: Subscription[]) => {
            if (isSubscribed) {
                setSubscriptions(res);
                setRetrievingSubscriptions(false);
            }

            StripeService.listBalanceTransactions(3).then(res => {
                if (isSubscribed) {
                    setBalanceTransactions(res);
                    setRetrievingBalanceTransactions(false);
                }
            });
        });

        if(!userDevicesFetched){
            dispatch(fetchUserDevices());
        }

        window.document.title = 'Dashboard | BECOME';
        return () => {
            isSubscribed = false;
        };
    }, []);

    useEffect(() => {
        if (!applicationsFetched.includes(lang)) {
            dispatch(fetchApplications(lang));
        }
    }, [lang]);

    return (
        <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}}>

            <StyledRightBar user={user}/>

            <div className="page-container page">
                <Header as={'h1'}>{'Dashboard'.toUpperCase()}</Header>

                <Item.Group relaxed>
                    <Item>
                        <Item.Content style={{marginLeft: '25px'}}>
                            <Item.Header style={{width: '100%'}}>
                                {LangDashboard.securityCheckup[lang]}
                                <Link to='/security' style={{
                                    color: '#6d6d6d',
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    float: 'right',
                                    fontSize: '80%'
                                }}>{LangDashboard.Security[lang]}</Link>
                            </Item.Header>
                            <Item.Description>
                                <StyledResponsiveCardGroup itemsPerRow={3} style={{marginTop: '25px'}}>
                                    <Card>
                                        <Card.Content>
                                            <div><Icon size={"big"} name={'envelope'}
                                                       color={user?.attributes.email_verified ? 'green' : 'grey'}/> {LangDashboard.emailVerified[lang]}
                                            </div>
                                        </Card.Content>
                                    </Card>
                                    <Card>
                                        <Card.Content>
                                            <Icon size={"big"} name={'mobile'}/> {LangDashboard.phoneVerified[lang]}
                                        </Card.Content>
                                    </Card>
                                    <Card>
                                        <Card.Content>
                                            <Icon size={"big"}
                                                  name={'key'}/> {LangDashboard.twoFactorAuthenticator[lang]}
                                        </Card.Content>
                                    </Card>
                                </StyledResponsiveCardGroup>
                            </Item.Description>
                        </Item.Content>
                    </Item>


                    <Item style={{marginTop: '40px'}}>
                        <Item.Content style={{marginLeft: '25px'}}>
                            <Item.Header style={{width: '100%'}}>
                                {LangDashboard.devices[lang]}
                                <Link to='/devices' style={{
                                    color: '#6d6d6d',
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    float: 'right',
                                    fontSize: '80%'
                                }}>{LangDashboard.moreDetails[lang]}</Link>
                            </Item.Header>
                            <Item.Description style={{position: 'relative'}}>
                                <Dimmer active={retrievingUserDevices} inverted>
                                    <Loader/>
                                </Dimmer>
                                <List divided verticalAlign='middle'>
                                    {userDevices.map((d: UserDevice) => {
                                        return <List.Item key={'device-' + d.id}>
                                            <Image avatar src={d.icon}/>
                                            <List.Content>
                                                <List.Header>{d.name}</List.Header>
                                                <List.Description>
                                                    ID: {d.serial_number}
                                                </List.Description>
                                            </List.Content>
                                        </List.Item>;
                                    })}
                                </List>
                            </Item.Description>
                        </Item.Content>
                    </Item>


                    <Item style={{marginTop: '50px'}}>
                        <Item.Content style={{marginLeft: '25px'}}>
                            <Item.Header style={{width: '100%'}}>
                                {LangDashboard.subscriptions[lang]}
                                <Link to='/subscriptions' style={{
                                    color: '#6d6d6d',
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    float: 'right',
                                    fontSize: '80%'
                                }}>{LangDashboard.manageSubscriptions[lang]}</Link>
                            </Item.Header>
                            <Item.Description style={{position: 'relative'}}>
                                <Dimmer active={retrievingSubscriptions} inverted>
                                    <Loader/>
                                </Dimmer>
                                <Item.Group divided verticalAlign='middle'>
                                    {applications && applications[lang] && subscriptions.map((subscription: Subscription) => {
                                        const app = applications[lang].find((app: Application) => app.prices.filter(p => p.price_id === subscription.plan.id));
                                        const createdAt = new Date(subscription.created * 1000);
                                        return <Item key={'subscription-' + subscription.id}>
                                            <Item.Image alt={app?.name + ' logo'} src={app?.icon} size='mini'
                                                        style={{opacity: subscription.status === 'active' ? '1' : '0.5'}}/>
                                            <Item.Content>
                                                <Item.Header>{app?.name}</Item.Header>
                                                <Item.Description>{LangDashboard.expiration[lang]} {monthNames[createdAt.getMonth()]} {createdAt.getDate()}, {createdAt.getFullYear()}</Item.Description>
                                                <Item.Extra>Quantità {subscription.quantity}</Item.Extra>
                                            </Item.Content>
                                        </Item>;
                                    })}
                                </Item.Group>
                            </Item.Description>
                        </Item.Content>
                    </Item>


                    <Item style={{marginTop: '50px'}}>
                        <Item.Content style={{marginLeft: '25px'}}>
                            <Item.Header style={{width: '100%'}}>
                                {LangDashboard.transactionHistory[lang].toUpperCase()}
                            </Item.Header>
                            <Item.Description style={{position: 'relative'}}>
                                <Dimmer active={retrievingBalanceTransactions} inverted>
                                    <Loader/>
                                </Dimmer>
                                <List divided verticalAlign='middle'>
                                    {balanceTransactions.map(transaction => {
                                        const createdAt = new Date(transaction.created * 1000);
                                        return <List.Item key={'subscription-' + transaction.id}>
                                            <List.Icon
                                                name='credit card' size='large' verticalAlign='middle'
                                                color={transaction.amount_captured > 0 ? 'red' : 'green'}/>
                                            <List.Content>
                                                <List.Header>{transaction.amount_captured / 100} {transaction.currency}</List.Header>
                                                <List.Description>Date: {monthNames[createdAt.getMonth()]} {createdAt.getDate()}, {createdAt.getFullYear()}</List.Description>
                                                <List.Description>{transaction.description}</List.Description>
                                            </List.Content>
                                        </List.Item>;
                                    })}
                                </List>
                            </Item.Description>
                        </Item.Content>
                    </Item>

                </Item.Group>


                {/*<Grid.Column width={3}>
                        <Header as='h2'>
                            <Image circular style={{height: '2.5em', width: '2.5em', borderRadius: '100%', marginRight: '15px'}}
                                   src={user?.attributes.picture ?? 'https://freepikpsd.com/wp-content/uploads/2019/10/default-avatar-png-3-Transparent-Images.png'}/>
                            {user?.attributes.nickname ??
                            <span style={{fontSize: '0.8em'}}>{user?.attributes.email}</span>}
                        </Header>
                        <div>
                            {groups.map(n => {
                                return <Label color='orange' key={n} style={{marginBottom: '4px'}}>
                                    {n}
                                </Label>;
                            })}
                        </div>
                        <Divider/>
                        <div>
                            <h5 style={{marginBottom: 0}}>LEVEL</h5>
                            <p>Adventurer</p>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>
                                <Image size={'mini'}
                                       src='./assets/icon/flag_02_blue.png'/>
                                <Image size={'mini'}
                                       src='./assets/icon/flag_02_red.png'/>
                            </div>
                            <Progress indicating progress='value' value={0}/>
                            <p style={{textAlign: 'right', marginTop: '-32px', fontSize: '0.9em'}}>XP required: 300</p>
                        </div>
                        <Divider/>
                        <div>
                            <h5>QUEST LOG</h5>
                            <p>Coming soon</p>
                        </div>

                    </Grid.Column>*/}
            </div>

        </Segment>
    );
};

export default Dashboard;
