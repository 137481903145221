import React, {Dispatch, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {setCurrentCourses} from "../../store/actions/applicationActions";
import {Course} from "../../interfaces/Course";
import {useParams} from "react-router";
import CourseService from "../../services/courses/CourseService";
import {UserCourse} from "../../interfaces/UserCourse";
import {Link, useHistory, useLocation} from "react-router-dom";
import {
    Breadcrumb,
    Button,
    Card, Confirm,
    Dimmer, Grid,
    Header,
    Icon,
    Loader,
    Segment, SemanticICONS,
    Step
} from "semantic-ui-react";

import {CourseStep} from "../../interfaces/CourseStep";
import {sendToast} from "../../store/actions/ApplicationConfigurationActions";
import {UserCourseStep} from "../../interfaces/UserCourseStep";
import QuizPage from "./components/QuizPage";
import {CompleteCourseResponse} from "../../interfaces/CompleteCourseResponse";
import {LangCourses} from "../../lang/Courses";
import {LangResults} from "../../lang/Results";
import {fetchUserCourses, userCoursesSelector} from "../../store/slices/UserCourseSlice";

const CoursePage: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();

    const {courseId} = useParams<{ courseId: string; }>();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);

    const [retrievingCourses, setRetrievingCourses] = useState<boolean>(false);
    const setCourses = React.useCallback(
        (courses: Course[]) => dispatch(setCurrentCourses(courses)),
        [dispatch]
    );

    useEffect(() => {
        window.document.title = LangCourses.headTitle[lang];
    }, []);


    const {userCourses, retrievingUserCourses, userCoursesFetched} = useSelector(userCoursesSelector);
    const [currentUserCourse, setCurrentUserCourse] = useState<UserCourse>();


    const [retrievingUserStep, setRetrievingUserStep] = useState<boolean>(true);
    const [userCourseStep, setUserCourseStep] = useState<UserCourseStep>();

    const [course, setCourse] = useState<Course>();
    const history = useHistory();
    const [pointer, setPointer] = useState<number>(0);
    const [timer, setTimer] = useState<number>(0);
    const [doOnce, setDoOnce] = useState<boolean>(false);
    const [answers, setAnswers] = useState<{ question_id: number, answer_id: number }[]>([]);

    const [submittingAnswers, setSubmittingAnswers] = useState<boolean>(false);
    const [confirmSubmitting, setConfirmSubmitting] = useState<boolean>(false);

    const [completeCourseResponse, setCompleteCourseResponse] = useState<CompleteCourseResponse | null>(null);
    const location = useLocation();

    useEffect(() => {
        let isSubscribed = true;

        CourseService.userCourseStep(parseInt(courseId)).then((res: UserCourseStep) => {
            if (isSubscribed) {
                setUserCourseStep(res);
            }
        }).catch(err => {
            dispatch(sendToast({title: 'Error', messages: [err.message], color: 'negative'}));
        }).finally(() => {
            setRetrievingUserStep(false);
        });

        return () => {
            isSubscribed = false;
        };
    }, []);

    let timerHandler = null;
    let timerFunction = () => {
        timerHandler = setTimeout(() => {
            setTimer(Math.max(0, timer - 1));
        }, 1000);
    };

    useEffect(() => {
        let isSubscribed = true;
        if (lang) {
            setRetrievingCourses(true);
            console.log('retrieving user courses, lang::', lang);

            CourseService.list(lang).then((courses: Course[]) => {
                if (isSubscribed) {
                    setCourses(courses);
                    setRetrievingCourses(false);
                    setCourse(courses.find((c: Course) => c.id === parseInt(courseId)));
                }
            });

            console.log('user courses fetched', userCoursesFetched, lang, userCoursesFetched.includes(lang));
            if (!userCoursesFetched.includes(lang)) {
                console.log('fetching user courses <<<<<');
                dispatch(fetchUserCourses(lang));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [lang]);

    useEffect(() => {
        if (userCourses && userCourses[lang] && userCourses[lang].length > 0) {
            setCurrentUserCourse(userCourses[lang].find((userCourse: UserCourse) => userCourse.course_id === parseInt(courseId)));
        }
    }, [userCourses]);


    useEffect(() => {
        if (timer > 0) {
            timerFunction();
        }
    }, [timer]);


    useEffect(() => {
        let isSubscribed = true;

        if (!doOnce) {
            if (course && userCourseStep && course.steps.length > 0) {
                setDoOnce(true);
                // if userCourseStep.step_id === undefined => first time that the user open this course.
                if (!userCourseStep.step_id) {
                    // we register the user course step progress
                    CourseService.userCourseNextStep(parseInt(courseId), course.steps[0].id).then((r) => {
                        if (isSubscribed) {
                            setUserCourseStep({
                                id: r.insertId,
                                step_id: course.steps[0].id,
                                course_id: course.id,
                                user_id: '',
                                started_at: Date.now()
                            });
                        }
                    }).catch(err => {
                        dispatch(sendToast({title: 'Error', messages: [err.message], color: 'negative'}));
                    });
                } else {
                    // ns is the current pointer position (course.steps)
                    const ns = course.steps.findIndex((c: CourseStep) => c.id === userCourseStep.step_id);
                    setPointer(ns);
                }
            }
        }


        return () => {
            isSubscribed = false;
        };
    }, [course, userCourseStep]);


    /****** MIDDLEWARE *******/
    useEffect(() => {
        if (userCourses && userCourses[lang] && userCourses[lang].length > 0) {
            const userHasCourse = userCourses[lang].find((c: UserCourse) => c.course_id === parseInt(courseId));
            if (userHasCourse && userHasCourse.status) {
                switch (userHasCourse.status) {
                    case 'completed':
                        history.push('/certificates/' + parseInt(courseId));
                        break;
                    case 'started':
                        if (location.pathname !== '/course/' + parseInt(courseId)) {
                            history.push('/course/' + parseInt(courseId));
                        }
                        break;
                    default:
                        history.push('/courses');
                }

            } else {
                console.log('redirect checkout');
                history.push('/course/' + courseId + '/checkout');
            }
        }
    }, [userCourses]);

    useEffect(() => {
        if (course) {
            window.document.title = course.name + ' | BECOME';
        }
    }, [course]);

    useEffect(() => {
        let isSubscribed = true;

        if (pointer > 0 && userCourseStep && userCourseStep.step_id) {
            const maxId = course.steps.findIndex((cs: CourseStep) => cs.id === userCourseStep.step_id);
            if (pointer > maxId) {
                const nextCS = course.steps[pointer];
                setTimer(nextCS.duration);
                CourseService.userCourseNextStep(parseInt(courseId), nextCS.id).then(r => {
                    console.log('updated', parseInt(courseId), nextCS.id);
                }).catch(err => {
                    dispatch(sendToast({title: 'Error', messages: [err.message], color: 'negative'}));
                });
            } else if (pointer === maxId) {
                const now = new Date().getTime() / 1000;
                const startedAt = new Date(userCourseStep.started_at).getTime() / 1000;
                const timeLeft = Math.round(Math.max(0, startedAt + course.steps[pointer].duration - now));
                setTimer(timeLeft);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [pointer]);

    useEffect(() => {
        let isSubscribed = true;
        console.log('retrieve video 0');
        if (course && userCourseStep && userCourseStep.step_id) {
            console.log('retrieve video 1');
            if (course.steps[pointer]?.video_url.length > 3) {
                console.log('retrieve video 2');
                CourseService.getVideo(parseInt(courseId), course?.steps[pointer]?.video_url).then(res => {
                    if (isSubscribed) {
                        document.getElementById('video-' + course.steps[pointer]?.id).setAttribute('src', res);
                    }
                });
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [pointer, course, userCourseStep]);

    const moveNext = () => {
        if (timer > 0) {
            return;
        }
        setPointer(Math.min(pointer + 1, course?.steps.length));
    };
    const movePrev = () => {
        clearTimeout(timerHandler);
        setTimer(0);
        setPointer(Math.max(pointer - 1, 0));
    };

    const stepIcon = (name: string): SemanticICONS => {
        switch (name) {
            case 'pdf':
                return "file pdf";
            case 'audio':
                return "file audio";
            case 'video':
                return "file video";
            case 'youtube':
                return "youtube play";
            case 'questions':
                return "tasks";
            case 'registered':
                return "registered";
            default:
                return "circle";
        }
    };

    const submitAnswers = async () => {
        let isSubscribed = true;
        setSubmittingAnswers(true);

        CourseService.userCourseNextStepComplete(parseInt(courseId), answers, course.steps[pointer].id, lang).then(
            (res: CompleteCourseResponse) => {
                if (isSubscribed) {
                    setPointer(-1);
                    setCompleteCourseResponse(res);
                }
            }
        ).catch(err => {
            if (isSubscribed) {
                try {
                    dispatch(sendToast({title: 'Error', messages: [err.response.data.message], color: 'negative'}));
                } catch (e) {
                    dispatch(sendToast({title: 'Error', messages: ['Error'], color: 'negative'}));
                }
            }
        }).finally(() => {
            if (isSubscribed) {
                setSubmittingAnswers(false);
            }
        });

        return () => {
            isSubscribed = false;
        };
    };

    const getContent = (cs: CourseStep) => {
        switch (cs.name) {
            case 'youtube':
                return <iframe width="100%" height="400"
                               frameBorder="0"
                               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                               allowFullScreen
                               title={cs.title}
                               src={cs.video_url}/>;
            case 'video':
                return <video width="100%" height="400" controls id={'video-' + cs.id} controlsList="nodownload">
                    <source src={''} type="video/mp4"/>
                    <source src={''} type="video/ogg"/>
                    Your browser does not support the video tag.
                </video>;

            case 'audio':
                return <audio controls>
                    <source src={cs.audio_url} type="audio/ogg"/>
                    <source src={cs.audio_url} type="audio/mpeg"/>
                    Your browser does not support the audio element.
                </audio>;
            case 'pdf':
                return <object data="your_url_to_pdf" type="application/pdf">
                    <embed src="your_url_to_pdf" type="application/pdf"/>
                </object>;
            default:
                return <QuizPage questions={cs.questions} answers={answers} setAnswers={setAnswers}/>;
        }
    };


    return (
        <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
            <Breadcrumb>
                <Breadcrumb.Section as={Link} to='/courses' style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    color: '#6d6d6d'
                }}>{LangCourses.title[lang].toUpperCase()}</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section active style={{
                    color: '#6d6d6d'
                }}>{course?.name}</Breadcrumb.Section>
            </Breadcrumb>
            <Dimmer inverted
                    className={retrievingCourses || retrievingUserCourses || retrievingUserStep ? 'active' : ''}
                    inline='centered'>
                <Loader content='Loading'/>
            </Dimmer>
            <Header as={'h1'}>
                {course?.name}
            </Header>
            <div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={6} computer={6}>
                            <div style={{
                                boxShadow: '0 0.625rem 0.875rem -0.3125rem #e0e0e0',
                                padding: '2em',
                                margin: '0 2em 0 0'
                            }}>
                                <Step.Group vertical size='mini' style={{
                                    border: 'none',
                                    width: '100%'
                                }}>
                                    {course?.steps.map((cs: CourseStep, index: number) => {
                                        return <Step key={'cs-' + cs.id} className={pointer === index ? 'active' : ''}
                                                     style={{flexWrap: 'nowrap'}}>
                                            <Icon name={pointer > index ? 'check circle' : stepIcon(cs.name)}
                                                  color={pointer > index ? 'green' : 'grey'}/>
                                            <Step.Content>
                                                <Step.Title style={{fontWeight: 'normal'}}>{cs.title}</Step.Title>
                                                <Step.Description>{cs.duration} s</Step.Description>
                                            </Step.Content>
                                        </Step>;
                                    })}
                                    <Step key={'results'} className={pointer === -1 ? 'active' : ''}>
                                        <Icon name={stepIcon('registered')}/>
                                        <Step.Content>
                                            <Step.Title
                                                style={{fontWeight: 'normal'}}>{LangResults.results[lang]}</Step.Title>
                                        </Step.Content>
                                    </Step>
                                </Step.Group>
                            </div>
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={10} computer={10}>
                            {pointer >= 0 ?
                                <Card fluid style={{border: 'none', boxShadow: 'none'}}>
                                    <Card.Content style={{border: 'none'}}>
                                        <Card.Header>
                                            <Icon name={stepIcon(course?.steps[pointer]?.name)}/>
                                            {course?.steps[pointer]?.title}
                                        </Card.Header>
                                        {course?.steps.length > 0 ? <>
                                                <Card.Description style={{marginTop: '25px'}}>
                                                    {getContent(course?.steps[pointer])}
                                                </Card.Description>
                                            </> :
                                            <Dimmer active>
                                                <Loader>Loading</Loader>
                                            </Dimmer>}
                                    </Card.Content>
                                    <Card.Content>

                                        <Card.Description textAlign={"right"}>
                                            <div>
                                                {pointer === 0 ? <></> :
                                                    <Button
                                                        style={{
                                                            border: '2px solid #000',
                                                            borderRadius: '0',
                                                            marginRight: '1em'
                                                        }}
                                                        onClick={() => movePrev()}>Prev</Button>
                                                }
                                                {course?.steps[pointer]?.name === 'questions' ?
                                                    <Button
                                                        style={{
                                                            marginRight: '1em'
                                                        }}
                                                        className={'whiteButton ' + (submittingAnswers ? 'loading' : '')}
                                                        disabled={timer > 0 || submittingAnswers}
                                                        onClick={() => setConfirmSubmitting(true)}>{timer > 0 ? timer : 'Submit'}</Button>
                                                    :
                                                    pointer === course?.steps.length - 1 ? <></> :
                                                        <Button className={'whiteButton'}
                                                                disabled={timer > 0}
                                                                onClick={() => moveNext()}>{timer > 0 ? timer : 'Next'}</Button>
                                                }
                                            </div>
                                        </Card.Description>
                                    </Card.Content>
                                </Card>
                                : <Card fluid style={{border: 'none', boxShadow: 'none'}}>
                                    <Card.Content style={{border: 'none'}}>
                                        <Card.Header>
                                            <Icon name={stepIcon('results')}/>
                                            {LangResults.results[lang]}
                                        </Card.Header>
                                        {completeCourseResponse !== null ?
                                            <Card.Description style={{marginTop: '25px'}} textAlign={'center'}>
                                                <Header as={'h3'} icon>
                                                    <Icon name={completeCourseResponse.success ? 'check circle' : 'ban'}
                                                          color={completeCourseResponse.success ? 'green' : 'red'}/>
                                                    {completeCourseResponse.message}
                                                </Header>
                                                <p>
                                                    <b>{LangResults.correctAnswers[lang]}:</b> {completeCourseResponse.total - completeCourseResponse.errors}
                                                </p>
                                                <p><b>{LangResults.errors[lang]}:</b> {completeCourseResponse.errors}
                                                </p>
                                                <p>
                                                    <b>{LangResults.success[lang]}:</b> {100 - Math.round(completeCourseResponse.percentage * 100)}%
                                                </p>
                                                <div style={{textAlign: 'center', marginTop: '35px'}}>
                                                    {completeCourseResponse.success ?
                                                        <Button className={'whiteButton'} onClick={() => {
                                                            const oldUserCourse = {...currentUserCourse};
                                                            oldUserCourse.status = 'completed';
                                                            window.location.reload();
                                                            // TODO  dispatch(putUserCourse(oldUserCourse));
                                                        }
                                                        }>{LangCourses.resultsSuccess[lang]}</Button> :
                                                        <Button className={'whiteButton'}
                                                                onClick={() => setPointer(course?.steps.length - 1)}>
                                                            {LangCourses.resultsFail[lang]}
                                                        </Button>}
                                                </div>
                                            </Card.Description> : <></>}
                                    </Card.Content>
                                </Card>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>


            </div>


            <Confirm
                open={confirmSubmitting}
                content={'Sei sicuro di voler inviare le risposte?'}
                onCancel={() => setConfirmSubmitting(false)}
                onConfirm={() => {
                    submitAnswers().then(r => {
                        setConfirmSubmitting(false);
                    });
                }}
            />
        </Segment>
    );
};

export default CoursePage;
