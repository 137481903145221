import React, {
    Dispatch,
    forwardRef,
    useEffect,
    useState
} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {sendToast} from "../../../store/actions/ApplicationConfigurationActions";
import {Form, InputOnChangeData, Item, Segment, Image, Button, Input, Divider} from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import {Auth} from "aws-amplify";
import {CognitoUserInterface} from "@aws-amplify/ui-components";
import {SemanticDatepickerProps} from "react-semantic-ui-datepickers/dist/types";
import * as dateFormat from "dateformat/lib/dateformat";
import {RootState} from "../../../store";
import {AccessLevel} from '@aws-amplify/ui-components';
import {Storage} from 'aws-amplify';
import {LangProfile} from "../../../lang/Profile";
import {SecondaryButton} from "../../../components/Buttons/SecondaryButton";
import ProfessionalDetails from "./ProfessionalDetails";


const UserDetailsComponent = forwardRef((props: {}, ref) => {
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const dispatch: Dispatch<any> = useDispatch();
    const profileInputRef = React.createRef<any>();
    const [file, setFile] = useState<File>();

    const [user, setUser] = React.useState<CognitoUserInterface>();

    const [updatingUserAttributes, setUpdatingUserAttributes] = useState<string>('');

    const [nickname, setNickname] = useState<string>('');
    const [fullName, setFullName] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [locale, setLocale] = useState<string>('');
    const [picture, setPicture] = useState<string>('');
    const [fiscalCode, setFiscalCode] = useState<string>('');
    const [birthDate, setBirthDate] = useState<Date | Date[]>(new Date);
    const [retrievingUserAttributes, setRetrievingUserAttributes] = useState<boolean>(true);


    useEffect(() => {
        let isSubscribed = true;
        Auth.currentAuthenticatedUser().then((u) => {
            if (isSubscribed) {
                setUser(u);
                if (u.attributes.nickname) {
                    setNickname(u.attributes.nickname);
                }
                if (u.attributes.given_name) {
                    setFullName(u.attributes.given_name);
                }
                if (u.attributes['custom:fiscal_code']) {
                    setFiscalCode(u.attributes['custom:fiscal_code']);
                }
                if (u.attributes.gender) {
                    setGender(u.attributes.gender);
                }
                if (u.attributes.locale) {
                    setLocale(u.attributes.locale);
                }
                if (u.attributes.picture) {
                    setPicture(u.attributes.picture);

                    Storage.get(u.attributes.picture, {level: AccessLevel.Private}).then((url: string) => {
                        const image = document.getElementById('avatar-img');
                        if (image) {
                            image.setAttribute('src', url);
                        }
                    });
                }
                if (u.attributes.birthdate) {
                    try {
                        setBirthDate(new Date(u.attributes.birthdate));
                    } catch (e) {
                        setBirthDate(new Date);
                        console.log(e);
                    }
                }
            }
        })
            .catch(error => dispatch(sendToast({title: 'Error', messages: [error.message], color: 'negative'})))
            .finally(() => {
                setTimeout(() => {
                    setRetrievingUserAttributes(false);
                }, 500);
            });

        return () => {
            isSubscribed = false;
        };
    }, []);


    const updateUserAttributes = (attributeName: string, value: string) => {
        setUpdatingUserAttributes(attributeName);
        Auth.updateUserAttributes(user, {
            [attributeName]: value
        }).then(r => {
            dispatch(sendToast({title: LangProfile.success[lang], messages: ['User updated'], color: 'positive'}));
        })
            .catch(error => {
                console.log('errormessage', error);
                dispatch(sendToast({title: LangProfile.error[lang], messages: [error.message], color: 'negative'}));
            })
            .finally(() => {
                setUpdatingUserAttributes('');
            });
    };

    return (
        <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
            <Item>
                <h3>{LangProfile.avatar[lang].toUpperCase()}</h3>
                <p>{LangProfile.avatarSubtitle[lang]}</p>
                <Image alt={'Avatar'} id='avatar-img' size={'tiny'} avatar src={''}/>
                <Input type={'file'} onChange={(e, data: InputOnChangeData) => {
                    const f = e.target.files[0];
                    setPicture(f.name);
                    setFile(f);
                    const image = document.getElementById('avatar-img');
                    if (image) {
                        var reader = new FileReader();
                        reader.onload = (e: ProgressEvent<FileReader>) => {
                            image.setAttribute('src', e.target.result as string);
                        };
                        reader.readAsDataURL(f);
                    }
                }}/>
                <input
                    ref={profileInputRef}
                    type="file"
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={(e) => {
                        setPicture(e.target.value);
                        setFile(e.target.files[0]);
                    }}
                />
                <SecondaryButton size={'mini'} style={{marginLeft: '15px'}}
                                 onClick={() => {
                                     if (file) {
                                         Storage.put(picture, file, {
                                             level: AccessLevel.Private,
                                             contentType: file.type
                                         }).then(() => {
                                             updateUserAttributes('picture', picture);
                                         });
                                     }
                                 }
                                 }>{LangProfile.saveButton[lang]}</SecondaryButton>
            </Item>

            <h2>{LangProfile.personalDetails.miao[lang].toUpperCase()}</h2>
            <Item>
                <Item.Content style={{flex: '1', marginTop: '25px'}}>
                    <Form className={retrievingUserAttributes || updatingUserAttributes !== '' ? 'loading' : ''}>
                        <Form.Group widths='equal'>

                            <Form.Input fluid label='Nickname' placeholder='Nickname' value={nickname}
                                        onBlur={() => {
                                            if (nickname && nickname.length > 0 && !retrievingUserAttributes && user.attributes.nickname !== nickname) {
                                                updateUserAttributes('nickname', nickname);
                                            }
                                        }}
                                        onChange={(e, data: InputOnChangeData) => setNickname(data.value)}/>


                            <Form.Input fluid label={LangProfile.personalDetails.fullName[lang]}
                                        placeholder={LangProfile.personalDetails.fullName[lang]} value={fullName}
                                        onBlur={() => {
                                            if (fullName && fullName.length > 0 && !retrievingUserAttributes && user.attributes.given_name !== fullName) {
                                                updateUserAttributes('given_name', fullName);
                                            }
                                        }}
                                        onChange={(e, data: InputOnChangeData) => setFullName(data.value)}/>


                            <Form.Input fluid label={LangProfile.personalDetails.fiscalCode[lang]}
                                        placeholder={LangProfile.personalDetails.fiscalCode[lang]} value={fiscalCode}
                                        onBlur={() => {
                                            if (fiscalCode && fiscalCode.length > 0 && !retrievingUserAttributes && user.attributes["custom:fiscal_code"] !== fiscalCode) {
                                                updateUserAttributes('custom:fiscal_code', fiscalCode);
                                            }
                                        }}
                                        onChange={(e, data: InputOnChangeData) => setFiscalCode(data.value)}/>

                            <Form.Select
                                fluid
                                label={LangProfile.personalDetails.gender[lang]}
                                placeholder={LangProfile.personalDetails.gender[lang]}
                                value={gender}
                                options={[
                                    {key: 'f', text: LangProfile.personalDetails.female[lang], value: 'female'},
                                    {key: 'm', text: LangProfile.personalDetails.male[lang], value: 'male'},
                                    {key: 'nb', text: LangProfile.personalDetails.nonbinary[lang], value: 'non_binary'},
                                    {
                                        key: 'gf',
                                        text: LangProfile.personalDetails.genderfluid[lang],
                                        value: 'gender_fluid'
                                    },
                                    {key: 'ag', text: LangProfile.personalDetails.agender[lang], value: 'agender'},
                                    {key: 'bg', text: LangProfile.personalDetails.bigender[lang], value: 'bigender'},
                                    {key: 'o', text: LangProfile.personalDetails.other[lang], value: 'other'},
                                ]}
                                onChange={(e, data: InputOnChangeData) => {
                                    setGender(data.value);
                                    if (data.value.length > 2 && !retrievingUserAttributes && user.attributes.gender !== data.value) {
                                        updateUserAttributes('gender', data.value);
                                    }
                                }}
                            />

                        </Form.Group>

                        <Form.Group widths='equal'>

                            <SemanticDatepicker
                                label={LangProfile.personalDetails.birthdate[lang]} value={birthDate}
                                format={'MM/DD/YYYY'}
                                onChange={(e, data: SemanticDatepickerProps) => {
                                    setBirthDate(data.value);
                                    const formattedBirthDate = dateFormat(data.value, 'mm/dd/yyyy');
                                    if (!retrievingUserAttributes && user.attributes.birthdate !== formattedBirthDate) {
                                        updateUserAttributes('birthdate', formattedBirthDate);
                                    }
                                }}/>

                            <Form.Select
                                fluid
                                value={lang}
                                label={LangProfile.personalDetails.locale[lang]}
                                onChange={(e, data: InputOnChangeData) => {
                                    setLocale(data.value);
                                    if (data.value.length > 0 && !retrievingUserAttributes && user.attributes.locale !== data.value) {
                                        updateUserAttributes('locale', data.value);
                                    }
                                }}
                                options={[
                                    {key: 'it', text: LangProfile.personalDetails.italian[lang], value: 'it'},
                                    {key: 'en', text: LangProfile.personalDetails.english[lang], value: 'en'}
                                ]}
                                placeholder='Locale'
                            />
                        </Form.Group>
                    </Form>
                </Item.Content>
            </Item>
            <Divider/>
            <ProfessionalDetails user={user}/>

        </Segment>
    );
});


export default UserDetailsComponent;




