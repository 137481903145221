import {combineReducers} from 'redux';
import {roomReducer} from "./reducers/roomReducer";
import {deviceReducer} from "./reducers/deviceReducer";
import {applicationReducer} from "./reducers/applicationReducer";
import {ApplicationConfigurationReducer} from "./reducers/ApplicationConfigurationReducer";
import applicationsSliceReducer from './slices/ApplicationSlice';
import userApplicationsSliceReducer from './slices/UserApplicationSlice';
import userCoursesSliceReducer from './slices/UserCourseSlice';
import userDevicesSliceReducer from './slices/UserDevicesSlice';
import patientSliceReducer from './slices/PatientsSlice';
import configurationsSliceReducer from './slices/ConfigurationsSlice';
import foodListsSliceReducer from './slices/FoodListsSlice';
import paymentMethodsSliceReducer from './slices/PaymentMethods';

export const rootReducer = combineReducers({
    application: ApplicationConfigurationReducer,
    roomReducer: roomReducer,
    deviceReducer: deviceReducer,
    applicationReducer: applicationReducer,

    applications: applicationsSliceReducer,
    userApplications: userApplicationsSliceReducer,
    userCourses: userCoursesSliceReducer,
    userDevices: userDevicesSliceReducer,
    patients: patientSliceReducer,
    configurations: configurationsSliceReducer,
    foodLists: foodListsSliceReducer,
    paymentMethods: paymentMethodsSliceReducer

});

export type RootState = ReturnType<typeof rootReducer>;
