import {UserDevice} from "../../interfaces/UserDevice";
import {AxiosInterceptor} from "../AxiosInterceptor";
import {AxiosInstance, AxiosResponse} from "axios";
import {Device} from "../../interfaces/Device";

export default class DeviceService {

    static listUserDevices = async (): Promise<UserDevice[]> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/application/user/devices';
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                }).catch((error) => {
                    return [];
                });
            });
    };

    static addUserDevice = async (serial_number: string, device_id: number): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/application/user/add_device';
                return ax.post(url, {
                    serial_number: serial_number,
                    device_id: device_id
                }).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

    static listDevices = async (): Promise<Device[]> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/application/devices';
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                }).catch((error) => {
                    return [];
                });
            });
    };

    static attachUserDevice = async (subscriptionId: string, userDeviceId: number): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/application/user/attach_device';
                return ax.post(url, {
                    subscriptionId: subscriptionId,
                    userDeviceId: userDeviceId
                }).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };


}


