
import {Course} from "../../interfaces/Course";
import {AxiosInterceptor} from "../AxiosInterceptor";
import {AxiosInstance, AxiosResponse} from "axios";
import {UserCourse} from "../../interfaces/UserCourse";
import {UserCourseStep} from "../../interfaces/UserCourseStep";
import {CompleteCourseResponse} from "../../interfaces/CompleteCourseResponse";

export default class CourseService {

    static list = async (lang: string): Promise<Course[]> => {
        const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/application/courses' : '/application/courses/test';

        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, {lang: lang}).then((res: AxiosResponse) => {
                    console.log('courses', res.data);
                    return res.data;
                }).catch((error) => {
                    return [];
                });
            });
    };

    static userCourses = async (lang: string): Promise<UserCourse[]> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/application/user/courses' : '/application/user/courses/test';
                return ax.post(url, {
                    lang: lang
                }).then((res: AxiosResponse) => {
                    console.log('user courses', res.data);
                    return res.data;
                }).catch((error) => {
                    return [];
                });
            });
    };


    static userCourseStep = async (courseId: number): Promise<UserCourseStep> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                //const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/application/user/course/step' : '/application/user/course//test';
                const url = `/application/user/course/${courseId}/step`;
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

    static userCourseNextStep = async (courseId: number, stepId: number): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                //const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/application/user/course/step' : '/application/user/course//test';
                const url = `/application/user/course/${courseId}/step`;
                return ax.post(url, {
                    stepId: stepId
                }).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

    static listCourseMaterials = async (courseId: number): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = `/application/s3/course/${courseId}/list`;
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

    static userCourseNextStepComplete = async (
        courseId: number,
        answers: { question_id: number, answer_id: number }[],
        stepId: number,
        lang: string
    ): Promise<CompleteCourseResponse> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = process.env.REACT_APP_LIVE_MODE === 'true' ?`/application/user/course/${courseId}/complete` : `/application/user/course/${courseId}/complete/test`;
                return ax.post(url, {
                    answers: answers,
                    stepId: stepId,
                    lang: lang
                }).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

    static getVideo = async (courseId: number, video_name: string): Promise<string> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post('application/s3/course/' + courseId + '/video', {video_name: video_name}).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

    static getObject = async (courseId: number, name: string): Promise<string> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post('application/s3/course/' + courseId + '/link', {name: name}).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

}

