import {ApplicationService} from "../../services/applications/ApplicationService";
import {createSlice} from '@reduxjs/toolkit';
import {UserApplication} from "../../interfaces/UserApplication";

export const initialState = {
    retrievingUserApplications: false,
    hasErrors: false,
    userApplications: [],
    userApplicationsFetched: false
};

// A slice for recipes with our 3 reducers
const userApplicationsSlice = createSlice({
    name: 'userApplications',
    initialState,
    reducers: {
        getUserApplications: state => {
            state.retrievingUserApplications = true;
        },
        getUserApplicationsSuccess: (state, {payload}) => {
            state.userApplications = payload;
            state.retrievingUserApplications = false;
            state.hasErrors = false;
            state.userApplicationsFetched = true;
        },
        getUserApplicationsFailure: state => {
            state.retrievingUserApplications = false;
            state.hasErrors = true;
        },
        updateUserApplication: (state, {payload}) => {
            state.userApplications = state.userApplications.map((uc: UserApplication, index: number) => {
                if (uc.id !== payload.id) {
                    return uc;
                }
                return {
                    ...uc,
                    ...payload
                };
            });
        }
    },
});

// The reducer
export default userApplicationsSlice.reducer;

// Selectors
export const userApplicationsSelector = state => state.userApplications;

// Actions
export const {getUserApplications, getUserApplicationsSuccess, getUserApplicationsFailure} = userApplicationsSlice.actions;

// Asynchronous thunk action
export const fetchUserApplications = (): (dispatch) => Promise<void> => {
    return async dispatch => {
        dispatch(getUserApplications());
        try {
            await ApplicationService.userApplications().then(data => {
                dispatch(getUserApplicationsSuccess(data));
            });
        } catch (error) {
            dispatch(getUserApplicationsFailure());
        }
    };
};
