import React, {Dispatch, forwardRef, useCallback} from 'react';
import {Icon, Menu, SemanticICONS} from "semantic-ui-react";
import './SidebarItem.css';
import {useHistory} from "react-router-dom";
import {toggleVerticalSidebar} from "../store/actions/ApplicationConfigurationActions";
import {useDispatch} from "react-redux";

const SideBarItem = forwardRef((props: { url: string, label: string, icon: SemanticICONS, highlight?: boolean }, ref) => {
    // React will ignore custom boolean attributes, therefore we
    // pass a string
    // we use this attribute in our SCSS for styling
    const dispatch: Dispatch<any> = useDispatch();
    const highlight = props.highlight ? 'highlight-item' : null;
    const history = useHistory();

    const handleOnClick = (path: string) => {
        navigate(path);
        if(window.innerWidth <= 1080){
            dispatch(toggleVerticalSidebar());
        }
    };

    const navigate = useCallback((path: string) => {
        history.push(path);
    }, [history]);

    return (
        // @ts-ignore
        <Menu.Item className={'sidebar-item'} as={'a'}
                   onClick={(e) => handleOnClick(props.url)}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{
                    marginRight: '20px',
                    color: highlight ? '#f28f3a' : '#888888'
                }}>
                    <Icon size='large' name={props.icon}/>
                </span>
                <span style={{
                    fontWeight: highlight ? 'bold' : 'normal'
                }}>{props.label}</span>
            </div>
        </Menu.Item>
    );
});
export default SideBarItem;
