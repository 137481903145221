export const LangPayments= {
    title: {
        it: 'Metodi di pagamento',
        en: 'Payment Methods'
    },
    bankTransfer: {
        it: 'Bonifico',
        en: 'Bank transfer'
    },
    bankTransferDetails: {
        it: ``,
        en: ``
    },
    payments: {
        it: 'Nessun metodo di pagamento impostato, aggiungine uno nuovo.',
        en: 'No methods found. Add new one.'
    },
    attachPayment:{
        it: 'Aggiungi un nuovo metodo di pagamento',
        en: 'Attach new payment method'
    },
    billingDetails:{
        it: 'Dettagli',
        en: 'Billing details'
    },
    billingPage: {
        it: 'Fatturazione | BECOME',
        en: 'Billing | BECOME'
    },
    success: {
      it: 'Successo',
      en: 'Success'
    },
    successMessage: {
      it: 'Metodo di pagamento rimosso correttamente',
      en: 'Payment method successfully removed'
    },
    creditCards: {
      it: 'Carte di credito',
      en: 'Credit cards'
    },
    creditHeader: {
      it: 'Rimuovi metodo di pagamento',
      en: 'Remove payment method'
    },
    addCardButton: {
      it: 'Aggiungi una nuova carta',
      en: 'Add new card'
    },
    cardExpiration: {
      it: 'Scadenza:',
      en: 'Expiration:'
    },
    cardRemove: {
      it: 'Rimuovi',
      en: 'Remove'
    },
    noCardsFound: {
      it: 'Nessuna carta trovata',
      en: 'No cards found'
    },
    connectPaypalAccount: {
      it: 'Connetti account',
      en: 'Connect account'
    },
    paypal: {
      it: 'Paypal',
      en: 'Paypal'
    },
    addCreditCard: {
      it: 'Aggiungi una nuova carta',
      en: 'Add new Credit Card'
    },
    addCreditCardClose: {
      it: 'Chiudi',
      en: 'Close'
    },

};
