import {AxiosInterceptor} from "../AxiosInterceptor";
import {AxiosInstance, AxiosResponse} from "axios";
import {PaymentMethod} from "@stripe/stripe-js";
import {Subscription} from "../../interfaces/Subscription";
import {Coupon} from "../../interfaces/Coupon";
import {HandlePaymentThatRequiresCustomerActionObj} from "../../interfaces/HandlePaymentThatRequiresCustomerActionObj";
import {HandleRequiresPaymentMethodObj} from "../../interfaces/HandleRequiresPaymentMethodObj";

export default class StripeService {

    static getUserPaymentMethods = async (): Promise<PaymentMethod[]> => {
        const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/stripe/user/payment_methods' : '/stripe/user/payment_methods/test';

        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                }).catch((error) => {
                    return [];
                });
            });
    }

    static addCard = async (payment_method_id: string): Promise<PaymentMethod> => {
        const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/stripe/user/attach_payment_method' : '/stripe/user/attach_payment_method/test';

        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, {
                    payment_method_id: payment_method_id
                }).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

    static detachPaymentMethod = async (payment_method_id: string): Promise<any> => {
        const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/stripe/user/detach_payment_method' : '/stripe/user/detach_payment_method/test';

        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, {
                    payment_method_id: payment_method_id
                }).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

    static checkCoupon = async (couponCode: string): Promise<Coupon> => {
        const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/stripe/check_coupon' : '/stripe/check_coupon/test';

        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, {coupon_code: couponCode}).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

    static createPaymentIntent = async (price_id: string, selectedPaymentMethodId: string, coupon_code: string): Promise<any> => {
        const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/stripe/create_payment_intent' : '/stripe/create_payment_intent/test';

        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, {
                    price_id: price_id,
                    payment_method_id: selectedPaymentMethodId,
                    coupon_code: coupon_code
                }).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }


    static confirmPaymentIntent = async (payment_intent_id: string): Promise<any> => {
        const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/stripe/confirm_payment_intent' : '/stripe/confirm_payment_intent/test';

        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, {
                    payment_intent_id: payment_intent_id
                }).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }


    static listUserSubscriptions = async (): Promise<Subscription[]> => {
        const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/stripe/user/subscriptions' : '/stripe/user/subscriptions/test';
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                }).catch((error) => {
                    return [];
                });
            });
    };

    static listBalanceTransactions = async (limit: number): Promise<any[]> => {
        const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/stripe/user/list_balance_transactions' : '/stripe/user/list_balance_transactions/test';
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, {
                    limit: limit
                }).then((res: AxiosResponse) => {
                    console.log('transactions', res.data);
                    return res.data.data;
                }).catch((error) => {
                    return [];
                });
            });
    };

    static subscribeToApplication = async (paymentMethodId: string, priceId: string, stripe: any, couponCode: string, quantity: number): Promise<any> => {
        const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/stripe/user/create_subscription' : '/stripe/user/create_subscription/test';

        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, {
                    paymentMethodId: paymentMethodId,
                    priceId: priceId,
                    coupon_code: couponCode,
                    quantity: quantity
                }).then((res: AxiosResponse) => {
                    console.log('step 1', res.data);
                    return res.data;
                }).then((result) => {
                    if (result.error) {
                        // The card had an error when trying to attach it to a customer.
                        throw result;
                    }
                    return result;
                })
                    // Normalize the result to contain the object returned by Stripe.
                    // Add the additional details we need.
                    .then((result) => {
                        console.log('step 2:', 'paymentMethodId', paymentMethodId, 'priceId', priceId, 'subscription', result);
                        return {
                            paymentMethodId: paymentMethodId,
                            priceId: priceId,
                            subscription: result,
                        };
                    }) // Some payment methods require a customer to be on session
                    // to complete the payment process. Check the status of the
                    // payment intent to handle these actions.
                    .then((object) => StripeService.handlePaymentThatRequiresCustomerAction(object as HandlePaymentThatRequiresCustomerActionObj, stripe))
                    // If attaching this card to a Customer object succeeds,
                    // but attempts to charge the customer fail, you
                    // get a requires_payment_method error.
                    .then(StripeService.handleRequiresPaymentMethod)
                    // No more actions required. Provision your service for the user.
                    .then((result) => result)
                    .catch((error) => {
                        console.log('error:', error);
                    });
            });
    }


    static handlePaymentThatRequiresCustomerAction = (htrcao: HandlePaymentThatRequiresCustomerActionObj, stripe: any) => {

        if (htrcao.subscription && htrcao.subscription.status === 'active') {
            // Subscription is active, no customer actions required.
            return htrcao;
        }

        // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
        // If it's a retry, the payment intent will be on the invoice itself.
        let paymentIntent = htrcao.invoice ? htrcao.invoice.payment_intent : htrcao.subscription.latest_invoice.payment_intent;

        if (
            paymentIntent.status === 'requires_action' ||
            (htrcao.isRetry === true && paymentIntent.status === 'requires_payment_method')
        ) {
            return stripe
                .confirmCardPayment(paymentIntent.client_secret, {
                    payment_method: htrcao.paymentMethodId,
                })
                .then((result: any) => {
                    if (result.error) {
                        console.log('Error handlePaymentThatRequiresCustomerAction -> confirmCardPayment', result.error);
                        // Start code flow to handle updating the payment details.
                        // Display error message in your UI.
                        // The card was declined (i.e. insufficient funds, card has expired, etc).
                        throw result;
                    } else {
                        if (result.paymentIntent.status === 'succeeded') {
                            // Show a success message to your customer.
                            // There's a risk of the customer closing the window before the callback.
                            // We recommend setting up webhook endpoints later in this guide.
                            // TODO fare con socket per ora metto refresh
                            setTimeout(() => {
                                window.location.href =  window.location.href.replace('/checkout', '');
                            }, 2500);


                            return htrcao;
                        }
                    }
                })
                .catch((error: any) => {
                    throw error.message;
                });
        } else {
            // No customer action needed.
            return htrcao;
        }
    };



    static handleRequiresPaymentMethod = ({subscription, paymentMethodId, priceId}: HandleRequiresPaymentMethodObj) => {
        console.log('handleRequiresPaymentMethod', 'subscription', subscription, 'paymentMethodId', paymentMethodId, 'priceId', priceId);
        if (subscription.status === 'active') {
            // subscription is active, no customer actions required.
            console.log('handleRequiresPaymentMethod -> subscription status active ');
            return {subscription, priceId, paymentMethodId};
        } else if (
            subscription.latest_invoice &&
            subscription.latest_invoice.payment_intent.status ===
            'requires_payment_method'
        ) {
            // Using localStorage to manage the state of the retry here,
            // feel free to replace with what you prefer.
            // Store the latest invoice ID and status.
            console.log('handleRequiresPaymentMethod -> subscription status requires_payment_method ');
            localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
            localStorage.setItem(
                'latestInvoicePaymentIntentStatus',
                subscription.latest_invoice.payment_intent.status
            );
            // eslint-disable-next-line no-throw-literal
            throw {error: {message: 'Your card was declined.'}};
        } else {
            console.log('handleRequiresPaymentMethod -> subscription status undefined ');
            return {subscription, priceId, paymentMethodId};
        }
    };


    static cancelSubscriptionService = async (subscriptionId: string): Promise<any> => {
        const url = process.env.REACT_APP_LIVE_MODE === 'true' ? '/stripe/user/cancel_subscription' : '/stripe/user/cancel_subscription/test';

        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, {
                    subscription_id: subscriptionId
                }).then((res: AxiosResponse) => {
                    return res.data;
                }).catch((error) => {
                    console.log('Error deleting subscription: ', error);
                    return [];
                });
            });
    };


}
