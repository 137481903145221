import React, {Dispatch, forwardRef, useEffect, useState} from "react";
import {
    Accordion,
    Checkbox,
    Dimmer,
    Dropdown,
    Form,
    Grid,
    Header,
    Image,
    Input,
    InputOnChangeData,
    Label,
    List,
    Loader,
    Message,
    Modal,
    Radio,
} from "semantic-ui-react";
import HubspotForm from 'react-hubspot-form';
import {LangCourses} from "../lang/Courses";
import {paymentIcons} from "../utils/paymentIcons";
import {loadStripe, PaymentIntent, PaymentMethod, PaymentMethodResult} from "@stripe/stripe-js";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import StripeService from "../services/user/StripeService";
import {sendToast} from "../store/actions/ApplicationConfigurationActions";
import {Coupon} from "../interfaces/Coupon";
import {COUNTRY_OPTIONS} from "../data/countriesData";
import {SecondaryButton} from "./Buttons/SecondaryButton";
import {PrimaryButton} from "./Buttons/PrimaryButton";
import {Auth} from "aws-amplify";
import {addPaymentMethod, fetchPaymentMethods, paymentMethodsSelector} from "../store/slices/PaymentMethods";
import {useHistory} from "react-router-dom";


const ModalCheckout = forwardRef((props: {
    header: string,
    image: string,
    open: boolean,
    setOpen: any,
    realCost: number,
    setRealCost: any,
    cost: number,
    price_id: string,
    is_subscription?: boolean
}, ref) => {
    const is_subscription = props.is_subscription ? props.is_subscription : false;

    const dispatch: Dispatch<any> = useDispatch();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);

    //accordion
    const [activeIndex, setActiveIndex] = useState<number>(0);

    // payment methods
    const {retrievingPaymentMethods, paymentMethods, paymentMethodsFetched} = useSelector(paymentMethodsSelector);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string>('');

    // stripe
    const stripePromise = loadStripe((process.env.REACT_APP_LIVE_MODE === 'true' ? process.env.REACT_APP_STRIPE_LIVE : process.env.REACT_APP_STRIPE_TEST) ?? '');
    const [validCardEvent, setValidCardEvent] = useState<boolean>(false);
    const [paymentInProgress, setPaymentInProgress] = useState<boolean>(false);
    const [stripe, setStripe] = useState<any>();
    const [cardElements, setCardElements] = useState<any>();
    const [formError, setFormError] = useState<string>('');

    // billing
    const [address, setAddress] = useState<string>('');
    const [addressError, setAddressError] = useState<string>('');

    const [cap, setCap] = useState<string>('');
    const [capError, setCapError] = useState<string>('');

    const [country, setCountry] = useState<string>('');
    const [countryError, setCountryError] = useState<string>('');

    const [province, setProvince] = useState<string>('');
    const [provinceError, setProvinceError] = useState<string>('');

    const [billingName, setBillingName] = useState<string>('');
    const [billingNameError, setBillingNameError] = useState<string>('');

    const [savePaymentMethod, setSavePaymentMethod] = useState<boolean>(false);

    const [open3Ds, setOpen3Ds] = useState<boolean>(false);
    // coupon
    const [couponCode, setCouponCode] = useState<string>('');
    const [invalidCouponCode, setInvalidCouponCode] = useState<boolean>(false);
    const [validCouponCode, setValidCouponCode] = useState<boolean>(false);
    const [checkingCoupon, setCheckingCoupon] = useState<boolean>(false);

    const [quantity, setQuantity] = useState<number>(1);

    const listener3Ds = (ev) => {
        if (ev.data === '3DS-authentication-complete') {
            on3DSComplete();
        } else {
            console.log(ev);
        }
    };


    const onSubmit = async () => {

        if (selectedPaymentMethodId === '') {
            if (country === '') {
                setCountryError('Campo richiesto');
                return;
            }
            if (cap === '') {
                setCapError('Campo richiesto');
                return;
            }
            if (province === '') {
                setProvinceError('Campo richiesto');
                return;
            }
            if (address === '') {
                setAddressError('Campo richiesto');
                return;
            }
            if (billingName === '') {
                setBillingNameError('Campo richiesto');
                return;
            }
        }

        setPaymentInProgress(true);
        let paymentMethodId = selectedPaymentMethodId;

        if (paymentMethodId === '') {
            console.log('creating payment method');
            try {
                const newPaymentMethod: PaymentMethod = await createPaymentMethodPromise(stripe, cardElements);
                console.log('newPaymentMethod', newPaymentMethod);
                paymentMethodId = newPaymentMethod.id;
            } catch (e) {
                setPaymentInProgress(false);
                return;
            }
        }

        window.removeEventListener('message', listener3Ds, false);
        window.addEventListener('message', listener3Ds, false);

        console.log('paymentMethodId', paymentMethodId);
        if (paymentMethodId !== '') {
            if (is_subscription) {
                StripeService.subscribeToApplication(paymentMethodId, props.price_id, stripe, couponCode, quantity).then(result => {
                    dispatch(sendToast({
                        title: 'Success',
                        messages: ['Thank you for subscribing! Hold on a sec:)'],
                        color: 'positive'
                    }));
                }).catch(err => {
                    dispatch(sendToast({title: 'Error', messages: [err.message], color: 'negative'}));
                }).finally(() => {
                    props.setOpen(false);
                    setPaymentInProgress(false);
                });
            } else {
                StripeService.createPaymentIntent(
                    props.price_id,
                    paymentMethodId,
                    couponCode
                ).then((r: PaymentIntent) => {
                    console.log('buy response', r);
                    paymentIntentInProgress = r;
                    StripeService.confirmPaymentIntent(r.id).then((paymentIntent: PaymentIntent) => {
                        console.log('confirm payment intent', paymentIntent);

                        switch (paymentIntent.status) {
                            case 'requires_action':
                                render3DSecureIframe(paymentIntent);
                                break;

                            case 'requires_payment_method':
                                console.log('requires payment method', paymentIntent.last_payment_error);
                                break;

                            case 'succeeded':
                                dispatch(sendToast({
                                    title: 'Success',
                                    messages: ['Payment successful'],
                                    color: 'positive'
                                }));
                                break;

                        }

                    });
                }).catch(err => {
                    dispatch(sendToast({title: 'Error', messages: [err.message], color: 'negative'}));
                }).finally(() => {
                    props.setOpen(false);
                    setPaymentInProgress(false);
                });
            }
        } else {
            dispatch(sendToast({title: 'Error', messages: ['Error with payment method'], color: 'negative'}));
            setPaymentInProgress(false);
        }

    };

    useEffect(() => {
        if (couponCode.length === 0) {
            setInvalidCouponCode(false);
            setValidCouponCode(false);
            props.setRealCost(props.cost / 100);
        }
        if (couponCode.length === 8) {
            checkCouponCode();
        }
    }, [couponCode]);

    useEffect(() => {
        if (!paymentMethodsFetched) {
            dispatch(fetchPaymentMethods());
        }
    }, []);

    const checkCouponCode = () => {
        setCheckingCoupon(true);
        setInvalidCouponCode(false);
        setValidCouponCode(false);
        StripeService.checkCoupon(couponCode).then((c: Coupon) => {
            setValidCouponCode(true);
            const finalCost = c.percent_off ? props.cost - props.cost / 100 * c.percent_off : props.cost - c.amount_off;
            props.setRealCost(finalCost / 100);
        }).catch((err) => {
            setInvalidCouponCode(true);
            props.setRealCost(props.cost / 100);
        }).finally(() => {
            setCheckingCoupon(false);
        });
    };


    let paymentIntentInProgress: PaymentIntent = null;

    const createPaymentMethodPromise = async (stripe, cardElements): Promise<PaymentMethod> => {
        return new Promise((resolve, reject) => {
            stripe.createPaymentMethod({
                type: 'card',
                card: cardElements,
                billing_details: {
                    name: billingName,
                    address: {
                        country: country,
                        line1: address,
                        postal_code: cap,
                        state: province
                    }
                }
            }).then(async (res: PaymentMethodResult) => {
                if (res.error) {
                    setFormError(res.error.message);
                    reject(res.error);
                }
                console.log('res: ', res);
                if (is_subscription || savePaymentMethod) {
                    console.log('saving payment method');
                    await StripeService.addCard(res.paymentMethod.id).then((pm: PaymentMethod) => {
                        console.log(res);
                        dispatch(addPaymentMethod(pm));
                    }).catch(err => {
                        console.log(err);
                    }).finally(() => {
                        resolve(res.paymentMethod);
                    });
                } else {
                    resolve(res.paymentMethod);
                }
            });
        });
    };

    const render3DSecureIframe = (paymentIntent: PaymentIntent) => {
        setOpen3Ds(true);
        const iframe: HTMLIFrameElement = document.getElementById('iframe') as HTMLIFrameElement;
        if (iframe) {
            iframe.setAttribute('src', paymentIntent.next_action.redirect_to_url.url);
        }
    };

    const on3DSComplete = () => {
        // Hide the 3DS UI
        console.log('on 3ds complete fired :P');
        setOpen3Ds(false);
        // Check the PaymentIntent
        console.log('paymentIntentINProgress', paymentIntentInProgress);
        const clientSecret = paymentIntentInProgress?.client_secret;
        console.log('payment intent ', clientSecret);
        stripe.retrievePaymentIntent(clientSecret)
            .then(function (result) {
                if (result.error) {
                    // PaymentIntent client secret was invalid
                    console.log('error', result, result.error);
                    dispatch(sendToast({
                        title: 'Error',
                        messages: [result.error],
                        color: 'negative'
                    }));
                } else {
                    if (result.paymentIntent.status === 'succeeded') {
                        // Show your customer that the payment has succeeded
                        console.log('succeeded', result);
                        dispatch(sendToast({
                            title: 'Success',
                            messages: ['Payment successful, hold on a sec :)'],
                            color: 'positive'
                        }));

                        // TODO fare con socket per ora metto refresh
                        setTimeout(() => {
                            window.location.href =  window.location.href.replace('/checkout', '');
                        }, 3500);

                    } else if (result.paymentIntent.status === 'requires_payment_method') {
                        // Authentication failed, prompt the customer to enter another payment method
                        console.log('failed', result);
                    }
                }
            });

    };


    return <>
        <Modal
            onClose={() => setOpen3Ds(false)}
            onOpen={() => setOpen3Ds(true)}
            open={open3Ds}
        >
            <Modal.Header>3DS secure payment confirmation</Modal.Header>
            <Modal.Content>
                <iframe id='iframe' width={'100%'} height={'600px'}/>
            </Modal.Content>
        </Modal>

        <Modal
            onClose={() => props.setOpen(false)}
            onOpen={() => props.setOpen(true)}
            open={props.open}
        >
            <Dimmer inverted className={retrievingPaymentMethods ? 'active' : ''}>
                <Loader inverted>Loading</Loader>
            </Dimmer>
            <Modal.Header style={{display: 'flex', alignItems: 'center'}}>
                <Image rounded src={props.image} size={'mini'} style={{marginRight: '15px'}}/>
                {props.header}
            </Modal.Header>


            <Modal.Content image>
                <Grid style={{width: '100%'}}>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={9} style={{marginBottom: '25px'}}>
                            <Modal.Description>
                                <Header style={{marginBottom: '25px'}}>{LangCourses.selectPaymentMethod[lang]}</Header>
                                <Accordion fluid styled style={{
                                    borderRadius: 0,
                                    boxShadow: 'none',
                                    border: '1px solid #cccccc'
                                }}>
                                    <Accordion.Title
                                        style={{alignItems: 'center', display: 'flex'}}
                                        active={activeIndex === 0}
                                        index={0}
                                        onClick={() => setActiveIndex(0)}
                                    >
                                        <Radio checked={activeIndex === 0} style={{marginRight: '15px'}}/>
                                        New credit/debit Card
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex === 0}>
                                        <Elements stripe={stripePromise}>
                                            <Form error={formError !== ''}>
                                                <h4>Dati di fatturazione</h4>
                                                <Form.Field>
                                                    <Input size={'mini'} value={billingName}
                                                           onChange={(e) => setBillingName(e.target.value)}
                                                           error={billingNameError !== ''}
                                                           onFocus={() => setBillingNameError('')}
                                                           placeholder={'Nome completo'}/>
                                                    {billingNameError !== '' ?
                                                        <Label pointing prompt>
                                                            {billingNameError}
                                                        </Label> : <></>
                                                    }
                                                </Form.Field>
                                                <Form.Group>
                                                    <Form.Field width={12}>
                                                        <Input size={'mini'} value={address}
                                                               onChange={(e) => setAddress(e.target.value)}
                                                               error={addressError !== ''}
                                                               onFocus={() => setAddressError('')}
                                                               placeholder={'Via/Piazza e numero civico'}/>
                                                        {addressError !== '' ?
                                                            <Label pointing prompt>
                                                                {addressError}
                                                            </Label> : <></>
                                                        }
                                                    </Form.Field>
                                                    <Form.Field width={4}>
                                                        <Input size={'mini'} value={province}
                                                               onChange={(e) => setProvince(e.target.value)}
                                                               error={provinceError !== ''}
                                                               onFocus={() => setProvinceError('')}
                                                               placeholder={'Provincia'}/>
                                                        {provinceError !== '' ?
                                                            <Label pointing prompt>
                                                                {provinceError}
                                                            </Label> : <></>
                                                        }
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths={'equal'}>
                                                    <Form.Field>
                                                        <Input size={'mini'} value={cap}
                                                               onChange={(e) => setCap(e.target.value)}
                                                               error={capError !== ''}
                                                               onFocus={() => setCapError('')}
                                                               placeholder={'Cap'}/>
                                                        {capError !== '' ?
                                                            <Label pointing prompt>
                                                                {capError}
                                                            </Label> : <></>
                                                        }
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Dropdown
                                                            style={{
                                                                minHeight: '31px',
                                                                padding: '7px',
                                                                fontSize: '12px'
                                                            }}
                                                            size={'mini'}
                                                            placeholder='Select Country'
                                                            value={country}
                                                            error={countryError !== ''}
                                                            selection
                                                            search
                                                            options={COUNTRY_OPTIONS}
                                                            onChange=
                                                                {
                                                                    (e, data: InputOnChangeData) => setCountry(data.value)
                                                                }
                                                        />
                                                        {countryError !== '' ?
                                                            <Label pointing prompt>
                                                                {countryError}
                                                            </Label> : <></>
                                                        }
                                                    </Form.Field>
                                                </Form.Group>
                                                {formError !== '' ? <Message
                                                    error
                                                    header='Error'
                                                    content={formError}
                                                /> : <></>}
                                            </Form>
                                            <h4>Dati della carta</h4>
                                            <div style={{border: '1px solid black', padding: '5px 8px'}}>
                                                <FormStripe selectedPaymentMethodId={selectedPaymentMethodId}
                                                            setSelectedPaymentMethodId={setSelectedPaymentMethodId}
                                                            setValidCardEvent={setValidCardEvent}
                                                            setStripe={setStripe}
                                                            setCardElements={setCardElements}/>
                                            </div>
                                            <div style={{textAlign: 'right', marginTop: '10px'}}
                                                 title={is_subscription ? 'Subscriptions requires to save your card data!' : 'We are not storing your billing data on our servers. We use Stripe'}>
                                                <Checkbox checked={is_subscription ? true : savePaymentMethod}
                                                          disabled={is_subscription}
                                                          onClick={() => setSavePaymentMethod(!savePaymentMethod)}
                                                          label='Salva per utilizzo futuro'/>
                                            </div>
                                        </Elements>
                                    </Accordion.Content>

                                    <Accordion.Title
                                        style={{alignItems: 'center', display: 'flex'}}
                                        active={activeIndex === 1}
                                        index={1}
                                        onClick={() => setActiveIndex(1)}
                                    >
                                        <Radio checked={activeIndex === 1} style={{marginRight: '15px'}}/>
                                        {LangCourses.bankTransferTitle[lang]}
                                    </Accordion.Title>

                                    <Accordion.Content active={activeIndex === 1}>
                                        <p>
                                            {LangCourses.bankTransfer[lang]}
                                        </p>
                                    </Accordion.Content>

                                    {paymentMethods.map((pm: PaymentMethod, index: number) => {
                                        return (
                                            <Accordion.Title key={'at-' + pm.id}
                                                             style={{alignItems: 'center', display: 'flex'}}
                                                             active={activeIndex === index + 2}
                                                             index={index + 2}
                                                             onClick={() => {
                                                                 setActiveIndex(index + 2);
                                                                 setSelectedPaymentMethodId(pm.id);
                                                             }}>
                                                <Radio checked={activeIndex === index + 2}
                                                       style={{marginRight: '15px'}}/>
                                                <Image avatar src={paymentIcons(pm.card.brand)}
                                                       style={{borderRadius: '3px'}}/>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                    width: '100%'
                                                }}>
                                                    <div>{pm.card.brand.toUpperCase()}</div>
                                                    <div style={{fontWeight: 400}}>Ending with ...{pm.card.last4}</div>
                                                    <div
                                                        style={{fontWeight: 400}}>Expiration: {pm.card.exp_month}/{pm.card.exp_year}</div>
                                                </div>
                                            </Accordion.Title>
                                        );
                                    })}


                                </Accordion>
                            </Modal.Description>
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={16} computer={7}>
                            {activeIndex === 1 ?
                                <Modal.Description>
                                    <HubspotForm
                                        portalId='19855872'
                                        formId='2e542c0f-d0ef-4f5d-89c2-dd9327d0ac61'
                                        onSubmit={() => console.log('Submit!')}
                                        onReady={(form) => {
                                            Auth.currentAuthenticatedUser().then(res => {
                                                if (res) {
                                                    const loop = () => {
                                                        setTimeout(() => {
                                                            console.log('let\'s try ', form.contentWindow.document.querySelectorAll('input[name="email"]'));
                                                            if (form.contentWindow.document.querySelectorAll('input[name="email"]').length === 0) {
                                                                loop();
                                                            } else {
                                                                form.contentWindow.document.querySelectorAll('input[name="email"]')[0].value = res.attributes.email;
                                                                form.contentWindow.document.querySelectorAll('textarea[name="message"]')[0].style.minHeight = '200px';
                                                            }
                                                        }, 200);
                                                    };
                                                    loop();
                                                }
                                            });
                                        }}
                                        loading={<div>Loading...</div>}
                                    />
                                </Modal.Description>
                                :
                                <Modal.Description>
                                    <Header style={{marginBottom: '25px'}}>Riepilogo dell'ordine</Header>
                                    <Form.Field>
                                        <Input size={'mini'}
                                               label={'Modifica la quantità:'}
                                               style={{
                                                   borderRadius: 0,
                                                   border: '1px solid black'
                                               }}
                                               onChange={(e) => {
                                                   if (!isNaN(parseInt(e.target.value)) && parseInt(e.target.value) > 0) {
                                                       setQuantity(parseInt(e.target.value));
                                                   }
                                               }}
                                               placeholder={1}/>
                                    </Form.Field>
                                    <List divided verticalAlign={'middle'}>
                                        <List.Item>
                                            <List.Content floated='right'>
                                                {quantity}
                                            </List.Content>
                                            <List.Content>Quantità</List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content floated='right'>
                                                {props.cost * quantity / 100} € + <b>IVA</b>
                                            </List.Content>
                                            <List.Content>{props.header}</List.Content>
                                        </List.Item>
                                    </List>
                                    <h4>Hai un coupon?</h4>
                                    <Form>
                                        <Form.Field>
                                            <Input placeholder={'Coupon code'} value={couponCode} type='text'
                                                   style={{width: 'auto'}}
                                                   className={(checkingCoupon ? 'input icon loading' : '') + ' ' + (invalidCouponCode ? 'error' : '')}
                                                   icon={checkingCoupon ? 'spinner' : ''}
                                                   onChange={(e, data: InputOnChangeData) => setCouponCode(data.value)}/>
                                            {invalidCouponCode ?
                                                <Label basic color='red' pointing={'left'}>
                                                    Invalid coupon code
                                                </Label> : <></>}
                                            {validCouponCode ?
                                                <Label basic color='green' pointing={'left'}>
                                                    Valid
                                                </Label> : <></>}
                                        </Form.Field>
                                    </Form>

                                    <List divided verticalAlign={'middle'}>
                                        <List.Item>
                                            <List.Content floated='right'>
                                                {props.realCost * quantity} €
                                            </List.Content>
                                            <List.Content>Totale</List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content floated='right'>
                                                {(props.realCost * quantity * 22 / 100).toFixed(2)} €
                                            </List.Content>
                                            <List.Content>IVA</List.Content>
                                        </List.Item>

                                        <List.Item>
                                            <List.Content floated='right'>
                                                <b>{(props.realCost * quantity + props.realCost * quantity * 22 / 100).toFixed(2)} €</b>
                                            </List.Content>
                                            <List.Content><b>Totale con IVA</b></List.Content>
                                        </List.Item>
                                    </List>

                                </Modal.Description>}
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </Modal.Content>


            <Modal.Actions>

                <SecondaryButton color='black' onClick={() => props.setOpen(false)}>
                    {LangCourses.close[lang]}
                </SecondaryButton>

                {activeIndex !== 1 &&
                <PrimaryButton
                    disabled={!(selectedPaymentMethodId !== '' || validCardEvent)}
                    content={LangCourses.buyNow[lang]}
                    //labelPosition='right'
                    className={'whiteButton ' + (paymentInProgress ? 'loading' : '')}
                    //icon='checkmark'
                    onClick={() => onSubmit()}
                    positive
                />
                }

            </Modal.Actions>
        </Modal>
    </>;

});

export default ModalCheckout;


const FormStripe = forwardRef((props: { selectedPaymentMethodId: string, setSelectedPaymentMethodId: any, setValidCardEvent: any, setStripe: any, setCardElements: any }, ref) => {

    const stripe = useStripe();
    const elements = useElements();
    let cardElement = null;
    let fired = false;
    let fireOnce = false;

    useEffect(() => {
        if (props.selectedPaymentMethodId !== '' && elements) {
            cardElement = elements.getElement('card');
            cardElement?.clear();
        }
    }, [props.selectedPaymentMethodId]);

    useEffect(() => {
        if (elements) {
            cardElement = elements.getElement('card');
            if (!fired) {
                props.setCardElements(cardElement);
                cardElement.on('change', (event) => {
                    if (event.complete) {
                        props.setValidCardEvent(true);
                    } else if (event.error) {
                        props.setValidCardEvent(false);
                    }
                });
                cardElement.on('focus', () => {
                    props.setSelectedPaymentMethodId('');
                });
                fired = true;
            }
        }
    }, [elements]);


    useEffect(() => {
        if (stripe) {
            if (!fireOnce) {
                props.setStripe(stripe);
                fireOnce = true;
            }
        }
    }, [stripe]);


    return <CardElement/>;
});
