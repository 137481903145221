import React, {forwardRef} from "react";
import {Header, Icon, List} from "semantic-ui-react";

const PasswordHelper = forwardRef((props: {
    displayPwHelper: boolean,
    validLen: boolean,
    validUpperCase: boolean,
    validLowercase: boolean,
    validDigit: boolean,
    validSpecial: boolean
}, ref) => {
    return <div style={{
        position: "absolute",
        background: "white",
        boxSizing: "border-box",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
        left: 0,
        right: 0,
        top: '102%',
        padding: '14px',
        zIndex: 99,
        display: props.displayPwHelper ? 'block' : 'none'
    }}>
        <Header as={'h4'}>
            <Icon name={'shield alternate'}/>
            {'Password security rules'.toUpperCase()}
        </Header>
        <div style={{fontSize: '13px', lineHeight: '14px'}}>
            <p style={{padding: 0}}>Your password must follow these rules:</p>
            <List bulleted style={{marginTop: '-6px'}}>
                <List.Item style={{color: props.validLen ? '#21A558' : '#454F5B'}}>Must be between 9
                    and 30 characters.</List.Item>
                <List.Item style={{color: props.validUpperCase ? '#21A558' : '#454F5B'}}>Must contain
                    at least one uppercase, or capital, letter (ex: A,B
                    etc.)</List.Item>
                <List.Item style={{color: props.validLowercase ? '#21A558' : '#454F5B'}}>Must contain
                    at least one lowercase letter.</List.Item>
                <List.Item style={{color: props.validDigit ? '#21A558' : '#454F5B'}}>Must contain at
                    least one number digit (ex: 0,1,2,3 etc)</List.Item>
                <List.Item style={{color: props.validSpecial ? '#21A558' : '#454F5B'}}>Must contain at
                    least one special character (ex:
                    $,#,@,!,$,^,&,*,(,),-)</List.Item>
            </List>
        </div>
    </div>;
});
export default PasswordHelper;
