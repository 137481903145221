import React, {useEffect, useState} from "react";
import {
    Form,
    Header,
    Icon,
    Message,
    Transition
} from "semantic-ui-react";
import {Hub} from "@aws-amplify/core";
import {AUTH_STATE_CHANGE_EVENT, AuthState, UI_AUTH_CHANNEL} from "@aws-amplify/ui-components";
import {LangForgotPassword} from "../../lang/ForgotPassword";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {Auth} from "aws-amplify";
import PasswordHelper from "../components/PasswordHelper";
import {SecondaryButton} from "../../components/Buttons/SecondaryButton";
import {PrimaryButton} from "../../components/Buttons/PrimaryButton";
import Authenticator from "../Authenticator";

const ForgotPassword: React.FC = () => {
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const [formError, setFormError] = useState<string>('');
    const [doingReset, setDoingReset] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [emailSent, setEmailSent] = useState<boolean>(false);

    const [form2Error, setForm2Error] = useState<string>('');
    const [verificationCode, setVerificationCode] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [doingReset2, setDoingReset2] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<string>('');


    const [validLen, setValidLen] = useState<boolean>(false);
    const [validUpperCase, setValidUppercase] = useState<boolean>(false);
    const [validLowercase, setValidLowercase] = useState<boolean>(false);
    const [validDigit, setValidDigit] = useState<boolean>(false);
    const [validSpecial, setValidSpecial] = useState<boolean>(false);
    const [displayPwHelper, setDisplayPwHelper] = useState<boolean>(false);
    const [resetCompleted, setResetCompleted] = useState<boolean>(false);

    const [activeIndex, setActiveIndex] = useState<number>(0);

    useEffect(() => {
        if (newPassword.length > 0) {
            setValidDigit(new RegExp(/\d/).test(newPassword));
            setValidUppercase(new RegExp(/[A-Z]/).test(newPassword));
            setValidLowercase(new RegExp(/[a-z]/).test(newPassword));
            setValidSpecial(new RegExp(/\W/).test(newPassword));
            setValidLen(newPassword.length >= 9 && newPassword.length <= 30);
        } else {
            setValidDigit(false);
            setValidUppercase(false);
            setValidLowercase(false);
            setValidSpecial(false);
            setValidLen(false);
        }
    }, [newPassword]);

    const handleReset = () => {
        setEmailSent(false);
        if (email !== '') {
            setDoingReset(true);
            Auth.forgotPassword(email)
                .then(res => {
                    console.log(res);
                })
                .catch(err => {
                })
                .finally(() => {
                    setDoingReset(false);
                    setEmailSent(true);
                });
        } else {
            setFormError('Email is required');
        }
    };

    const confirmReset = () => {
        if (verificationCode === '') {
            setForm2Error(LangForgotPassword.codeIsRequired[lang]);
            return;
        }
        if (!validDigit || !validUpperCase || !validLowercase || !validSpecial || !validLen) {
            setPasswordError(' ');
            return;
        }
        setDoingReset2(true);
        Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
            .then(res => {
                setResetCompleted(true);
                Hub.dispatch(UI_AUTH_CHANNEL, {
                    event: AUTH_STATE_CHANGE_EVENT,
                    message: AuthState.SignIn,
                    data: null
                });
            })
            .catch(err => {
                setForm2Error(LangForgotPassword.codeIsRequired[lang]);
            })
            .finally(() => {
                setDoingReset2(false);
            });
    };

    const switchComponent = () => {
        setActiveIndex(1);
        setEmailSent(false);
    };
    const backComponent = () => {
        setActiveIndex(0);
    };


    return <Authenticator
        header={
            <>
                {LangForgotPassword.header[lang]}
                <Header.Subheader>{LangForgotPassword.hint[lang]}</Header.Subheader>
            </>
        }
        form={
            <div style={{height: '400px', overflow: 'hidden'}}>
                <Transition.Group animation={'slide left'} duration={500}>
                    {activeIndex === 0 && <>

                        <Form.Input fluid className={formError !== '' ? 'error' : ''}
                                    id={'email-input'}
                                    onFocus={() => {
                                        setFormError('');
                                    }}
                                    error={formError !== ''}
                                    label='Email'
                                    placeholder={LangForgotPassword.email[lang]}
                                    value={email} onChange={e => setEmail(e.target.value)}/>


                        <Message
                            style={{marginTop: '40px'}}
                            success
                            content={LangForgotPassword.done[lang]}
                        />
                        <div style={{width: '318px'}}>
                            {emailSent ? <PrimaryButton style={{padding: '14px 0', marginBottom: '15px'}}
                                                        fluid className={'whiteButton'}
                                                        onClick={() => switchComponent()}>
                                {LangForgotPassword.codeReceived[lang]}
                                <Icon name={'arrow right'}/>
                            </PrimaryButton> : <></>
                            }

                            <SecondaryButton fluid size='large' style={{padding: '14px 0'}}
                                             className={'whiteButton ' + (doingReset ? 'loading' : '')}
                                             disabled={doingReset}
                                             onClick={() => handleReset()}>
                                {LangForgotPassword.sendBtn[lang]}
                            </SecondaryButton>
                        </div>
                    </>}

                    {activeIndex === 1 &&
                    <>

                        <Form.Input fluid className={form2Error !== '' ? 'error' : ''}
                                    onFocus={() => {
                                        setForm2Error('');
                                    }}
                                    error={form2Error !== ''}
                                    label={LangForgotPassword.code[lang]}
                                    placeholder={LangForgotPassword.code[lang]}
                                    value={verificationCode}
                                    onChange={e => setVerificationCode(e.target.value)}/>

                        <div style={{position: "relative"}}>
                            <Form.Input fluid className={passwordError !== '' ? 'error' : ''}
                                        onFocus={() => {
                                            setPasswordError('');
                                            setDisplayPwHelper(true);
                                        }}
                                        icon={<Icon name='eye' link onClick={(ev) => {
                                            const input = ev.target.parentNode.firstChild as HTMLInputElement;
                                            input.type = input.type === 'password' ? 'text' : 'password';
                                        }}/>}
                                        error={passwordError !== ''}
                                        label={LangForgotPassword.newPassword[lang]}
                                        placeholder={LangForgotPassword.newPassword[lang]}
                                        onBlur={() => {
                                            setDisplayPwHelper(false);
                                        }}
                                        autoComplete="new-password"
                                        type={'password'}
                                        value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
                            <PasswordHelper displayPwHelper={displayPwHelper} validLen={validLen}
                                            validUpperCase={validUpperCase}
                                            validLowercase={validLowercase} validDigit={validDigit}
                                            validSpecial={validSpecial}/>
                        </div>

                        <Message
                            style={{marginTop: '40px'}}
                            success
                            content={LangForgotPassword.completed[lang]}
                        />
                        <Message
                            style={{marginTop: '40px'}}
                            error
                            content={formError}
                        />

                        <div style={{width: '318px'}}>
                            <PrimaryButton style={{padding: '14px 0', marginBottom: '15px', marginTop: '40px'}}
                                           fluid className={'whiteButton ' + (doingReset2 ? 'loading' : '')}
                                           onClick={() => confirmReset()}>
                                {LangForgotPassword.header[lang]}
                                <Icon name={'check'} style={{marginLeft: '10px'}}/>
                            </PrimaryButton>

                            <SecondaryButton fluid onClick={() => backComponent()} style={{padding: '14px 0'}}>
                                <Icon name={'arrow left'}/>
                                {LangForgotPassword.back[lang]}
                            </SecondaryButton>
                        </div>
                    </>
                    }

                </Transition.Group>
            </div>
        }
        links={[{
            message: AuthState.SignIn,
            label: LangForgotPassword.signIn[lang]
        }]}/>;
};
export default ForgotPassword;
