import React, {Dispatch, forwardRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Grid, Header, Image, Dropdown, DropdownProps, Form, Segment} from "semantic-ui-react";
import {RootState} from "../store";
import {countryOptions} from "../data/countryOptions";
import {setCurrentLang} from "../store/actions/applicationActions";
import {Hub} from "@aws-amplify/core";
import {AUTH_STATE_CHANGE_EVENT, AuthState, UI_AUTH_CHANNEL} from "@aws-amplify/ui-components";


const Authenticator = forwardRef((props: {
    header: any,
    form: any,
    formStatus?: { error?: boolean, success?: boolean }
    links: { label: string, data?: any, callback?: any, message?: AuthState }[]
}, ref) => {

    const dispatch: Dispatch<any> = useDispatch();
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const setLang = React.useCallback(
        (lang: string) => dispatch(setCurrentLang(lang)),
        [dispatch]
    );

    return <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
        <Grid.Column style={{
            maxWidth: 450, minWidth: 350, border: 'none', boxShadow: '0 0.625rem 0.875rem -0.3125rem #e0e0e0',
            padding: '2em'
        }}>
            <div>
                <Dropdown
                    className={'miniDropdown'}
                    onChange={(e, props: DropdownProps) => setLang((props.value as string))}
                    placeholder='Select Country'
                    fluid
                    value={lang}
                    selection
                    options={countryOptions}
                />
            </div>
            <Image src={'/assets/icon/logo.png'} alt={"Become Logo"} style={{margin: 'auto'}} size={"tiny"}/>
            <Header as='h2' textAlign='center'>{props.header}</Header>

            <Form size='large' autoComplete="chrome-off" success={props.formStatus?.success} error={props.formStatus?.error}>
                <Segment textAlign={'left'} style={{border: 'none', boxShadow: 'none'}}>
                    {props.form}
                </Segment>
            </Form>


            <div style={{display: "flex", justifyContent: "space-around"}}>
                {props.links?.map((l: { label: string, data?: any, message?: AuthState, callback?: any }) =>
                    <div className={'hoverContainer'}>
                        <p className={'hover hover-4'}
                           onClick={() => l.callback ? l.callback() : Hub.dispatch(UI_AUTH_CHANNEL, {
                               event: AUTH_STATE_CHANGE_EVENT,
                               message: l.message,
                               data: l.data
                           })}>{l.label}</p>
                    </div>)}
            </div>
        </Grid.Column>
    </Grid>;
});

export default Authenticator;
