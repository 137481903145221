import axios from "axios";
import {CognitoRefreshToken} from 'amazon-cognito-identity-js';

export default class AuthService{

    static refreshToken = async (refreshToken: CognitoRefreshToken, deviceKey: string): Promise<any> => {
        return await axios.post(`${process.env.REACT_APP_BECOME_API_URL}/auth/refresh_token`, {
            refresh_token: refreshToken.getToken(),
            device_key: deviceKey
        }).then(res => {
           return res.data;
        });
    };

}
