export const LangConfirmSignIn = {
    header: {
        en: 'Confirm Sign In',
        it: 'Conferma login',
    },
    button: {
        en: 'Verify',
        it: 'Verifica'
    }
};
