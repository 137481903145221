import React, {
    Dispatch,
    forwardRef,
    useEffect,
    useState
} from 'react';
import {LangProfile} from "../../../lang/Profile";
import {Form, InputOnChangeData, Item, Segment} from "semantic-ui-react";
import {SecondaryButton} from "../../../components/Buttons/SecondaryButton";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {UserProfession} from "../../../interfaces/UserProfession";
import UserService from "../../../services/user/UserService";
import {sendToast} from "../../../store/actions/ApplicationConfigurationActions";
import CompanyService from "../../../services/company/CompanyService";
import {Company} from "../../../interfaces/Company";
import {CognitoUserInterface} from "@aws-amplify/ui-components";
import {Auth} from "aws-amplify";


const CompanyDetails = forwardRef((props: { user: CognitoUserInterface | undefined }, ref) => {
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const dispatch: Dispatch<any> = useDispatch();
    const [fiscalCode, setFiscalCode] = useState<string>('');
    const [savingDetails, setSavingDetails] = useState<boolean>(false);
    const [company, setCompany] = useState<Company>({name: '', address: '', vat: ''});


    const saveDetails = () => {
        if (savingDetails) {
            return;
        }
        setSavingDetails(true);
        CompanyService.saveCompanyDetails(company)
            .then(res => console.log('res', res))
            .catch(err => console.warn(err))
            .finally(() => setSavingDetails(false));
    };

    return <>
        <Item>
            <Item.Content>
                <Form className={savingDetails ? 'loading' : ''}>


                    <Form.Input fluid label={LangProfile.companyDetails.name[lang]}
                                placeholder={LangProfile.companyDetails.name[lang]}
                                value={company.name}
                                onBlur={() => {
                                    if (company.name !== '') {
                                        saveDetails();
                                    }
                                }}
                                onChange={(e, data: InputOnChangeData) => setCompany({
                                    ...company,
                                    name: data.value
                                })}/>

                    <Form.Input fluid label={LangProfile.companyDetails.address[lang]}
                                placeholder={LangProfile.companyDetails.address[lang]}
                                value={company.address}
                                onBlur={() => {
                                    if (company.address !== '') {
                                        saveDetails();
                                    }
                                }}
                                onChange={(e, data: InputOnChangeData) => setCompany({
                                    ...company,
                                    address: data.value
                                })}/>


                    <Form.Input fluid label={LangProfile.companyDetails.vatNumber[lang]}
                                placeholder={LangProfile.companyDetails.vatNumber[lang]}
                                value={company.vat}
                                onBlur={() => {
                                    if (company.vat !== '') {
                                        saveDetails();
                                    }
                                }}
                                onChange={(e, data: InputOnChangeData) => setCompany({
                                    ...company,
                                    vat: data.value
                                })}/>

                    <Form.Input fluid label={LangProfile.personalDetails.fiscalCode[lang]}
                                placeholder={LangProfile.personalDetails.fiscalCode[lang]} value={fiscalCode}
                                onBlur={() => {
                                    if (fiscalCode && fiscalCode.length > 0 && props.user?.attributes["custom:fiscal_code"] !== fiscalCode) {
                                        setSavingDetails(true);
                                        Auth.updateUserAttributes(props.user, {
                                            "custom:fiscal_code": fiscalCode
                                        }).then(r => {
                                            dispatch(sendToast({
                                                title: LangProfile.success[lang],
                                                messages: ['User updated'],
                                                color: 'positive'
                                            }));
                                        })
                                            .catch(error => {
                                                console.log('errormessage', error);
                                                dispatch(sendToast({
                                                    title: LangProfile.error[lang],
                                                    messages: [error.message],
                                                    color: 'negative'
                                                }));
                                            })
                                            .finally(() => {
                                                setSavingDetails(false);
                                            });
                                    }
                                }}
                                onChange={(e, data: InputOnChangeData) => setFiscalCode(data.value)}/>


                </Form>
            </Item.Content>
        </Item>
    </>;
});
export default CompanyDetails;
