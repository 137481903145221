import {AxiosInstance, AxiosResponse} from "axios";
import {AxiosInterceptor} from "../AxiosInterceptor";
import {UserProfession} from "../../interfaces/UserProfession";
import {Address} from "../../interfaces/Address";
import {Company} from "../../interfaces/Company";

export default class UserService {

    static getUserAddresses = async (): Promise<Address[]> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/application/addresses';
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                });
            }).catch(err => Promise.reject(err));
    };

    static saveUserAddress = async (address: Address, is_personal: boolean = false): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/application/save_address';
                return ax.post(url, {address: {...address, is_personal: is_personal}}).then((res: AxiosResponse) => {
                    return res.data;
                });
            }).catch(err => Promise.reject(err));
    };


    static sendMessage = async (title: string, message: string): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/contact_us/message';
                return ax.post(url, {title: title, message: message}).then((res: AxiosResponse) => {
                    return res.data;
                });
            }).catch(err => Promise.reject(err));
    }


    static getProfessionDetails = async (): Promise<UserProfession> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/application/profession/details';
                return ax.get(url).then((res: AxiosResponse) => {
                    const response: UserProfession = res.data;
                    return {
                        sdi: response.sdi ?? '',
                        vat: response.vat ?? '',
                        profession: response.profession ?? '',
                        profession_id: response.profession_id ?? '',
                        pec: response.pec ?? '',
                        verified_profession_id: response.verified_profession_id,
                        verified_vat: response.verified_vat
                    };
                    //return res.data;
                });
            }).catch(err => Promise.reject(err));
    };


    static saveUserProfessionDetails = async (userProfession: UserProfession): Promise<any> => {
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                const url = '/application/profession/save_details';
                return ax.post(url, {userProfession}).then((res: AxiosResponse) => {
                    return res.data;
                });
            }).catch(err => Promise.reject(err));
    };


}




