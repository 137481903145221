import React, {Dispatch, useEffect, useState} from 'react';
import {AUTH_STATE_CHANGE_EVENT, AuthState, UI_AUTH_CHANNEL} from '@aws-amplify/ui-components';
import {useDispatch, useSelector} from "react-redux";
import {Hub, HubCapsule} from "@aws-amplify/core";
import {Button, Container, Dropdown, DropdownProps, Form, Grid, Header, Icon, Image, Segment} from "semantic-ui-react";
import {RootState} from "../../store";
import {LangSignIn} from "../../lang/SignIn";
import {SecondaryButton} from "../../components/Buttons/SecondaryButton";
import SignInFunctions from "../functions/SignIn";
import {setCurrentLang} from "../../store/actions/applicationActions";
import {PrimaryButton} from "../../components/Buttons/PrimaryButton";
import {countryOptions} from "../../data/countryOptions";

const SignIn: React.FC = () => {


    const dispatch: Dispatch<any> = useDispatch();
    const setLang = React.useCallback(
        (lang: string) => dispatch(setCurrentLang(lang)),
        [dispatch]
    );

    const [signInFunction, setSignInFunction] = useState<SignInFunctions | undefined>();
    const [doingLogin, setDoingLogin] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);


    useEffect(() => {
        let subscribed = true;
        Hub.listen(UI_AUTH_CHANNEL, (data: HubCapsule) => {
            if (data.payload.data && subscribed) {
                setUsername(data.payload.data);
            }
        });
        return () => {
            subscribed = false;
        };
    }, []);

    useEffect(() => {
        const sif = new SignInFunctions(setEmailError, setPasswordError, setDoingLogin, dispatch, lang);
        setSignInFunction(sif);
    }, [lang]);

    return <Container fluid>

        <div style={{position: 'fixed', right: '15px', top: '15px', zIndex: 999}}>
            <Dropdown
                className={'miniDropdownTop'}
                onChange={(e, props: DropdownProps) => setLang((props.value as string))}
                placeholder='Select Country'
                fluid
                value={lang}
                selection
                options={countryOptions}
            />
        </div>

        <Grid verticalAlign='middle'>
            <Grid.Row style={{minHeight: '100vh', backgroundColor: '#191919'}}>
                <Grid.Column mobile={16} tablet={16} computer={6} style={{
                    background: '#F6F8FB',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                    <div style={{maxWidth: 450, minWidth: 350, margin: 'auto'}}>
                        <Image src={'/assets/icon/logo.png'} alt={"Become Logo"} style={{margin: 'auto'}}
                               size={"tiny"}/>
                        <Header as={'h4'} textAlign={'center'}>
                            {LangSignIn.header[lang].toUpperCase()}
                        </Header>
                        <Form size='large' autoComplete="chrome-off">


                            <Form.Input fluid placeholder='Enter your email address'
                                        className={emailError !== '' ? 'error' : ''}
                                        label={'Email'}
                                        onFocus={() => setEmailError('')}
                                        error={emailError !== '' ? emailError : false}
                                        value={username} onChange={e => setUsername(e.target.value)}/>

                            <Form.Input
                                fluid label={'Password'}
                                icon={<Icon name='eye' link onClick={(ev) => {
                                    const input = ev.target.parentNode.firstChild as HTMLInputElement;
                                    input.type = input.type === 'password' ? 'text' : 'password';
                                }}/>}
                                onFocus={() => setPasswordError('')}
                                className={passwordError !== '' ? 'error' : ''}
                                value={password}
                                autoComplete="new-password"
                                onChange={e => setPassword(e.target.value)}
                                placeholder='Enter your password'
                                type='password'
                                error={passwordError !== '' ? passwordError : false}
                            />

                            <PrimaryButton fluid size='large' style={{padding: '14px 0', marginTop: '25px'}}
                                           className={'whiteButton ' + (doingLogin ? 'loading' : '')}
                                           disabled={doingLogin}
                                           onClick={() => signInFunction?.handleSignIn(username, password)}>
                                {LangSignIn.button[lang]}
                            </PrimaryButton>
                        </Form>
                        <div style={{display: "flex", justifyContent: "space-around", marginTop: '2em'}}>
                            <div className={'hoverContainer'}>
                                <p className={'hover hover-4'} onClick={() => {
                                    Hub.dispatch(UI_AUTH_CHANNEL, {
                                        event: AUTH_STATE_CHANGE_EVENT,
                                        message: AuthState.ForgotPassword,
                                        data: null
                                    });
                                }}>
                                    {LangSignIn.forgotPassword[lang]}
                                </p>
                            </div>
                        </div>

                    </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={9}>
                    <div style={{margin: 'auto', textAlign: 'center'}} className={'fitContent'}>
                        <Header as={'h1'} style={{color: 'white'}}>{LangSignIn.register[lang]}</Header>
                        <p style={{fontSize: '2em', color: '#eee'}}>{LangSignIn.text[lang]}</p>

                        <SecondaryButton onClick={() => {
                            Hub.dispatch(UI_AUTH_CHANNEL, {
                                event: AUTH_STATE_CHANGE_EVENT,
                                message: AuthState.SignUp,
                                data: null
                            });}
                        }
                            size={'massive'}>{LangSignIn.createAccount[lang]}</SecondaryButton>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Container>;
};

export default SignIn;
