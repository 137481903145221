import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {Auth} from "aws-amplify";
import AuthService from "./user/AuthService";
import awsmobile from "../aws-exports";

let jwt = '';
const interceptor = axios.create({
    baseURL: `${process.env.REACT_APP_BECOME_API_URL}`,
    timeout: 60000,
});
const MAX_REQUESTS_COUNT = 5;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;
let refreshing_token = false;


export const AxiosInterceptor = async (): Promise<AxiosInstance> => {
    await Auth.currentSession().then(async (session) => {
        const accessToken = session.getAccessToken();
        const expiration = session.getIdToken().getExpiration();
        const currentTimeSeconds = Math.round(+new Date() / 1000);
        jwt = accessToken.getJwtToken();

        if(expiration - currentTimeSeconds <= 0 && !refreshing_token) {
            refreshing_token = true;
            Auth.currentAuthenticatedUser().then(user => {
                user.getCachedDeviceKeyAndPassword();
                user.getDevice({
                    onSuccess: (deviceKey: any) => {
                        const device_key = deviceKey.Device.DeviceKey;
                        console.log('device_key', device_key);

                        AuthService.refreshToken(session.getRefreshToken(), device_key).then(r => {
                            const authRes = r.AuthenticationResult;
                            localStorage.setItem(`CognitoIdentityServiceProvider.${awsmobile.aws_user_pools_web_client_id}.${user.username}.idToken`, authRes.IdToken);
                            localStorage.setItem(`CognitoIdentityServiceProvider.${awsmobile.aws_user_pools_web_client_id}.${user.username}.accessToken`, authRes.AccessToken);
                            //localStorage.setItem(`CognitoIdentityServiceProvider.${awsmobile.aws_user_pools_web_client_id}.${user.username}.clockDrift`, authRes.AccessToken);
                            refreshing_token = false;
                        });

                    }, onFailure: (e) => console.log('err', e)
                });
            });
        }

    });
    return new Promise((resolve, reject) => {
        (function waitForFoo(){
            if (!refreshing_token) return resolve(interceptor);
            setTimeout(waitForFoo, 30);
        })();
    });

};


interceptor.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        config.headers.Authorization = `Bearer ${jwt}`;
        return new Promise((resolve, reject) => {
            let interval = setInterval(() => {
                if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                    PENDING_REQUESTS++;
                    clearInterval(interval);
                    resolve(config);
                }
            }, INTERVAL_MS);
        });
    },
    (error) => {
        return Promise.reject(error);
    }
);


interceptor.interceptors.response.use(response => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
}, async (error) => {
    if (error.response && error.response.status === 401) {
        await Auth.signOut();
    }
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
});
