export const SET_ROOM = 'SET_ROOM';

export interface RoomState {
    room: string
}

interface SetSelectedRoom {
    type: typeof SET_ROOM,
    payload: string
}

export type RoomActionTypes = SetSelectedRoom;