/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:534644df-09db-400e-8fb3-ec2fc7f26b94",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_5UmfDqYEh",
    "aws_user_pools_web_client_id": "3obd71km8r3tm2rgiveflp05uu",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://5363onkc6fghlahyrplcfkni6m.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "become-courses173259-staging",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
