import {Auth} from "aws-amplify";
import {Hub} from "@aws-amplify/core";
import {AUTH_STATE_CHANGE_EVENT, AuthState, UI_AUTH_CHANNEL} from "@aws-amplify/ui-components";
import {sendToast} from "../../store/actions/ApplicationConfigurationActions";

class SignInFunctions {
    setEmailError: any;
    setPasswordError: any;
    setDoingLogin: any;
    dispatch: any;
    lang: string;

    constructor(setEmailError: any, setPasswordError: any, setDoingLogin: any, dispatch: any, lang: string) {
        console.log('Initializing Sign in [' + lang + ']');
        this.setEmailError = setEmailError;
        this.setPasswordError = setPasswordError;
        this.setDoingLogin = setDoingLogin;
        this.dispatch = dispatch;
        this.lang = lang;
    }

    public handleSignIn = (username: string, password: string) => {

        if (username === '') {
            this.setEmailError('Email cannot be empty');
            return;
        }

        if (password === '') {
            this.setPasswordError('Password cannot be empty');
            return;
        }

        this.setDoingLogin(true);


        Auth.signIn({
            username,
            password,
            validationData: []
        })
            .then(user => {

                switch (user.challengeName) {
                    case 'SMS_MFA':
                    case 'SOFTWARE_TOKEN_MFA':
                        Hub.dispatch(UI_AUTH_CHANNEL, {
                            event: AUTH_STATE_CHANGE_EVENT,
                            message: AuthState.ConfirmSignIn,
                            data: user
                        });
                        break;
                    case 'NEW_PASSWORD_REQUIRED':
                        Hub.dispatch(UI_AUTH_CHANNEL, {
                            event: AUTH_STATE_CHANGE_EVENT,
                            message: AuthState.ResetPassword,
                            data: user
                        });
                        /** TODO
                         const {requiredAttributes} = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
                         // You need to get the new password and required attributes from the UI inputs
                         // and then trigger the following function with a button click
                         // For example, the email and phone_number are required attributes
                         const {username, email, phone_number} = getInfoFromUserInput();
                         const loggedUser = await Auth.completeNewPassword(
                         user,              // the Cognito User Object
                         newPassword,       // the new password
                         // OPTIONAL, the required attributes
                         {
                                email,
                                phone_number,
                            }
                         */
                        break;
                    case 'MFA_SETUP':
                        // TODO it should not happen here (become)
                        // This happens when the MFA method is TOTP
                        // The user needs to setup the TOTP before using it
                        // More info please check the Enabling MFA part
                        // Auth.setupTOTP(user);
                        break;
                    default:
                        this.dispatch(sendToast({title: 'Success', messages: ['Logged in!'], color: 'positive'}));
                        if (this.lang !== 'en') {
                            Auth.updateUserAttributes(user, {
                                locale: this.lang
                            });
                        }
                        if (!user.attributes['custom:identity_id']) {
                            console.log('updating identity');
                            Auth.currentUserCredentials().then(credentials => {
                                Auth.updateUserAttributes(user, {
                                    "custom:identity_id": credentials.identityId
                                });
                            });
                        }
                        Hub.dispatch(UI_AUTH_CHANNEL, {
                            event: AUTH_STATE_CHANGE_EVENT,
                            message: AuthState.SignedIn,
                            data: null
                        });
                }
            })
            .catch(err => {
                console.log('err', err, err.code);
                switch (err.code) {
                    case 'UserNotConfirmedException':
                        Hub.dispatch(UI_AUTH_CHANNEL, {
                            event: AUTH_STATE_CHANGE_EVENT,
                            message: AuthState.ConfirmSignUp,
                            data: username
                        });
                        break;

                    case 'PasswordResetRequiredException':
                        Hub.dispatch(UI_AUTH_CHANNEL, {
                            event: AUTH_STATE_CHANGE_EVENT,
                            message: AuthState.ResetPassword,
                            data: username
                        });
                        break;

                    case 'NotAuthorizedException':
                        this.setPasswordError(err.message);
                        break;

                    case 'UserNotFoundException':
                        this.setPasswordError('Incorrect username or password.');
                        break;

                    default:
                        this.dispatch(sendToast({title: 'Error', messages: [err.message], color: 'negative'}));
                }
            }).finally(() => {
            this.setDoingLogin(false);
        });
    };
}

export default SignInFunctions;
