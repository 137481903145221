export const LangApplications = {
    title: {
        it: 'Applicazioni',
        en: 'Applications'
    },
    month: {
        it: 'Mese',
        en: 'Month'
    },
    year: {
        it: 'Anno',
        en: 'Year'
    },
    subscribe: {
        it: 'Sottoscrivi abbonamento',
        en: 'Subscribe now'
    },
    windowTitle: {
        it: 'Applicazioni | BECOME',
        en: 'Applications | BECOME'
    },
    description1: {
        it: 'Applicazione sbloccata, clicca qui per iniziare',
        en: 'Application Unlocked, click here to launch the configuration'
    },
    description2: {
        it: 'Questa applicazione al momento è in versione',
        en: 'This application is currently in'
    },
    description3: {
        it: 'Devi completare il corso per usare questa applicazione',
        en: 'You need to complete the course to use this application'
    },
    description4: {
        it: 'Corso completato. Clicca qui per iscriverti all\'applicazione',
        en: 'Course completed. Click here to subscribe to the application'
    },

};
