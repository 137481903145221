import {Button} from "semantic-ui-react";
import styled from "styled-components";

export const SecondaryButton = styled(Button)`
  background: white!important;
  color: black!important;;
  border: 2px solid #000!important;;
  border-radius: 0!important;;

  &:hover {
    background: black!important;;
    color: white!important;;
  }
`;


/**


 .ui.button.whiteButton {
    background: white;
    color: black;
    border: 2px solid #000;
    border-radius: 0;
}

 .ui.button.whiteButton:hover {
    background: black;
    color: white;
}

 */
