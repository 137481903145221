export const LangSignUp = {
    header: {
        en: 'Create an account',
        it: 'Crea un nuovo account'
    },
    button: {
        en: 'Create account',
        it: 'Registra'
    },
    haveAnAccount: {
        en: 'Have an account? Sign in',
        it: 'Hai già un account? Accedi'
    },
    username: {
        en: 'Username',
        it: 'Nome account'
    },
    phone: {
        en: 'Prefix + Phone number',
        it: 'Prefisso e numero di telefono'
    },
    policy: {
        en: 'I authorize the processing of personal data according to the privacy policy of the site',
        it: 'Autorizzo il trattamento dei miei dati personali in accordo con la privacy policy del sito'
    },
    requiredPolicy:{
        it: 'Per creare un account è necessario accettare la privacy policy del sito',
        en: 'In order to create an account you should accept our privacy policy.'
    }

};
