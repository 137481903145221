import React, {Dispatch, forwardRef, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Configuration} from "../interfaces/Configuration";
import {
    Button,
    Grid,
    Icon,
    Label,
    Table,
    Item, Confirm, Loader
} from "semantic-ui-react";
import AugmentedFoodService from "../services/AugmentedFoodService";
import {Application} from "../../../interfaces/Application";
import CreateConfiguration from "../components/CreateConfiguration";
import ShowFoodResult from "../components/ShowFoodResult";
import {fetchUserDevices, userDevicesSelector} from "../../../store/slices/UserDevicesSlice";
import {StyledResponsiveItemGroup} from "../../../components/ResponsiveItemGroup";
import {SecondaryButton} from "../../../components/Buttons/SecondaryButton";
import Patients from "./Patients";
import FoodLists from "./FoodLists";
import DevicesModal from "../../../pages/user/components/DevicesModal";
import {fetchPatients, patientsSelector} from "../../../store/slices/PatientsSlice";
import {
    configurationsSelector,
    fetchConfigurations,
    removeConfiguration
} from "../../../store/slices/ConfigurationsSlice";
import {fetchFoodLists, foodListsSelector} from "../../../store/slices/FoodListsSlice";

const AppFoodDashboard = forwardRef((props: { application: Application }, ref) => {

    const dispatch: Dispatch<any> = useDispatch();
    const {retrievingUserDevices, userDevices, userDevicesFetched} = useSelector(userDevicesSelector);
    const {retrievingPatients, patients, patientsFetched} = useSelector(patientsSelector);
    const {retrievingConfigurations, configurations, configurationsFetched} = useSelector(configurationsSelector);
    const {retrievingFoodLists, foodLists, foodListsFetched} = useSelector(foodListsSelector);

    // TABLE FILTER AND SORTING
    const [pagination, setPagination] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string>('c.created_at');
    const [direction, setDirection] = useState<'ascending' | 'descending'>('descending');

    // MODALS
    const [open, setOpen] = useState<boolean>(false);
    const [openResults, setOpenResults] = useState<boolean>(false);
    const [resultsFoodListId, setResultsFoodListId] = useState<number>(0);
    const [patientsOpen, setPatientsOpen] = useState<boolean>(false);
    const [foodListsOpen, setFoodListsOpen] = useState<boolean>(false);
    const [userDevicesOpen, setUserDevicesOpen] = useState<boolean>(false);

    // CONFIRM
    const [confirmDeleteConfiguration, setConfirmDeleteConfiguration] = useState<boolean>(false);
    const [deleteConfiguration, setDeleteConfiguration] = useState<Configuration | null>(null);

    useEffect(() => {
        let isSubscribed = true;

        if (!patientsFetched) {
            dispatch(fetchPatients());
        }
        if (!foodListsFetched) {
            dispatch(fetchFoodLists());
        }
        if (!userDevicesFetched) {
            dispatch(fetchUserDevices());
        }
        return () => {
            isSubscribed = false;
        };
    }, []);


    const sortTable = (col: string) => {
        if (col === sortBy) {
            setDirection(direction === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortBy(col);
        }
    };

    useEffect(() => {
        dispatch(fetchConfigurations(pagination, sortBy, direction));
    }, [pagination, sortBy, direction]);

    const toggleResults = (foodListId: number) => {
        setResultsFoodListId(foodListId);
        setOpenResults(true);
    };

    return (
        <div>
            <StyledResponsiveItemGroup>

                <Item>
                    <Item.Image src={'./assets/images/medical-records.svg'} size={'tiny'}/>
                    <Item.Content verticalAlign='middle'>
                        <Item.Header>Patients</Item.Header>
                        <Item.Description>{retrievingPatients ? 'Loading' : `Total: ${patients.length}`}</Item.Description>
                        <Item.Extra style={{textDecoration: 'underline'}} onClick={() => setPatientsOpen(true)}>Add new
                            patient</Item.Extra>
                        <Patients open={patientsOpen} setOpen={setPatientsOpen} patients={patients}/>
                    </Item.Content>
                </Item>

                <Item>
                    <Item.Image src={'./assets/images/shopping-cart.svg'} size={'tiny'}/>
                    <Item.Content verticalAlign='middle'>
                        <Item.Header>Food Lists</Item.Header>
                        <Item.Description>{retrievingFoodLists ? 'Loading' : `Total: ${foodLists.length}`}</Item.Description>
                        <Item.Extra style={{textDecoration: 'underline'}} onClick={() => setFoodListsOpen(true)}>Add new
                            list</Item.Extra>
                        <FoodLists open={foodListsOpen} setOpen={setFoodListsOpen} foodLists={foodLists}/>
                    </Item.Content>
                </Item>

                <Item>
                    <Item.Image src={'./assets/images/virtual-reality.svg'} size={'tiny'}/>
                    <Item.Content verticalAlign='middle'>
                        <Item.Header>Devices</Item.Header>
                        <Item.Description>{retrievingUserDevices ? 'Loading' : `Total: ${userDevices.length}`}</Item.Description>
                        <Item.Extra style={{textDecoration: 'underline'}} onClick={() => setUserDevicesOpen(true)}>Add
                            new
                            device</Item.Extra>
                        <DevicesModal open={userDevicesOpen} setOpen={setUserDevicesOpen} userDevices={userDevices}/>
                    </Item.Content>
                </Item>

            </StyledResponsiveItemGroup>


            <Grid style={{marginTop: 0}}>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <p style={{textAlign: 'right'}}><Icon name='info circle'/> Advanced search and filters: coming
                            soon
                        </p>
                        <Table compact celled definition sortable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell
                                        sorted={sortBy === 'c.id' ? direction : null}
                                        onClick={() => sortTable('c.id')}>
                                        ID
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        sorted={sortBy === 'patient_name' ? direction : null}
                                        onClick={() => sortTable('patient_name')}>Patient code</Table.HeaderCell>
                                    <Table.HeaderCell>Device ID</Table.HeaderCell>
                                    <Table.HeaderCell
                                        sorted={sortBy === 'c.room' ? direction : null}
                                        onClick={() => sortTable('c.room')}>Room</Table.HeaderCell>
                                    <Table.HeaderCell sorted={sortBy === 'food_list_name' ? direction : null}
                                                      onClick={() => sortTable('food_list_name')}>Food
                                        list</Table.HeaderCell>
                                    <Table.HeaderCell
                                        sorted={sortBy === 'c.created_at' ? direction : null}
                                        onClick={() => sortTable('c.created_at')}>Created at</Table.HeaderCell>
                                    <Table.HeaderCell
                                        sorted={sortBy === 'c.status' ? direction : null}
                                        onClick={() => sortTable('c.status')}>Status</Table.HeaderCell>
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>

                                {retrievingConfigurations &&
                                <Table.Row>
                                    <Table.Cell colSpan='9' textAlign={'center'}>
                                        <Loader style={{
                                            color: 'black',
                                            display: 'block',
                                            position: 'relative',
                                            transform: 'none'
                                        }}/>
                                    </Table.Cell>
                                </Table.Row>
                                }
                                {configurations.map((c: Configuration) => {
                                    let created_at = <>{c.created_at}</>;
                                    if (c.created_at !== 'now') {
                                        const createdAt = new Date(c.created_at);
                                        created_at = <>
                                            <Label>{createdAt.getUTCFullYear()}/{createdAt.getUTCMonth()}/{createdAt.getUTCDay()}</Label>
                                            <Label
                                                color='teal'>{createdAt.getUTCHours()}:{createdAt.getUTCMinutes()}:{createdAt.getUTCSeconds()}</Label>
                                        </>;
                                    }
                                    return <Table.Row key={'table-' + c.id}>
                                        <Table.Cell collapsing>
                                            {c.status === 'COMPLETED' ?
                                                <Button circular icon='clipboard list'
                                                        onClick={() => toggleResults(c.food_list_id)}/> : <></>}
                                        </Table.Cell>
                                        <Table.Cell>{c.id}</Table.Cell>
                                        <Table.Cell>{c.patient_name}</Table.Cell>
                                        <Table.Cell>{c.serial_number}</Table.Cell>
                                        <Table.Cell>{c.room}</Table.Cell>
                                        <Table.Cell>{c.food_list_name}</Table.Cell>
                                        <Table.Cell>{created_at}</Table.Cell>
                                        <Table.Cell>
                                            <Icon name={c.status === 'COMPLETED' ? 'check circle' : 'wait'}
                                                  color={c.status === 'COMPLETED' ? 'green' : 'orange'}/>
                                        </Table.Cell>
                                        <Table.Cell style={{textAlign: 'center'}}>
                                            {c.status !== 'COMPLETED' &&
                                            <Button circular size={'mini'} icon={'trash'} title={'Delete'}
                                                    onClick={() => {
                                                        setDeleteConfiguration(c);
                                                        setConfirmDeleteConfiguration(true);
                                                    }}
                                                    color={'red'}/>
                                            }
                                        </Table.Cell>
                                    </Table.Row>;
                                })}
                            </Table.Body>

                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell>{pagination}</Table.HeaderCell>
                                    <Table.HeaderCell colSpan='8'>
                                        <SecondaryButton
                                            onClick={() => setOpen(true)}
                                            disabled={userDevices.length === 0 || patients.length === 0 || foodLists.length === 0}
                                            floated='right'
                                            icon
                                            labelPosition='left'
                                            primary
                                            size='small'
                                        >
                                            <Icon name='user'/> Create new configuration
                                        </SecondaryButton>
                                        <Button size='small'
                                                disabled={pagination === 0}
                                                onClick={() => setPagination(Math.max(pagination - 10, 0))}
                                                icon='arrow left'
                                                label='Prev'/>
                                        <Button size='small'
                                                labelPosition='left'
                                                icon='arrow right'
                                                onClick={() => setPagination(pagination + 10)}
                                                label='Next'/>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>

                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Confirm
                open={confirmDeleteConfiguration}
                onCancel={() => setConfirmDeleteConfiguration(false)}
                onConfirm={() => {
                    setConfirmDeleteConfiguration(false);
                    AugmentedFoodService.deleteConfiguration(deleteConfiguration.id)
                        .then()
                        .catch((e) => console.log(e))
                        .finally(() => {
                            setDeleteConfiguration(null);
                            dispatch(removeConfiguration(deleteConfiguration));
                        });
                }}
            />

            <CreateConfiguration open={open}
                                 setOpen={setOpen}
                                 userDevices={userDevices}
                                 patients={patients}
                                 foodLists={foodLists}
                                 rooms={['kitchen', 'restaurant']}/>

            <ShowFoodResult open={openResults} setOpen={setOpenResults} foodListId={resultsFoodListId}/>

        </div>
    );
});
export default AppFoodDashboard;
