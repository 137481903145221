import {DeviceActionTypes, DeviceState, SET_DEVICE} from "../types/deviceTypes";

const initialState: DeviceState = {
    device_id: -1
};

export function deviceReducer(
    state: DeviceState = initialState,
    action: DeviceActionTypes
): DeviceState {
    switch (action.type) {
        case SET_DEVICE:
            return {
                ...state,
                device_id: action.payload
            };
        default:
            return state;
    }
}
