export const LangCourses = {
    title: {
        it: 'Corsi',
        en: 'Courses'
    },
    headTitle: {
        it: 'Corsi | BECOME',
        en: 'Courses | BECOME'
    },
    bankTransfer: {
        it: 'Il bonifico richiede circa 3 giorni. Contattaci per maggiori informazioni-',
        en: 'You have to wait 2-3 days. Contact us for details.'
    },
    bankTransferTitle: {
        it: 'Bonifico',
        en: 'Bank Transfer'
    },
    contactUs: {
        it: 'Form di contatto',
        en: 'Contact us'
    },
    sendMessage: {
        it: 'Invia',
        en: 'Send message'
    },
    addCard: {
        it: 'Salva una nuova carta',
        en: 'Add another card'
    },
    selectPaymentMethod: {
        it: 'Seleziona metodo di pagamento',
        en: 'Select your payment method'
    },
    payWithoutSave: {
        it: 'Oppure paga senza salvare dati della carta',
        en: 'Or pay without saving the card'
    },
    buyNow: {
        it: 'Acquista',
        en: 'Buy now'
    },
    close: {
        it: 'Annulla',
        en: 'Close'
    },
    resultsSuccess:{
        it: 'Scarica il tuo certificato e accedi al materiale post corso',
        en: 'Take your certificate and download course materials!'
    },
    resultsFail:{
        it: 'Prova di nuovo',
        en: 'Try again!'
    },
    buttonAccessMaterials:{
        it: 'Accedi ai materiali',
        en: 'Access materials'
    }
};
