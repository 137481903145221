import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {AccessLevel, CognitoUserInterface} from "@aws-amplify/ui-components";
import {Header, Image} from "semantic-ui-react";
import {LangDashboard} from "../../../lang/Dashboard";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {Storage} from "aws-amplify";

const RightBar: React.FC<any & { className?: string, user: CognitoUserInterface }> = ({className, children, user}) => {
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);
    const [imageUrl, setImageUrl] = useState<string>('./assets/images/avatar.svg');

    useEffect(() => {
        if (user && user.attributes.picture) {
            Storage.get(user.attributes.picture, {level: AccessLevel.Private}).then((url: string) => {
                setImageUrl(url);
            });
        }
    }, [user]);

    return <div className={className}>


        <Image src={imageUrl} avatar/>
        <b>{user?.attributes.email}</b>

        <Header as={'h5'}>{LangDashboard.nameSurname[lang]}</Header>
        <p>{user?.attributes.given_name}</p>

        <Header as={'h5'}>{LangDashboard.tag[lang]}</Header>
        <p>{user?.attributes.sub}</p>

        <Header as={'h5'}>{LangDashboard.phone[lang]}</Header>
        <p>{user?.attributes.phone_number}</p>

    </div>;
};

export const StyledRightBar = styled(RightBar)`
  position: fixed;
  right: 0;
  min-width: 300px;

  @media (max-width: 1750px) {
    display: none !important;
  }
`;
