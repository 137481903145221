import {RoomActionTypes, RoomState, SET_ROOM} from "../types/roomTypes";

const initialState: RoomState = {
    room: ''
};

export function roomReducer(
    state: RoomState = initialState,
    action: RoomActionTypes
): RoomState {
    switch (action.type) {
        case SET_ROOM:
            return {
                ...state,
                room: action.payload
            };
        default:
            return state;
    }
}
