export const LangDashboard = {
    devices: {
        it: 'DISPOSITIVI',
        en: 'DEVICES'
    },
    information: {
        it: 'INFORMAZIONI PERSONALI',
        en: 'YOUR INFORMATION'
    },
    securityCheckup: {
        it: 'CONTROLLO DI SICUREZZA',
        en: 'SECURITY CHECKUP'
    },
    Security: {
        it: 'Modifica le impostazioni di sicurezza',
        en: 'Manage security settings'
    },
    subscriptions: {
        it: 'ABBONAMENTI',
        en: 'SUBSCRIPTIONS'
    },
    manageSubscriptions:{
        it: 'Modifica le sottoscrizioni',
        en: 'Manage your subscriptions'
    },
    purchases: {
        it: 'ACQUISTI RECENTI',
        en: 'RECENT PURCHASES'
    },
    moreDetails: {
        it: 'Connetti un nuovo dispositivo',
        en: 'Connect a new device'
    },
    accountDetails: {
        it: 'Profilo',
        en: 'Account Details'
    },
    edit: {
        it: 'Modifica',
        en: 'Edit'
    },
    nameSurname: {
        it: 'Nome e cognome',
        en: 'Name & surname'
    },
    phone: {
        it: 'Telefono',
        en: 'Phone'
    },
    tag: {
        it: 'Codice univoco',
        en: 'User code'
    },
    transactionHistory: {
        it: 'Storico transazioni',
        en: 'Transaction History'
    },
    emailVerified: {
        it: 'Verifica Email',
        en: 'Email Verified'
    },
    phoneVerified: {
        it: 'Verifica cellulare',
        en: 'Phone Verified'
    },
    twoFactorAuthenticator: {
        it: 'Autenticazione a due fattori',
        en: 'Two factor authentication'
    },
    expiration: {
        it: 'Scadenza',
        en: 'Expiration'
    }

};
