import React, { useEffect} from 'react';
import 'react-circular-progressbar/dist/styles.css';
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {LangSecurity} from "../../lang/Security";
import {
    Header,
    Segment,
    Tab,
} from "semantic-ui-react";

import ActivityLog from "./components/ActivityLog";
import Account from "./components/Account";


const Security: React.FC = () => {
    const lang: string = useSelector((state: RootState) => state.applicationReducer.lang);

    useEffect(() => {
        window.document.title = LangSecurity.document[lang];
    }, []);

    const panes = [
        {
            menuItem: LangSecurity.accountItem[lang].toUpperCase(),
            render: () => <Tab.Pane attached={false} style={{border: 0, boxShadow: 'none'}}><Account/></Tab.Pane>,
        },
        {
            menuItem: LangSecurity.activityLogItem[lang].toUpperCase(),
            render: () => <Tab.Pane attached={false}
                                    style={{boxShadow: 'none', border: 'none'}}><ActivityLog/></Tab.Pane>,
        }
    ];

    return (
        <Segment style={{minHeight: '50vh', border: 'none', boxShadow: 'none'}} className="page-container page">
            <Header as='h1'>{LangSecurity.title[lang].toUpperCase()}</Header>
            <Tab menu={{text: true, style: {borderBottom: '1px solid #e0e0e0', height: '60px'}}} panes={panes}/>
        </Segment>
    );
};

export default Security;
