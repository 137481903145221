import {
    ApplicationActionTypes,
    SET_COURSES,
    SET_LANG,
} from "../types/applicationTypes";
import {Course} from "../../interfaces/Course";


export function setCurrentCourses(courses: Course[]): ApplicationActionTypes {
    return {
        type: SET_COURSES,
        payload: courses
    };
}


export function setCurrentLang(lang: string): ApplicationActionTypes {
    return {
        type: SET_LANG,
        payload: lang
    };
}
