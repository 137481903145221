export const LangReturnUrl = {

    title: {
        en: 'Payment successful',
        it: 'Pagamento avvenuto con successo'
    },

    description: {
        en: 'This page will close automatically. Hold on a sec :)',
        it: 'Questa pagina si chiuderà automaticamente. Attendi un attimo oppure clicca sullo sfondo grigio.'
    }

};
